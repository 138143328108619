import { format } from 'date-fns';
import { FC, Fragment, useEffect, useState } from 'react';
import { Button, Col, Container, FormControl, InputGroup, Modal, Row } from 'react-bootstrap';
import { Search } from 'react-bootstrap-icons';
import DataTable, { TableColumn } from 'react-data-table-component';
import { AiOutlineFileAdd } from "react-icons/ai";
import { BsTrash } from "react-icons/bs";
import { IoEllipsisVerticalCircle } from 'react-icons/io5';
import { useDispatch, useSelector } from 'react-redux';
import { IRejectResponse } from '../../api/api.interface';
import { ICertificationEntity } from '../../entities/certification.entity';
import { ICompanyEntity } from '../../entities/company.entity';
import { IMetadataOption, IMetadataOptionNode } from '../../entities/metadata.entity';
import { deleteCertification, IDeleteCertificationThunkPayload } from '../../redux/slices/certification.slice';
import { getCertifications, ICertificationsFilter, ICertificationsPagination, ICertificationsSorting, ICertificationsThunkPayload } from '../../redux/slices/certifications.slice';
import { AppDispatch, RootState } from '../../redux/store';
import { CertificationOrderBy } from '../../utils/certification-default';
import CertificationDetail from '../shared/CertificationDetail';
import { getNome } from '../shared/dropdown/certification/OggettoTipologia';
import StatoCertificazione, { getStatoCertificazione } from '../shared/dropdown/certification/StatoCertificazione';
import Triennio from '../shared/dropdown/course/Triennio';
import PaginationLimit from '../shared/dropdown/list/PaginationLimit';
import Sorting from '../shared/dropdown/list/Sorting';
import { getOrdineProvinciale } from '../shared/dropdown/OrdineProvinciale';
import { useTranslation } from 'react-i18next';
import PageSelect from '../shared/dropdown/list/PageSelect';
import Oggetto from '../shared/dropdown/certification/Oggetto';
import Tipologia from '../shared/dropdown/certification/Tipologia';
import Esonero from '../shared/dropdown/certification/Esonero';

interface IPagination extends ICertificationsPagination { }
interface IFilter extends ICertificationsFilter { }
interface ISorting extends ICertificationsSorting { }

interface IProps {
  refreshTrigger?: number;
}

const CertificationList: FC<IProps> = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();

  const companiesOrders: Array<ICompanyEntity> = useSelector((s: RootState) => s.companies.orders);
  const oggettiAll: Array<IMetadataOptionNode> = useSelector((s: RootState) => s.metadata.certification.oggetto_certificazione_all);
  const accreditiStandard: Array<IMetadataOptionNode> = useSelector((s: RootState) => s.metadata.certification.tipo_accredito_certificazione);
  const accreditiDaDelibera: Array<IMetadataOptionNode> = useSelector((s: RootState) => s.metadata.certification.tipo_accredito_certificazione_non_standard);
  const [tipologie, setTipologie] = useState<Array<IMetadataOptionNode>>(new Array<IMetadataOptionNode>());
  const esoneri: Array<IMetadataOptionNode> = useSelector((s: RootState) => s.metadata.certification.tipo_esonero_certificazione);
  const stati: Array<IMetadataOption> = useSelector((s: RootState) => s.metadata.certification.stato_certificazione);
  const trienni: Array<IMetadataOption> = useSelector((s: RootState) => s.metadata.course.trienni);
  const countTotalCertifications: number = useSelector((s: RootState) => s.certifications.total);

  const [filterByThreeYears, setFilterByThreeYears] = useState<IMetadataOption | null>(trienni.length ? trienni[trienni.length - 1] : null);
  const [isLoading, setLoading] = useState(true);
  const [filterByText, setFilterByText] = useState<string | null>(null);
  const [filter, setFilter] = useState<IFilter>({ oggetto: 0, tipologia: (-1), esonero: 0, company: 0, text: '', stato: 0, triennio: filterByThreeYears ? filterByThreeYears.id : 0 });
  const [pagination, setPagination] = useState<IPagination>({ page: 1, limit: 25 });
  const [sorting, setSorting] = useState<ISorting>({ orderby: Array<string>(), ordertype: false });
  const [selectedItem, setSelectedItem] = useState<ICertificationEntity | null>(null);
  const [selectedId, setSelectedId] = useState<number | null>(null);
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [showDelete, setShowDelete] = useState(false);

  const error = useSelector((s: RootState) => s.certifications.error as IRejectResponse);
  const certifications: Array<ICertificationEntity> = useSelector((s: RootState) => s.certifications.value);

  const handleCloseDelete = () => {
    setShowDelete(false);
    setSelectedId(null);
  };
  const handleShowDelete = () => setShowDelete(true);

  const handleOpenDetailModal = () => setShowDetailModal(true);
  const handleCloseDetailModal = () => {
    setShowDetailModal(false);
    setSelectedItem(null);
    setPagination({ ...pagination });
  };

  useEffect(() => {
    if (accreditiStandard.length && accreditiDaDelibera.length) {
      setTipologie([...accreditiStandard, ...accreditiDaDelibera]);
    }
  }, [accreditiStandard, accreditiDaDelibera]);

  useEffect(() => {
    setLoading(true);
    dispatch(getCertifications({ filter: filter, pagination: pagination, sorting: sorting } as ICertificationsThunkPayload))
      .unwrap()
      .finally(() => setLoading(false));
  }, [dispatch, filter, pagination, sorting, props.refreshTrigger]);

  const handleFilterByText = (event: React.ChangeEvent<HTMLInputElement>) => {
    const text = event.target.value;
    setFilterByText(text);
    setFilter({ ...filter, text: text });
    if (pagination.page !== 1) {
      setPagination({ ...pagination, page: 1 });
    }
  };

  const handleFilterByOggetto = (option: IMetadataOption | null) => {
    if (option) {
      setFilter({ ...filter, oggetto: option.id, tipologia: (-1) });
    }
    else {
      setFilter({ ...filter, oggetto: 0, tipologia: (-1) });
    }
    if (pagination.page !== 1) {
      setPagination({ ...pagination, page: 1 });
    }
  };

  const handleFilterByTipologia = (option: IMetadataOptionNode | null) => {
    if (option) {
      setFilter({ ...filter, oggetto: option.extId, tipologia: option.id });
    }
    else {
      setFilter({ ...filter, oggetto: 0, tipologia: (-1) });
    }
    if (pagination.page !== 1) {
      setPagination({ ...pagination, page: 1 });
    }
  };

  const handleFilterByEsonero = (option: IMetadataOptionNode | null) => {
    if (option) {
      setFilter({ ...filter, oggetto: option.extId, tipologia: option.id });
    }
    else {
      setFilter({ ...filter, oggetto: 0, tipologia: (-1) });
    }
    if (pagination.page !== 1) {
      setPagination({ ...pagination, page: 1 });
    }
  };

  const handleFilterByStato = (option: IMetadataOption | null) => {
    setFilter({ ...filter, stato: option ? option.id : 0 });
    if (pagination.page !== 1) {
      setPagination({ ...pagination, page: 1 });
    }
  };

  const handleFilterByTriennio = (option: IMetadataOption | null) => {
    if (option) {
      setFilter({ ...filter, triennio: option.id });
    }
    else {
      setFilter({ ...filter, triennio: 0 });
    }
    if (pagination.page !== 1) {
      setPagination({ ...pagination, page: 1 });
    }
  };

  const handleSortingOrderBy = (ordby: Array<IMetadataOption> | null) => {
    setSorting({ ...sorting, orderby: ordby ? ordby.map(oby => oby.nome) : [] });
    if (pagination.page !== 1) {
      setPagination({ ...pagination, page: 1 });
    }
  };

  const handleSortingOrderType = (type: boolean) => {
    setSorting({ ...sorting, ordertype: type });
    if (pagination.page !== 1) {
      setPagination({ ...pagination, page: 1 });
    }
  };

  const handlePaginationLoad = (pag: number) => {
    setPagination({ ...pagination, page: pag });
  };
  
  const handlePaginationLoadFirst = () => {
    setPagination({ ...pagination, page: 1 });
  };

  const handlePaginationLoadLast = () => {
    setPagination({ ...pagination, page: Math.ceil(countTotalCertifications / pagination.limit) });
  };

  const handlePaginationLimit = (limit: number) => {
    setPagination({ page: 1, limit: limit });
  };

  const handlePaginationLoadMore = () => {
    setPagination({ ...pagination, page: pagination.page + 1 });
  };

  const handlePaginationLoadMinus = () => {
    const previuosPage = pagination.page - 1;
    if (previuosPage === 0) return;
    setPagination({ ...pagination, page: pagination.page - 1 });
  };

  const handleClickItem = (cert: ICertificationEntity) => {
    setSelectedItem(cert);
    handleOpenDetailModal();
  };

  const handleDelete = () => {
    if (selectedId) {
      setLoading(true);
      dispatch(deleteCertification({ id: selectedId } as IDeleteCertificationThunkPayload))
        .unwrap()
        .then(() => {
          handleCloseDelete();
          setSelectedItem(null);
          setPagination({ ...pagination, page: 1 });
        })
        .finally(() => setLoading(false));
    }
  };


  const columns: TableColumn<ICertificationEntity>[] = [
    {
      id: 'id',
      name: '#Id',
      selector: (row: ICertificationEntity) => row.id,
      cell: (row: ICertificationEntity) => (<span className="blockquote small m-0 p-0" title={'#' + row?.id.toString()}><b>#</b><small><u>{row.id}</u></small></span>),
      grow: 1,
      omit: true,
    },
    {
      name: 'Oggetto',
      selector: (row: ICertificationEntity) => getNome(row.idOggetto, oggettiAll),
      omit: (filter.oggetto > 0),
      grow: 2,
    },
    {
      name: 'Tipologia',
      selector: (row: ICertificationEntity) => "",
      cell: (row: ICertificationEntity) => (
        <div title={(row.idOggetto === 1 || row.idOggetto === 5) ? getNome(row.tipoAccreditoCfpId, tipologie) : getNome(row.tipoEsoneroId, esoneri)}>
          {(row.idOggetto === 1 || row.idOggetto === 5) ? getNome(row.tipoAccreditoCfpId, tipologie) : getNome(row.tipoEsoneroId, esoneri)}</div>),
      omit: (filter.tipologia > 0),
      grow: 3,
    },
    {
      name: 'Stato',
      selector: (row: ICertificationEntity) => getStatoCertificazione(row.idStatoCertificazione, stati),
      grow: 1,
    },
    {
      name: 'CFP dichiarati / assegnati',
      selector: (row: ICertificationEntity) => row.cfpDichiarati + " / " + row.cfpAssegnati,
      grow: 1,
    },
    {
      name: 'Periodo rif.',
      selector: (row: ICertificationEntity) => (row.dataRiferimentoDa ? format(new Date(row.dataRiferimentoDa), 'dd/MM/yyyy') : "") + " - " + (row.dataRiferimentoA ? format(new Date(row.dataRiferimentoA), 'dd/MM/yyyy') : ""),
      grow: 2,
    },
    {
      name: 'Ordine',
      selector: (row: ICertificationEntity) => getOrdineProvinciale(row.idAzienda, companiesOrders),
      grow: 1,
    },
    {
      selector: (row: ICertificationEntity) => "",
      cell: (row: ICertificationEntity) => (
        <span>
          <BsTrash className={`text-danger c-pointer p-0 my-0 ms-0 me-2 ${(!accreditiDaDelibera.some((item) => item.id === row.tipoAccreditoCfpId) && row.idStatoCertificazione === 1) ? 'visible': 'invisible'}`} 
              title="elimina" size={18} onClick={(e: React.MouseEvent<SVGElement>) => {
              e.preventDefault();
              setSelectedId(row.id);
              handleShowDelete();
            }} />
            {/* {
            (row.allegati != null && row.allegati.length >= 4) || row.idStatoCertificazione > 2 ? (
              <AiOutlineFileAdd className="text-dark p-0 my-0 ms-0 me-1 invisible" size={20} />
            ) : (
              <AiOutlineFileAdd className="text-dark c-pointer p-0 my-0 ms-0 me-1" title="aggiungi allegato" size={20} onClick={(e: React.MouseEvent<SVGAElement>) => {
                  e.preventDefault();
                  setSelectedItem(row);
                  handleOpenAddAttachModal();
                }} />
            )
          } */}
          <IoEllipsisVerticalCircle className="text-dark c-pointer p-0 m-0" title="dettagli" size={20} onClick={(e: React.MouseEvent<SVGElement>) => {
              e.preventDefault();
              handleClickItem(row);
            }} />
        </span>),
      ignoreRowClick: true,
      button: true,
      grow: 1,
    },
  ];

  return (
    <Fragment>
      <Container fluid as="section">
        <Row>
          <Col>
            <section className="d-flex mb-3">
              <div className="flex-grow-1 d-flex align-items-center">
                <InputGroup className="mx-1">
                  <FormControl placeholder="Cerca" aria-label="Cerca" onChange={handleFilterByText} aria-describedby="basic-search" />
                  <InputGroup.Text id="basic-search"><Search /></InputGroup.Text>
                </InputGroup>
              </div>
              <div className="flex-shrink-1 d-flex align-items-center">
                {/* <OggettoTipologia onSelectOggetto={handleFilterByOggetto} onSelectTipologia={handleFilterByTipologia} /> */}
                <Oggetto selectAll={true} triennio={filter.triennio} anno={filter.annoInizio} onSelectOggetto={handleFilterByOggetto} />
                {(filter.oggetto == 1 || filter.oggetto == 5) ? (<Tipologia onSelectTipologia={handleFilterByTipologia} />) : ""}
                {(filter.oggetto == 2 || filter.oggetto == 3 || filter.oggetto == 4) ? (<Esonero onSelectEsonero={handleFilterByEsonero} />) : ""}
                <StatoCertificazione onSelect={handleFilterByStato} />
                <Triennio defaultSelectedId={filterByThreeYears ? filterByThreeYears.id : 0} onSelect={handleFilterByTriennio} />
              </div>
            </section>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <section className="d-flex">
              <div className="flex-grow-1 d-flex align-items-center">
                <p className="mx-1">
                  {(certifications.length > 0 ? "certificazioni " + (1 + pagination.limit * (pagination.page-1)) + "-" + (certifications.length + pagination.limit * (pagination.page-1)) + 
                                (certifications.length >= countTotalCertifications ? "" : " di " + countTotalCertifications) : "0 professionisti TOTALI")}
                </p>
              </div>
              <div className="flex-shrink-1 d-flex align-items-center">
                <PageSelect defaultSelectedId={1} totPagine={Math.ceil(countTotalCertifications / pagination.limit)} onSelect={handlePaginationLoad} />
                <Sorting initSortingOptions={CertificationOrderBy} onSelectOrderBy={handleSortingOrderBy} onSelectOrderType={handleSortingOrderType} />
                <PaginationLimit defaultSelectedId={pagination.limit} onSelect={handlePaginationLimit} enableUnlimit={false} />
              </div>
            </section>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <DataTable
              columns={columns} data={certifications}
              noDataComponent={`${error ? error.error : 'Nessun risultato'}`}
              responsive striped dense progressPending={isLoading} />
          </Col>
        </Row>
        <Row className="py-2">
          <Col className="text-center">
          {/* <Pagination.First onClick={() => handlePaginationLoad(1)} disabled={(pagination.page - 1) === 0}>Prima pagina</Pagination.First> */}
            <Button variant="primary" onClick={handlePaginationLoadFirst} className="mx-2" disabled={isLoading || (pagination.page - 1) === 0}>
              Pagina iniziale
            </Button>
            <Button variant="primary" onClick={handlePaginationLoadMinus} className="mx-2" disabled={isLoading || (pagination.page - 1) === 0}>
              Precedente
            </Button>
            <span>pagina {pagination.page}</span>
            <Button variant="primary" onClick={handlePaginationLoadMore} className="mx-2" disabled={isLoading || pagination.limit != certifications.length}>
              Successiva
            </Button>
            <Button variant="primary" onClick={handlePaginationLoadLast} className="mx-2" disabled={isLoading || pagination.limit != certifications.length}>
              Pagina finale
            </Button>
          </Col>
        </Row>
      </Container>

      <Modal size="lg" show={showDetailModal} onHide={handleCloseDetailModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            <p className="display-6 m-0 p-0">Certificazione / Esonero</p> 
            {selectedItem ? (<p className="blockquote m-0 p-0">richiesta {/*<b>#</b><u>{selectedItem.id}</u>*/} inserita il {format(new Date(selectedItem.dataCreazione), 'dd/MM/yyyy')}</p>) : ""}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedItem ? (
            <CertificationDetail certification={selectedItem} />
          ) : (
            <Fragment />
          )}
        </Modal.Body>
      </Modal>

      <Modal centered show={showDelete} onHide={handleCloseDelete}>
        <Modal.Header closeButton>
          <Modal.Title><p className="display-6 m-0 p-0">Certificazione / Esonero</p> {selectedId ? (<p className="blockquote m-0 p-0">CANCELLA richiesta {/*<b>#</b><u>{selectedId}</u>*/}</p>) : ""}</Modal.Title>
        </Modal.Header>
        <Modal.Body><p>Confermi la cancellazione?<br /><small>Cliccando sul bottone ELIMINA verrà cancellata la richiesta selezionata.</small></p></Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDelete} disabled={isLoading}>Annulla</Button>
          <Button variant="danger" onClick={handleDelete} disabled={isLoading}>Elimina</Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default CertificationList;