import { format } from 'date-fns';
import { FC, Fragment, useEffect, useState } from 'react';
import { Badge, Col, Container, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { ICertificationEntity } from '../../entities/certification.entity';
import { ICompanyEntity } from '../../entities/company.entity';
import { IMetadataOption, IMetadataOptionNode } from '../../entities/metadata.entity';
import { RootState } from '../../redux/store';
import { getNome } from './dropdown/certification/OggettoTipologia';
import { getStatoCertificazione } from './dropdown/certification/StatoCertificazione';
import { getOrdineProvinciale } from './dropdown/OrdineProvinciale';
import ShowDetailAttachment from './ShowDetailAttachment';

interface IProps {
  certification: ICertificationEntity;
}

const CertificationDetail: FC<IProps> = (props) => {

  const companiesOrders: Array<ICompanyEntity> = useSelector((s: RootState) => s.companies.orders);
  const oggettiCertificazione: Array<IMetadataOptionNode> = useSelector((s: RootState) => s.metadata.certification.oggetto_certificazione);
  const statiCertificazione: Array<IMetadataOption> = useSelector((s: RootState) => s.metadata.certification.stato_certificazione);  

  // CONTROLLARE se serve active = 0 oppure active = 1
  const esoneri: Array<IMetadataOptionNode> = useSelector((s: RootState) => s.metadata.certification.tipo_esonero_certificazione_all);
  const accreditiStandard: Array<IMetadataOptionNode> = useSelector((s: RootState) => s.metadata.certification.tipo_accredito_certificazione); 
  const accreditiDaDelibera: Array<IMetadataOptionNode> = useSelector((s: RootState) => s.metadata.certification.tipo_accredito_certificazione_non_standard);
  const [accrediti, setAccrediti] = useState<Array<IMetadataOptionNode>>(new Array<IMetadataOptionNode>());
  const [idTipologia, setIdTipologia] = useState<number>(0);
  const [nomeTipologia, setNomeTipologia] = useState<string>("");

  const [allegatiAggiornati, setAllegatiAggiornati] = useState(props.certification.allegati);
  
  useEffect(() => {
    if (accreditiStandard.length && accreditiDaDelibera.length) {
      setAccrediti([...accreditiStandard, ...accreditiDaDelibera]);
    }
  }, [accreditiStandard, accreditiDaDelibera]);

  // useEffect(() => {
  //   console.log("allegati aggiornati!");
  //   if (allegatiAggiornati != props.certification.allegati) {
  //     props.certification.allegati = allegatiAggiornati;
  //     console.log("da modificare");
  //   } 
  //   console.log(allegatiAggiornati);
  // }, [allegatiAggiornati]);

  useEffect(() => {
    if ((props.certification.idOggetto === 1 || props.certification.idOggetto === 5)) {
      if (accrediti.length) {
        setIdTipologia(props.certification.tipoAccreditoCfpId);
        setNomeTipologia(getNome(props.certification.tipoAccreditoCfpId, accrediti));
      }
    } else {
      if (esoneri.length) {
        setIdTipologia(props.certification.tipoEsoneroId);
        setNomeTipologia(getNome(props.certification.tipoEsoneroId, esoneri));
      }
    }
  }, [accrediti, esoneri]);


  return (
    <Fragment>
      <Container fluid as="section" className="m-0 p-0">
        <Row className="mb-1">
          <Col md={3}><b>Stato richiesta</b></Col>
          <Col md={9}><h5><Badge pill bg="dark">{getStatoCertificazione(props.certification.idStatoCertificazione, statiCertificazione)}</Badge></h5></Col>
        </Row>
        <hr className="my-1" />
        <Row className="mb-1">
          <Col md={3}><b>Codice fiscale</b></Col>
          <Col md={9}>{props.certification.codiceFiscale}</Col>
        </Row>
        <hr className="my-1" />
        <Row className="mb-1">
          <Col md={3}><b>Note</b></Col>
          <Col md={9}>{props.certification.noteRichiedente}</Col>
        </Row>
        <hr className="my-1" />
        <Row className="mb-1">
          <Col md={3}><b>Oggetto</b></Col>
          <Col md={9}>{props.certification.idOggetto}. {getNome(props.certification.idOggetto, oggettiCertificazione)}</Col>
        </Row>
        <Row className="mb-1">
          <Col md={3}><b>Tipologia</b></Col>
          {/* <Col md={9}>{props.certification.idTipologia}. {getOggettoTipologia(props.certification.idOggetto, props.certification.idTipologia, tipologieCertificazione)}</Col> */}
          <Col md={9}>{idTipologia}. {nomeTipologia}</Col>
        </Row>
        {
          (props.certification.idOggetto === 1) ? (
            <Row className="mb-1">
              <Col md={3}><b>Org. / Titolo / Cod.</b></Col>
              <Col md={9}>
                {(props.certification.organizzatore != null && props.certification.organizzatore.length > 0) ? props.certification.organizzatore : "--"} &nbsp; <b>/</b> &nbsp;
                {(props.certification.titolo != null && props.certification.titolo.length > 0) ? props.certification.titolo : "--"} &nbsp; <b>/</b> &nbsp;
                {(props.certification.codiceCorso != null && props.certification.codiceCorso.length > 0) ? props.certification.codiceCorso : "--"}         
              </Col>
            </Row>
          ) : (<Fragment />)
        }
        <hr className="my-1" />
        <Row className="mb-1">
          <Col md={3}><b>Periodo rif.</b></Col>
          <Col md={9}>{format(new Date(props.certification.dataRiferimentoDa), 'dd/MM/yyyy')} - {format(new Date(props.certification.dataRiferimentoA), 'dd/MM/yyyy')}</Col>
        </Row>
        <hr className="my-1" />
        <Row className="mb-1">
          <Col md={3}><b>CFP dic. / assegnati</b></Col>
          <Col md={9}>{props.certification.cfpDichiarati} / {props.certification.cfpAssegnati}</Col>
        </Row>
        <hr className="my-1" />
        <Row className="mb-1">
          <Col md={3}><b>Ordine</b></Col>
          <Col md={9}>{getOrdineProvinciale(props.certification.idAzienda, companiesOrders)}</Col>
        </Row>
        <hr className="my-1" />
        {allegatiAggiornati ? (
          <Row className="mb-1">
            <Col><ShowDetailAttachment stato={props.certification.idStatoCertificazione} 
            allegatiAggiornati={allegatiAggiornati} setAllegatiAggiornati={setAllegatiAggiornati} /></Col>
          </Row>
        ) : ("Nessun allegato")}
      </Container>
    </Fragment>
  );
};

export default CertificationDetail;