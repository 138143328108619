import { format } from 'date-fns';
import React, { FC, Fragment, useEffect, useState } from 'react';
import { Badge, Button, Col, Container, FormControl, InputGroup, ListGroup, Modal, Row, Spinner } from 'react-bootstrap';
import { Search, X } from 'react-bootstrap-icons';
import DataTable, { TableColumn } from 'react-data-table-component';
import { BsCheckCircle, BsXCircle } from 'react-icons/bs';
import { FaUserCheck, FaUserClock } from 'react-icons/fa';
import { MdRefresh } from 'react-icons/md';
import { IoEllipsisVerticalCircle } from 'react-icons/io5';
import { useDispatch, useSelector } from 'react-redux';
import { ISubscriptionEntity } from '../../entities/subscription.entity';
import { SubscriptionState } from '../../enums/subscription-state.enum';
import { deleteSubscriptions, getPaymentTReceipt, getSubscriberExport, getSubscribers, IDeleteSubscriptionsThunkPayload, IGetPaymentTReceiptThunkPayload, IGetSubscriberExportFulfilledPayload, IGetSubscribersThunkPayload, IPutSubscriptionsStatusThunkPayload, putSubscriptionsStatus } from '../../redux/slices/course.slice';
import { AppDispatch, RootState } from '../../redux/store';
import { b64toBlob } from '../../utils/attachment-parser';
import { paginationComponentOptions } from '../../utils/list-default';
import Status from '../shared/dropdown/course/Status';
import SubscriberAddForm from './SubscriberAddForm';
import SubscriberEditStatusForm from './SubscriberEditStatusForm';
import SubscriberImportForm from './SubscriberImportForm';
import SubscriberImportFormIMateria from './SubscriberImportFormIMateria';
import TelematicReceipt from '../shared/dropdown/course/TelematicReceipt';
import CertificationFormCfpOnly from './CertificationFormCfpOnly';
import { ICourseEntityRaw } from '../../entities/course.entity';

interface IProps {
    idCorso: number;
    showDialogCert: boolean;
}

const CourseSubscriptionList: FC<IProps> = (props) => {
    const dispatch = useDispatch<AppDispatch>();

    const [isLoadingExport, setIsLoadingExport] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [textSearch, setTextSearch] = useState<string>('');
    const [filterByStatus, setFilterByStatus] = useState<SubscriptionState | null>(null);
    const [filterByTelematicReceipt, setFilterByTelematicReceipt] = useState<number | null>(null);
    const [isSelectTableRows, setiIsSelectTableRows] = useState<boolean>(false);
    const [showCfpInsertModal, setShowCfpInsertModal] = useState(false);
    const [selectedItem, setSelectedItem] = useState<ISubscriptionEntity | null>(null);

    const handleOpenCfpInsertModal = (cf: ISubscriptionEntity) => {
        setSelectedItem(cf);
        setShowCfpInsertModal(true);
    }

    const handleCloseCfpInsertModal = () => {
        setShowCfpInsertModal(false);
        setSelectedItem(null);
    };

    const handleExport = () => {
        setIsLoadingExport(true);
        dispatch(getSubscriberExport({ idCorso: props.idCorso }))
            .unwrap()
            .then((e) => {
                const payload = e as unknown as IGetSubscriberExportFulfilledPayload;
                const div = document.getElementById("divSubscribersExportAttachmentCSV");
                if (div) {
                    b64toBlob(payload.attachment.contentRaw, payload.attachment.contentType).then((blobresult: Blob) => {
                        const url = window.URL.createObjectURL(blobresult);
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', payload.attachment.filename);
                        div.appendChild(link);
                        link.click();
                        link.remove();
                    });
                }
            })
            .catch((e) => {
                // console.log("KO", e);
            })
            .finally(() => setIsLoadingExport(false));
    };

    const [dInizio, setDInizio] = useState<string>((new Date()).toISOString());

    const handleSetDataInizio = (dt: string | null) => {
        if(dt) {
            setDInizio(dt);
        }
    };
    
    const evento: ICourseEntityRaw | null = useSelector((s: RootState) => s.course.value);
    
    useEffect(() => {
        const dIn: string | null = evento && evento?.dataInizio && evento.dataInizio.length > 0 ? evento.dataInizio : null;
        handleSetDataInizio(dIn);
    }, [evento]);

    const subscriptions: Array<ISubscriptionEntity> = useSelector((s: RootState) => s.course.value?.iscritti ? s.course.value.iscritti : []);

    const subscriptionsFiltered: Array<ISubscriptionEntity> = useSelector((s: RootState) => s.course.value?.iscritti ? (
        s.course.value.iscritti
            .filter((item: ISubscriptionEntity) => {
                const status = filterByStatus;
                if (status === null) return true;
                if (status === item.state) return true;
                return false;
            })
            .filter((item: ISubscriptionEntity) => {
                const rt = filterByTelematicReceipt;
                if (rt === null) return true;
                if (item.inAttesaAcconto === rt) return true;
                return false;
            })
            .filter((item: ISubscriptionEntity) => {
                const search = textSearch;
                if (!search || !search.length) return true;
                if (item.codiceFiscale.toUpperCase().includes(search.trim().toUpperCase())) return true;
                if (item.cognome.toLowerCase().includes(search.trim().toLowerCase())) return true;
                if (item.nome.toLowerCase().includes(search.trim().toLowerCase())) return true;
                return false;
            })
    ) : []);

    const [countExt, setCountExt] = useState<number>(0);
    const [countTot, setCountTot] = useState<number>(0);
    const [countInt, setCountInt] = useState<number>(0);
    const [countNoRT, setCountNoRT] = useState<number>(0);
    const [countPending, setCountPending] = useState<number>(0);
    const [countIngoing, setCountIngoing] = useState<number>(0);
    const [countAttemptPayment, setCountAttemptPayment] = useState<number>(0);
    const [countConfirmed, setCountConfirmed] = useState<number>(0);
    const [countCanceled, setCountCanceled] = useState<number>(0);
    const [countAttendance, setCountAttendance] = useState<number>(0);
    const [countNonAttendance, setCountNonAttendance] = useState<number>(0);

    const [selectedRows, setSelectedRows] = useState<Array<ISubscriptionEntity>>(new Array<ISubscriptionEntity>());
    const [toggleSelectedRowsCleared, setToggleSelectedRowsCleared] = useState(false);

    const [showModalConfirmed, setShowModalConfirmed] = useState(false);

    const handleClearIscritti = () => {
        if (selectedRows.length) { 
            handleFilterByStatus(null);
            setTextSearch('');
            setFilterByTelematicReceipt(null);
            setToggleSelectedRowsCleared(!toggleSelectedRowsCleared);
            setSelectedRows(new Array<ISubscriptionEntity>());
        }
    };

    const handleGetSubscribersDispatch = () => {
        //setIsLoading(true);
        dispatch(getSubscribers({ idCorso: props.idCorso } as IGetSubscribersThunkPayload))
            .then(() => handleClearIscritti())
            .finally(() => setIsLoading(false));
    };

    const handleShow = () => setShowModalConfirmed(true);
    const handleClose = () => {
        setShowModalConfirmed(false);
        handleGetSubscribersDispatch();
    };

    const handleList = (selected: any) => {
        setSelectedRows(selected.selectedRows);
    };

    const handleDeleteSubscribers = (e: React.MouseEvent) => {
        setIsLoading(true);
        dispatch(deleteSubscriptions({ idCorso: props.idCorso, ids: selectedRows.map(elem => elem.id) } as IDeleteSubscriptionsThunkPayload))
            .unwrap()
            .then(() => handleClose())
            .catch(() => setIsLoading(false));
    };

    const handleCount = (subs: Array<ISubscriptionEntity>) => {
        const n = subs.length;
        setCountTot(n);
        const nExt = subs.filter((e: ISubscriptionEntity) => e.esterna).length;
        setCountExt(nExt);
        const nInt = n - nExt;
        setCountInt(nInt);

        const nPending = subs.filter((e: ISubscriptionEntity) => e.state === SubscriptionState.Pending).length;
        setCountPending(nPending);
        const nIngoing = subs.filter((e: ISubscriptionEntity) => e.state === SubscriptionState.Ingoing).length;
        setCountIngoing(nIngoing);
        const nAttemptPayment = subs.filter((e: ISubscriptionEntity) => e.state === SubscriptionState.AttemptPayment).length;
        setCountAttemptPayment(nAttemptPayment);
        const nConfirmed = subs.filter((e: ISubscriptionEntity) => e.state === SubscriptionState.Confirmed).length;
        setCountConfirmed(nConfirmed);
        const nCanceled = subs.filter((e: ISubscriptionEntity) => e.state === SubscriptionState.Canceled).length;
        setCountCanceled(nCanceled);
        const nAttendance = subs.filter((e: ISubscriptionEntity) => e.state === SubscriptionState.Attendance).length;
        setCountAttendance(nAttendance);
        const nNonAttendance = subs.filter((e: ISubscriptionEntity) => e.state === SubscriptionState.NonAttendance).length;
        setCountNonAttendance(nNonAttendance);
        const nNoRT = subs.filter((e: ISubscriptionEntity) => e.inAttesaAcconto === 1).length;
        setCountNoRT(nNoRT);
    }

    useEffect(() => {
        handleCount(subscriptions);
    }, [subscriptions]);

    const handleDownloadRTDispatch = (idCorso: number, codiceFiscale: string) => {
        setIsLoading(true);
        dispatch(getPaymentTReceipt({idCorso: idCorso, codiceFiscale: codiceFiscale} as IGetPaymentTReceiptThunkPayload))
            .unwrap()
            .then(() => handleGetSubscribersDispatch())
            .catch(() => setIsLoading(false));
    };

    const columns: TableColumn<ISubscriptionEntity>[] = [
        {
            name: 'Professionista',
            sortable: true,
            selector: (row: ISubscriptionEntity) => (row.stato != null && row.stato.length > 0 && row.stato.toLowerCase() !== 'iscritto' ? '[' + row.stato + '] ' : '') + (row.cognome != null ? row.cognome.toUpperCase() : '') + ' ' + row.nome,
            grow: 3,
        },
        {
            name: 'Codice fiscale',
            sortable: true,
            selector: (row: ISubscriptionEntity) => row.codiceFiscale,
            grow: 2,
        },
        {
            name: 'Esterno',
            sortable: true,
            selector: (row: ISubscriptionEntity) => row.esterna ? 'Sì' : 'No',
            grow: 0,
        },
        {
            name: 'Iscritto',
            sortable: true,
            selector: (row: ISubscriptionEntity) => {
                if (!row.dtConfReg) return '';
                return format(new Date(row.dtConfReg), 'dd/MM/yyyy');
            },
            sortFunction: (a: ISubscriptionEntity, b: ISubscriptionEntity) => {
                return new Date(b.dtConfReg).getTime() - new Date(a.dtConfReg).getTime();
            },
            grow: 1,
            compact: true,
        },
        {
            name: 'Presenza',
            sortable: true,
            selector: (row: ISubscriptionEntity) => {
                if (!row.dtConfPres) return '';
                return format(new Date(row.dtConfPres), 'dd/MM/yyyy');
            },
            sortFunction: (a: ISubscriptionEntity, b: ISubscriptionEntity) => {
                return new Date(b.dtConfPres).getTime() - new Date(a.dtConfPres).getTime();
            },
            grow: 1,
            compact: true,
        },
        {
            name: 'R.T.',
            sortable: true,
            selector: (row: ISubscriptionEntity) => {
                if (row.inAttesaAcconto === 1) {
                    return "Mancante";
                }
                return "";
            },
            cell: (row) => {
                if (row.inAttesaAcconto === 1) {
                    return (
                    <Badge pill bg="info" className="text-dark c-pointer" onClick={() => handleDownloadRTDispatch(props.idCorso, row.codiceFiscale)}>
                        <span>Mancante</span>
                        <MdRefresh size={16} />
                    </Badge>
                    );
                }
                return "";
            },
            grow: 1,
            compact: true,
        },
        {
            name: 'Stato',
            sortable: true,
            selector: (row: ISubscriptionEntity) => row.state ? row.state.toString() : 0,
            cell: (row, index, column, id) => {
                if (row.state === SubscriptionState.NonAttendance) {
                    return (<Badge pill bg="warning">Assente</Badge>);
                }
                else if (row.state === SubscriptionState.Attendance) {
                    return (<Badge pill bg="success">Presente</Badge>);
                }
                else if (row.state === SubscriptionState.Canceled) {
                    return (<Badge pill bg="danger">Annullata</Badge>);
                }
                else if (row.state === SubscriptionState.Confirmed) {
                    return (<Badge pill bg="primary">Iscritto</Badge>);
                }
                else if (row.state === SubscriptionState.AttemptPayment) {
                    return (<Badge pill bg="light" text="primary" className="border border-primary">In pagamento</Badge>);
                }
                else if (row.state === SubscriptionState.Ingoing) {
                    return (<Badge pill bg="light" text="dark" className="border border-dark">Pre-iscritto</Badge>);
                }

                return (<Badge pill bg="dark">Lista d'attesa</Badge>);
            },
            grow: 1,
            compact: true,
        },
        {
            cell: (row, index, column, id) => (
                <div className="d-flex justify-content-end">
                    <FaUserCheck className={"text-success c-pointer mx-2 my-1 " + (row.state !== SubscriptionState.Confirmed ? 'invisible' : 'visible')} title="Segna presenza" 
                        onClick={(e: React.MouseEvent<SVGElement>) => {
                            e.preventDefault();
                            const dIniz = dInizio;
                            handleChangeState(SubscriptionState.Attendance, row, dIniz);
                        }} size={20} />
                    <FaUserClock className={"text-warning c-pointer mx-2 my-1 " + (row.state !== SubscriptionState.Confirmed ? 'invisible' : 'visible')} title="Segna assenza" 
                        onClick={(e: React.MouseEvent<SVGElement>) => {
                            e.preventDefault();
                            handleChangeState(SubscriptionState.NonAttendance, row, (new Date()).toISOString());
                        }} size={20} />
                    <BsCheckCircle className={"text-primary c-pointer mx-2 my-1 " + (row.state !== SubscriptionState.Pending ? 'invisible' : 'visible')} title="Iscrivi" 
                        onClick={(e: React.MouseEvent<SVGElement>) => {
                            e.preventDefault();
                            handleChangeState(SubscriptionState.Confirmed, row, (new Date()).toISOString());
                        }} size={20} />
                    <BsXCircle className={"text-danger c-pointer mx-2 my-1 " + (row.state === SubscriptionState.Attendance || row.state === SubscriptionState.NonAttendance || row.dtCancReg ? 'invisible' : 'visible')} title="Annulla" 
                        onClick={(e: React.MouseEvent<SVGElement>) => {
                            e.preventDefault();
                            handleChangeState(SubscriptionState.Canceled, row, (new Date()).toISOString());
                        }} size={20} />
                    <MdRefresh className={"text-dark c-pointer mx-2 my-1 " + (row.state === SubscriptionState.Pending ? 'invisible' : 'visible')} title="Ritorna in lista d'attesa" 
                        onClick={(e: React.MouseEvent<SVGElement>) => {
                            e.preventDefault();
                            handleChangeState(SubscriptionState.Pending, row, (new Date()).toISOString());
                        }} size={20} />
                    <IoEllipsisVerticalCircle className={"text-dark c-pointer mx-2 my-1 " + (props.showDialogCert ? 'visible' : 'invisible')} title="Inserimento cfp" 
                        onClick={(e: React.MouseEvent<SVGElement>) => {
                            e.preventDefault();
                            handleClickItem(row);
                        }} size={20} />
                </div>
            ),
            ignoreRowClick: true,
            grow: 2,
        },
    ] as TableColumn<ISubscriptionEntity>[];

    const handleEditSubscriptionStateDispatch = (value: IPutSubscriptionsStatusThunkPayload) => {
        setIsLoading(true);
        dispatch(putSubscriptionsStatus(value))
            .unwrap()
            .then(() => handleGetSubscribersDispatch())
            .catch(() => setIsLoading(false));
    };

    const handleChangeState = (newState: SubscriptionState, sub: ISubscriptionEntity, dataRiferimento: string) => {
        const valuex: IPutSubscriptionsStatusThunkPayload = {
            idCorso: props.idCorso,
            param: {
                fiscalCodes: [sub.codiceFiscale],
                newStatus: Number(newState.toString()),
                dateRif: dataRiferimento
            }
        } as IPutSubscriptionsStatusThunkPayload;

        handleEditSubscriptionStateDispatch(valuex);
    };

    const handleClickItem = (cf: ISubscriptionEntity) => {
        // console.log("premuto i: " + cf.codiceFiscale);
        //       setSelectedItem(cert);
        handleOpenCfpInsertModal(cf);
    };

    /*
    const handleChangeState = (newState: SubscriptionState, sub: ISubscriptionEntity) => {
        let subscr: ISubscriptionEntity = {
            ...sub,
            state: newState,
        } as ISubscriptionEntity;

        if (newState === SubscriptionState.NonAttendance) {
            subscr = {
                ...subscr,
                dtCancPres: new Date().toISOString(),
                dtInsPres: "",
                dtConfPres: "",
            } as ISubscriptionEntity;
        }
        else if (newState === SubscriptionState.Attendance) {
            subscr = {
                ...subscr,
                dtInsPres: new Date().toISOString(),
                dtConfPres: new Date().toISOString(),
                dtCancPres: "",
            } as ISubscriptionEntity;
        }
        else if (newState === SubscriptionState.Canceled) {
            subscr = {
                ...subscr,
                dtCancReg: new Date().toISOString(),
            } as ISubscriptionEntity;
        }
        else if (newState === SubscriptionState.Confirmed) {
            subscr = {
                ...subscr,
                dtConfReg: new Date().toISOString(),
            } as ISubscriptionEntity;
        }
        else if (newState === SubscriptionState.Pending) {
            subscr = {
                ...subscr,
                dtInsReg: new Date().toISOString(),
                dtConfReg: "",
                dtCancReg: "",
                dtInsPres: "",
                dtConfPres: "",
                dtCancPres: "",
            } as ISubscriptionEntity;
        }

        dispatch(putSubscription({ subscription: subscr } as IPutSubscriptionThunkPayload));
    };
    */

    const [showImportModal, setShowImportModal] = useState(false);
    const handleOpenImportModal = () => setShowImportModal(true);
    const handleCloseImportModal = () => {
        setShowImportModal(false);
        handleGetSubscribersDispatch();
    };

    const [showImportImateriaModal, setShowImportImateriaModal] = useState(false);
    const handleOpenImportImateriaModal = () => setShowImportImateriaModal(true);
    const handleCloseImportImateriaModal = () => {
        setShowImportImateriaModal(false);
        handleGetSubscribersDispatch();
    };

    const [showAddModal, setShowAddModal] = useState(false);
    const handleOpenAddModal = () => setShowAddModal(true);
    const handleCloseAddModal = () => {
        setShowAddModal(false);
    };

    const handleFilterByStatus = (option: SubscriptionState | null) => {
        if (option!=null){
            setiIsSelectTableRows(true);
        } else {
            setiIsSelectTableRows(false);
        }
        setFilterByStatus(option);
    };

    const handleFilterByTelematicReceipt = (option: number | null) => {
        setFilterByTelematicReceipt(option);
    };


    const handleEditSubscriptionState = (state: SubscriptionState, date: string) => {
        const valuex: IPutSubscriptionsStatusThunkPayload = {
            idCorso: props.idCorso,
            param: {
                fiscalCodes: selectedRows.map(elem => elem.codiceFiscale),
                newStatus: Number(state.toString()),
                dateRif: date
            }
        } as IPutSubscriptionsStatusThunkPayload;

        handleEditSubscriptionStateDispatch(valuex);
    };

    return (
        <Fragment>
            <Container fluid as="section" className="m-0 p-0">
                <Row>
                    <Col className="mb-3 d-flex justify-content-end">
                        <Button type="button" variant="outline-dark" onClick={handleOpenAddModal}>Aggiungi iscrizione</Button>
                        {/* <Button type="button" variant="outline-dark" className="mx-2" onClick={handleOpenImportImateriaModal}>Import da IM@TERIA</Button> */}
                        <Button type="button" variant="outline-dark" className="mx-2" onClick={handleOpenImportModal}>Import</Button>
                        <Button type="button" variant="outline-dark" className="ms-2" onClick={handleExport}>
                            {isLoadingExport ? (
                                <Fragment>
                                    <Spinner as="span" animation="border" size="sm" role="status" />
                                    <span className="ms-1">Attendere</span>
                                    <span>...</span>
                                </Fragment>
                            ) : (
                                <span>Export in CSV</span>
                            )}
                        </Button>
                        <div id="divSubscribersExportAttachmentCSV" className="d-none"></div>
                    </Col>
                </Row>
                <Row>
                    <Col className="mb-3 d-flex justify-content-between">
                        <ListGroup horizontal>
                            <ListGroup.Item>
                                Totale <Badge pill bg="secondary">{countTot}</Badge>
                                {countNoRT === 0 ? (<Fragment />) : (
                                    <>&nbsp; di cui RT mancante <Badge pill bg="info" className="text-dark">{countNoRT}</Badge></>
                                )}
                            </ListGroup.Item>
                            <ListGroup.Item>Esterni <Badge pill bg="secondary">{countExt}</Badge></ListGroup.Item>
                            <ListGroup.Item>Interni <Badge pill bg="secondary">{countInt}</Badge></ListGroup.Item>
                        </ListGroup>
                        <ListGroup horizontal>
                            <ListGroup.Item>Lista d'attesa <Badge pill bg="dark">{countPending}</Badge></ListGroup.Item>
                            {
                                countIngoing === 0 ? (<Fragment />) : (
                                    <ListGroup.Item>Pre-iscritti <Badge pill bg="light" text="dark" className="border border-dark">{countIngoing}</Badge></ListGroup.Item>
                                )
                            }
                            {
                                countAttemptPayment === 0 ? (<Fragment />) : (
                                    <ListGroup.Item>In pagamento <Badge pill bg="light" text="primary" className="border border-primary">{countAttemptPayment}</Badge></ListGroup.Item>
                                )
                            }
                            <ListGroup.Item>Iscritti <Badge pill bg="primary">{countConfirmed}</Badge></ListGroup.Item>
                            <ListGroup.Item>Annullati <Badge pill bg="danger">{countCanceled}</Badge></ListGroup.Item>
                            <ListGroup.Item>Presenti <Badge pill bg="success">{countAttendance}</Badge></ListGroup.Item>
                            {
                                countNonAttendance === 0 ? (<Fragment />) : (
                                    <ListGroup.Item>Assenti <Badge pill bg="warning">{countNonAttendance}</Badge></ListGroup.Item>
                                )
                            }
                        </ListGroup>
                    </Col>
                </Row>
                <Row>
                    <Col className={`mb-3 ${countTot > 0 ? '' : 'd-none'}`}>
                        <InputGroup className="w-100">
                            <FormControl placeholder="Cerca digitando: cognome e/o nome, codice fiscale e premi INVIO..." id="idTextSearch"
                                onKeyPress={(event: React.KeyboardEvent<HTMLInputElement>) => {
                                    if (event.key === 'Enter') {
                                        event.preventDefault();
                                        setTextSearch(event.currentTarget.value);
                                    }
                                }} />
                            <InputGroup.Text title="Pulisci filtro ricerca" className="c-pointer"
                                onClick={(event: React.MouseEvent) => {
                                    event.preventDefault();
                                    const element = document.getElementById("idTextSearch") as HTMLInputElement;
                                    if (element) {
                                        element.value = '';
                                    }
                                    setTextSearch('');
                                }}><X size={20} /></InputGroup.Text>
                            <InputGroup.Text title="Inizia la ricerca" className="c-pointer"
                                onClick={(event: React.MouseEvent) => {
                                    event.preventDefault();
                                    const element = document.getElementById("idTextSearch") as HTMLInputElement;
                                    if (element) {
                                        setTextSearch(element.value);
                                    }
                                }}><Search size={20} /></InputGroup.Text>
                        </InputGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {
                            isLoading ? (
                                <Fragment />
                            ) : (
                                selectedRows.length ? (
                                    <section className="d-flex bg-color-palette-4 border border-secondary rounded p-1">
                                        <div className="flex-grow-1 d-flex align-items-center mx-2">
                                            <h6><b>{selectedRows.length}</b> record selezionati</h6>
                                        </div>
                                        <div className="flex-grow-1 d-flex mx-2">
                                            {
                                                filterByStatus == null ? (
                                                    <Fragment />
                                                ) : (
                                                    <SubscriberEditStatusForm initialState={filterByStatus} onSubmit={handleEditSubscriptionState} />
                                                )
                                            }
                                        </div>
                                        <div className="flex-shrink-1 d-flex mx-2">
                                            <Button variant="secondary" onClick={handleClearIscritti}>
                                                Annulla
                                            </Button>
                                        </div>
                                        <div className="flex-shrink-1 d-flex mx-2">
                                            <Button variant="danger" onClick={handleShow}>
                                                Elimina
                                            </Button>
                                        </div>
                                    </section>
                                ) : (
                                    <div className={`d-flex justify-content-end my-1 ${countTot === 0 || isLoading || selectedRows.length > 0 ? 'd-none' : ''}`}>
                                        <TelematicReceipt onSelect={handleFilterByTelematicReceipt} defaultSelectedId={filterByTelematicReceipt} />
                                        <Status onSelect={handleFilterByStatus} defaultSelectedId={filterByStatus} />
                                    </div>
                                )
                            )
                        }
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <DataTable
                            columns={columns}
                            data={subscriptionsFiltered}
                            pagination
                            responsive
                            striped
                            dense
                            noDataComponent="Nessun risultato"
                            paginationComponentOptions={paginationComponentOptions}
                            selectableRows={isSelectTableRows}
                            progressPending={isLoading}
                            onSelectedRowsChange={handleList}
                            clearSelectedRows={toggleSelectedRowsCleared}
                        />
                    </Col>
                </Row>
            </Container>

            <Modal show={showAddModal} onHide={handleCloseAddModal} centered size="lg">
                <Modal.Header closeButton>
                    <Modal.Title><h3>Aggiungi iscrizione</h3></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <SubscriberAddForm idCorso={props.idCorso} />
                </Modal.Body>
            </Modal>

            <Modal show={showImportImateriaModal} onHide={handleCloseImportImateriaModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title><h3>Import iscrizioni da IM@TERIA</h3></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <SubscriberImportFormIMateria idCorso={props.idCorso} />
                </Modal.Body>
            </Modal>

            <Modal show={showImportModal} onHide={handleCloseImportModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title><h3>Import iscrizioni</h3></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <SubscriberImportForm idCorso={props.idCorso} />
                </Modal.Body>
            </Modal>

            <Modal show={showModalConfirmed} onHide={handleClose} animation={true}>
                <Modal.Header closeButton>
                    <Modal.Title>Confermi la cancellazione?</Modal.Title>
                </Modal.Header>
                <Modal.Body><p>La cancellazione degli iscritti selezionati è un'operazione che <strong>NON può essere ripristinata</strong>.<br /></p><p>Procedere con la cancellazione?</p></Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose} disabled={isLoading}>
                        Annulla
                    </Button>
                    <Button variant="danger" onClick={handleDeleteSubscribers} disabled={isLoading}>
                        Conferma
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal size="lg" centered show={showCfpInsertModal} onHide={handleCloseCfpInsertModal}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <p className="display-6 m-0 p-0">Inserisci nuova richiesta per</p>
                        <p>{selectedItem?.cognome + ' ' + selectedItem?.nome}</p>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CertificationFormCfpOnly codiceFiscale={selectedItem?.codiceFiscale || ''} onSubmitSuccess={handleCloseCfpInsertModal}/>
                </Modal.Body>
            </Modal>
        </Fragment>
    );
};

export default CourseSubscriptionList;