import { FC, Fragment, useState, useRef } from 'react';
import { Button, Card, Container, Form, Modal, Spinner } from 'react-bootstrap';
import { CheckCircleFill, XCircleFill } from 'react-bootstrap-icons';
import { useDispatch } from 'react-redux';
import { IRejectResponse } from '../../api/api.interface';
import PageParagraph from '../../components/PageParagraph';
import PageSubtitle from '../../components/PageSubtitle';
import PageTitle from '../../components/PageTitle';
import { IPostCoursesImportFulfilledPayload, IPostCoursesImportThunkPayload, postCoursesImport } from '../../redux/slices/courses.slice';
import { AppDispatch } from '../../redux/store';
import { ICourseEntity } from '../../entities/course.entity';
import { b64toBlob } from '../../utils/attachment-parser';
import CourseListImportResults from '../../components/admin/CourseListImportResults';

interface IForm extends IPostCoursesImportThunkPayload { }

const AdminImport: FC = () => {
    const dispatch = useDispatch<AppDispatch>();

    const [fields, setFields] = useState({
        fileName: '',
        contentType: '',
        contentRaw: '',
    } as IForm);

    const [isLoading, setLoading] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [error, setError] = useState<IRejectResponse>();
    const [total, setTotal] = useState<number>(0);

    const [showResultsModal, setShowResultsModal] = useState(false);
    const [scarti, setScarti] = useState<Array<ICourseEntity>>(new Array);
    const [validati, setValidati] = useState<Array<ICourseEntity>>(new Array);
    const [duplicati, setDuplicati] = useState<Array<ICourseEntity>>(new Array);

    const fileInputRefCorsi = useRef<HTMLInputElement | null>(null);

    const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const target = event.target as HTMLInputElement;
        const files = event.target.files as FileList;

        if (files.length) {
            const file_name = files[0].name;
            const file_type = files[0].type;

            const fileReader = new FileReader();
            fileReader.onload = (e: ProgressEvent<FileReader>) => {
                if (e.target?.result) {
                    const res = e.target.result;
                    setFields({
                        fileName: file_name,
                        contentType: file_type,
                        contentRaw: res,
                    });
                }
            };

            fileReader.readAsDataURL(files[0]);
        }
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        //setHasError(false);
        // console.log("fields: ", fields);

        setLoading(true);

        dispatch(postCoursesImport(fields as IPostCoursesImportThunkPayload))
            .unwrap()
            .then((value) => {
                const fulfilledPayload = value as unknown as IPostCoursesImportFulfilledPayload;
                console.log("Response:", fulfilledPayload);
                
                // Accedi ai dati dal payload della risposta
                const { importStatus } = fulfilledPayload;
                console.log("Scarti:", importStatus.scarti);
                console.log("Validi:", importStatus.validati);
                console.log("rendiconto:", importStatus.rendiconto);
            
                if (importStatus.rendiconto.contentRaw != null && importStatus.rendiconto.contentRaw.length > 0) {
                    const div = document.getElementById("divDownloadRendicontoImportazione");
                    if (div) {
                        b64toBlob(importStatus.rendiconto.contentRaw, importStatus.rendiconto.contentType).then((blobresult: Blob) => {
                            console.log("blobresult:", blobresult);
                            const url = window.URL.createObjectURL(blobresult);
                            const link = document.createElement('a');
                            link.href = url;
                            link.setAttribute('download', importStatus.rendiconto.filename);
                            div.appendChild(link);
                            link.click();
                            link.remove();
                        });
                    }
                }               
                
                setFields({ fileName: '', contentType: '', contentRaw: '' });
                if (fileInputRefCorsi.current) {
                    fileInputRefCorsi.current.value = "";
                }
            
                setScarti(importStatus.scarti);
                setValidati(importStatus.validati);
                setDuplicati(importStatus.duplicati);                
            
                // setSubmitted(true);
                // setTotal(res.countCourses);
                // setTotal(importStatus.validati.length);

                console.log("azzerati: ", fields);
                setShowResultsModal(true);
            })
            .catch((e) => {
                setSubmitted(true);
                // const err = e as unknown as IRejectResponse;
                // setError(err);
            })
            .finally(() => setLoading(false));
    };

    return (
        <Fragment>
            <PageTitle content="Import" />
            <PageSubtitle content="Importa lista corsi" />
            <PageParagraph content="" />
            <Container fluid as="section">
                <Card className="mt-3">
                    <Card.Body>
                        {submitted ? (
                            <div className="text-center">
                                <XCircleFill size={100} className="text-warning m-5" />
                                <h3 className="text-warning">Attenzione, qualcosa è andato storto.</h3>
                                {/* <CheckCircleFill size={100} className="text-success m-5" />
                                <h3 className="text-success">Operazione completata!</h3>
                                <p>Inseriti {total} corsi.</p> */}
                            </div>
                        ) : (
                            // <div>
                            //     <p><b>Le funzioni di importazione sono disabilitate. Ne stiamo effettuando un nuovo collaudo.</b></p>
                            // </div>
                            <Form noValidate onSubmit={handleSubmit} >
                                {/* <p><b>Si raccomanda di NON modificare il file ottenuto dalla piattaforma di esportazione.</b></p>
                                <p><b>Aggiornamento 06 giugno 2022:</b> <u>La piattaforma ora importa il nuovo formato file esportato da Im@teria.</u></p> */}
                                <p><a href="/assets/documents/import-corsi-esempio.csv" download={`import-corsi-esempio_${new Date().getTime()}.csv`}>Clicca QUI per scaricare il file CSV di esempio</a></p>
                                <p>Eventuali scarti saranno visualizzati e scaricati come file excel</p>
                                <Form.Group controlId="formFile" className="mb-3">
                                    <Form.Label>File da importare</Form.Label>
                                    <Form.Control type="file" name="file" onChange={handleFileUpload} ref={fileInputRefCorsi} />
                                    <Form.Text className="text-danger">{error?.error ? error.error : ''}</Form.Text>
                                </Form.Group>
                                <Button type="submit" className="w-25 float-right" disabled={isLoading}>
                                    {isLoading ? (
                                        <Fragment>
                                            <Spinner as="span" animation="border" size="sm" role="status" />
                                            <span className="ms-1">Attendere</span>
                                            <span>...</span>
                                        </Fragment>
                                    ) : (
                                        <span>Salva</span>
                                    )}
                                </Button>
                            </Form>
                        )}
                    </Card.Body>
                </Card>
            </Container>

            <Modal show={showResultsModal} onHide={() => setShowResultsModal(false)} size="xl" centered>
                <Modal.Header closeButton>
                    <Modal.Title><h3>Risultati importazione</h3></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CourseListImportResults validati={validati} scarti={scarti} duplicati={duplicati}/>
                </Modal.Body>
            </Modal>
            <div id="divDownloadRendicontoImportazione" className="d-none"></div>
        </Fragment>
    );
};

export default AdminImport;
