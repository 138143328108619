import { format } from 'date-fns';
import { FC, Fragment, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import DataTable, { TableColumn } from 'react-data-table-component';
import { ICourseEntity } from '../../entities/course.entity';

interface IProps { 
  validati?: Array<ICourseEntity>;
  scarti?: Array<ICourseEntity>;
  duplicati?: Array<ICourseEntity>;
}

const CourseListImportResults: FC<IProps> = (props) => {

    const [coursesListScarti, setCoursesListScarti] = useState(props.scarti || new Array<ICourseEntity>());
    const [coursesListDuplicati, setCoursesListDuplicati] = useState(props.duplicati || new Array<ICourseEntity>());
    const [coursesList, setCoursesList] = useState(new Array<ICourseEntity>());

  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    if (coursesListScarti.length || coursesListDuplicati.length) { 
      setCoursesList(coursesListScarti.concat(coursesListDuplicati));
    } 
    setLoading(false);
  }, [coursesListScarti, coursesListDuplicati]);

  const columns: TableColumn<ICourseEntity>[] = [
    {
      id: 'id',
      name: '#Id',
      selector: (row: ICourseEntity) => row.id,
      cell: (row: ICourseEntity) => (<span className="blockquote small m-0 p-0" title={'#' + row?.id.toString()}><b>#</b><small><u>{row.id}</u></small></span>),
      grow: 1,
      omit: true,
    },
    {
      name: 'Codice',
      selector: (row: ICourseEntity) => row.codice,
      grow: 2,
    },
    {
      name: 'Titolo',
      selector: (row: ICourseEntity) =>row.titolo,
      wrap: true,
      grow: 2,
    },
    {
      name: 'Tipologia',
      selector: (row: ICourseEntity) =>row.idTipologia,
      grow: 1,
    },
    {
      name: 'Area',
      selector: (row: ICourseEntity) =>row.idAreaFormativa,
      grow: 1,
    },
    {
      name: 'Note',
      selector: (row: ICourseEntity) =>row.descrizione,
      wrap: true,
      grow: 2,
    },
    {
      name: 'Erogazione',
      selector: (row: ICourseEntity) => row.idErogazione,
      grow: 2,
    },
    {
      name: 'Data inizio',
      selector: (row: ICourseEntity) => (row.dataInizio ? format(new Date(row.dataInizio), 'dd/MM/yyyy') : ""),
      grow: 2,
    },
    {
      name: 'Data fine',
      selector: (row: ICourseEntity) => (row.dataFine ? format(new Date(row.dataFine), 'dd/MM/yyyy') : ""),
      grow: 2,
    },
    {
      name: 'CFP',
      selector: (row: ICourseEntity) =>  row.cpfAssegnati,
      grow: 1,
    },
    {
      name: 'Stato',
      selector: (row: ICourseEntity) =>  row.idStatoPubblicazione,
      grow: 1,
    },
  ] as TableColumn<ICourseEntity>[];

  return (
    <Fragment>
      <Container fluid as="section">
        <Row className="mb-3">
          {(props.validati? props.validati.length > 0 : false) || (props.scarti ? props.scarti.length > 0 : false) || (props.duplicati ? props.duplicati.length > 0 : false)
           ? ( <Fragment>
                  <p>corsi validati: {props.validati?.length || 0}</p>
                  <p>corsi scartati: {props.scarti?.length || 0}</p>
                  <p>corsi duplicati: {props.duplicati?.length || 0}</p>
                </Fragment>
              ) : 
                (<Fragment>
                  <p>Non è stato possibile leggere il file da importare, controllare il formato</p>
                </Fragment>)}
        </Row>
        <Row className="text-center text-warning">
          <h3>Lista corsi scartati:</h3>
        </Row>
        <Row className="mb-3">
          <Col>
            <DataTable columns={columns} data={coursesList} pagination={false}
              responsive striped dense progressPending={isLoading} 
              noDataComponent="Non ci sono scarti da visualizzare"
            />
          </Col>
        </Row>
      </Container>

    </Fragment>
  );
};

export default CourseListImportResults;