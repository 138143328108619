import { FC, Fragment, useState, useRef } from 'react';
import { Alert, Button, Card, Container, Form, Modal, Spinner } from 'react-bootstrap';
import { CheckCircleFill, XCircleFill } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import PageParagraph from '../../components/PageParagraph';
import PageSubtitle from '../../components/PageSubtitle';
import PageTitle from '../../components/PageTitle';
import { IPostProfessionalCertificateImportFulfilledPayload, IPostProfessionalCertificateImportThunkPayload, postProfessionalCertificateImport } from '../../redux/slices/professionals.slice';
import { AppDispatch } from '../../redux/store';
import CertificationListImportResults from '../../components/admin/CertificationListImportResults';
import { ICertificationEntity } from '../../entities/certification.entity';
import { b64toBlob } from '../../utils/attachment-parser';

interface IForm extends IPostProfessionalCertificateImportThunkPayload { }

const AdminImportProfessional: FC = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();

    const [fields, setFields] = useState({
        fileName: '',
        contentType: '',
        contentRaw: '',
    } as IForm);

    const [isLoading, setLoading] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [showResultsModal, setShowResultsModal] = useState(false);
    const [scarti, setScarti] = useState<Array<ICertificationEntity>>(new Array);
    const [validati, setValidati] = useState<Array<ICertificationEntity>>(new Array);

    const fileInputRef = useRef<HTMLInputElement | null>(null);

    const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const target = event.target as HTMLInputElement;
        const files = event.target.files as FileList;

        if (files.length) {
            const file_name = files[0].name;
            const file_type = files[0].type;

            const fileReader = new FileReader();
            fileReader.onload = (e: ProgressEvent<FileReader>) => {
                if (e.target?.result) {
                    const res = e.target.result;
                    setFields({
                        fileName: file_name,
                        contentType: file_type,
                        contentRaw: res,
                    });
                }
            };

            fileReader.readAsDataURL(files[0]);
        }
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        //setHasError(false);

        console.log("fields: ", fields);

        setLoading(true);

        dispatch(postProfessionalCertificateImport(fields as IPostProfessionalCertificateImportThunkPayload))
            .unwrap()
            .then((value) => {
                const fulfilledPayload = value as unknown as IPostProfessionalCertificateImportFulfilledPayload;
                console.log("Response:", fulfilledPayload);

                // Accedi ai dati dal payload della risposta
                const { importStatus } = fulfilledPayload;
                console.log("Scarti:", importStatus.scarti);
                console.log("Validi:", importStatus.validati);
                console.log("rendiconto:", importStatus.rendiconto);

                if (importStatus.rendiconto.contentRaw != null && importStatus.rendiconto.contentRaw.length > 0) {
                    const div = document.getElementById("divDownloadRendicontoImportazione");
                    if (div) {
                        b64toBlob(importStatus.rendiconto.contentRaw, importStatus.rendiconto.contentType).then((blobresult: Blob) => {
                            const url = window.URL.createObjectURL(blobresult);
                            const link = document.createElement('a');
                            link.href = url;
                            link.setAttribute('download', importStatus.rendiconto.filename);
                            div.appendChild(link);
                            link.click();
                            link.remove();
                        });
                    }
                }                

                setScarti(importStatus.scarti);
                setValidati(importStatus.validati);
                
                setFields({ fileName: '', contentType: '', contentRaw: '' });

                if (fileInputRef.current) {
                    fileInputRef.current.value = "";
                }

                // Mostra il modal dei risultati
                setShowResultsModal(true);
            })
            .catch((err) => {
                setSubmitted(true);})
            .finally(() => {
                setLoading(false); 
            });
    };

    const handleCloseReslutsModal = () => setShowResultsModal(false);

    return (
        <Fragment>
            <PageTitle content="Import" />
            <PageSubtitle content="Importa lista certificazioni/esoneri" />
            <PageParagraph content="" />
            <Container fluid as="section">
                <Card className="mt-3">
                    <Card.Body>
                        {submitted ? (
                            <div className="text-center">
                                <XCircleFill size={100} className="text-warning m-5" />
                                <h3 className="text-warning">Attenzione, qualcosa è andato storto.</h3>
                            </div>
                        ) : (
                            <Form noValidate onSubmit={handleSubmit} >
                                <Form.Group controlId="formFile" className="mb-3">
                                    <Form.Label>
                                        {/* <p><b>Si raccomanda di NON modificare il file ottenuto dalla piattaforma di esportazione</b></p> */}
                                        <p><a href="/assets/documents/import-certificazioni-esoneri-esempio.csv" download={`import-certificazioni-esoneri-esempio_${new Date().getTime()}.csv`}>Clicca QUI per scaricare il file CSV di esempio</a></p>
                                        <p>State per caricare i dati delle Certificazioni e degli Esoneri </p>
                                        <p>Eventuali scarti saranno visualizzati e scaricati come file excel</p>
                                        {/* <p>la sezione per la visualizzazione dello stato formativo è in
                                            costruzione e i dati saranno visibili a partire dal 26 Aprile 2022.</p> */}
                                        {/* <p><b>Le funzioni di importazione sono disabilitate. Ne stiamo effettuando un nuovo collaudo.</b></p> */}
                                        <br /><br />File da importare
                                    </Form.Label>
                                    <Form.Control type="file" name="file" onChange={handleFileUpload} ref={fileInputRef} />
                                </Form.Group>
                                <Button type="submit" className="w-25 float-right" disabled={isLoading}>
                                    {isLoading ? (
                                        <Fragment>
                                            <Spinner as="span" animation="border" size="sm" role="status" />
                                            <span className="ms-1">Attendere</span>
                                            <span>...</span>
                                        </Fragment>
                                    ) : (
                                        <span>Salva</span>
                                    )}
                                </Button>
                                {hasError ? (
                                    <Fragment>
                                        <Alert variant="danger">
                                            <Alert.Heading>Si è verificato un errore!</Alert.Heading>
                                            <p>Il file è stato salvato correttamente nei nostri sistemi. La lettura del file non ha avuto successo!</p>
                                        </Alert>
                                    </Fragment>
                                ) : (
                                    <Fragment />
                                )}
                            </Form>
                        )}
                    </Card.Body>
                </Card>
            </Container>

            <Modal show={showResultsModal} onHide={handleCloseReslutsModal} centered size="xl">
                <Modal.Header closeButton>
                    <Modal.Title><h3>Risultato operazione</h3></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CertificationListImportResults validati={validati} scarti={scarti}/>
                </Modal.Body>
            </Modal>
            <div id="divDownloadRendicontoImportazione" className="d-none"></div>
        </Fragment>
    );
};

export default AdminImportProfessional;
