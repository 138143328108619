import { Formik } from 'formik';
import { FC, Fragment, useEffect, useState } from 'react';
import { Button, Col, Form, Modal, Row, Spinner } from 'react-bootstrap';
import { CheckCircleFill } from 'react-bootstrap-icons';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { IRejectResponse } from '../../api/api.interface';
import { ICertificationEntity } from '../../entities/certification.entity';
import { ICompanyEntity } from '../../entities/company.entity';
import { IMetadataOption, IMetadataOptionNode } from '../../entities/metadata.entity';
import { IPutCertificationThunkPayload, putCertification } from '../../redux/slices/certification.slice';
import { AppDispatch, RootState } from '../../redux/store';
import { parseISOStringToStandardDate } from '../../utils/course-parser';
import { getNome } from './dropdown/certification/OggettoTipologia';
import { getOrdineProvinciale } from './dropdown/OrdineProvinciale';
import ShowDetailAttachment from './ShowDetailAttachment';
import AddAttachForm from './AddAttachForm';

interface IProps {
  exception: ICertificationEntity;
  submitted: boolean;
  nonstandard?: boolean;
  onSubmitSuccess?: () => void;
}

const ExceptionEdit: FC<IProps> = (props) => {
  const dispatch = useDispatch<AppDispatch>();
  const [submitted, setSubmitted] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState<IRejectResponse>();
  const companiesOrders: Array<ICompanyEntity> = useSelector((s: RootState) => s.companies.orders);
  const oggettiCertificazione: Array<IMetadataOptionNode> = useSelector((s: RootState) => s.metadata.certification.oggetto_certificazione);
  
  // CONTROLLARE se serve active = 0 oppure active = 1
  // const accrediti: Array<IMetadataOptionNode> = useSelector((s: RootState) => s.metadata.certification.tipo_accredito_certificazione);
  const accreditiStandard: Array<IMetadataOptionNode> = useSelector((s: RootState) => s.metadata.certification.tipo_accredito_certificazione); 
  const accreditiDaDelibera: Array<IMetadataOptionNode> = useSelector((s: RootState) => s.metadata.certification.tipo_accredito_certificazione_non_standard);
  const [accrediti, setAccrediti] = useState<Array<IMetadataOptionNode>>(new Array<IMetadataOptionNode>());

  const esoneri: Array<IMetadataOptionNode> = useSelector((s: RootState) => s.metadata.certification.tipo_esonero_certificazione);
  const esoneriAll: Array<IMetadataOptionNode> = useSelector((s: RootState) => s.metadata.certification.tipo_esonero_certificazione_all);
  const staticertificazione: Array<IMetadataOption> = useSelector((s: RootState) => s.metadata.certification.stato_certificazione);

  const [idTipologia, setIdTipologia] = useState<number>(0);
  const [nomeTipologia, setNomeTipologia] = useState<string>("");

  const [showAddAttachModal, setShowAddAttachModal] = useState<boolean>(false);
  const handleOpenAddAttachModal = () => setShowAddAttachModal(true);
  const handleCloseAddAttachModal = () => {
    setShowAddAttachModal(false);  
    if (props?.onSubmitSuccess) {
        props.onSubmitSuccess();
    };  
  };

  const [allegatiAggiornati, setAllegatiAggiornati] = useState(props.exception.allegati ? props.exception.allegati : []);

  const [initialValues, setInitialValues] = useState<ICertificationEntity>({ 
      ...props.exception, 
      noteRichiedente: props.exception.noteRichiedente ? props.exception.noteRichiedente : "",
      titolo : props.exception.titolo ? props.exception.titolo : "", // aggiunto per organizzatore
      organizzatore : props.exception.organizzatore ? props.exception.organizzatore : "", // aggiunto per organizzatore
      codiceCorso: props.exception.codiceCorso ? props.exception.codiceCorso : "", // aggiunto p
      dataRiferimentoDa: parseISOStringToStandardDate(props.exception.dataRiferimentoDa), 
      dataRiferimentoA: parseISOStringToStandardDate(props.exception.dataRiferimentoA) });

  useEffect(() => {
        if (accreditiStandard.length && accreditiDaDelibera.length) {
          setAccrediti([...accreditiStandard, ...accreditiDaDelibera]);
        }
      }, [accreditiStandard, accreditiDaDelibera]);

  useEffect(() => {
    if ((props.exception.idOggetto === 1 || props.exception.idOggetto === 5)) {
      if (accrediti.length) {
        setIdTipologia(props.exception.tipoAccreditoCfpId);
        setNomeTipologia(getNome(props.exception.tipoAccreditoCfpId, accrediti));
      }
    } else {
      if (esoneriAll.length) {
        // if (esoneri.length) {
        setIdTipologia(props.exception.tipoEsoneroId);
        setNomeTipologia(getNome(props.exception.tipoEsoneroId, esoneriAll));
        // setNomeTipologia(getNome(props.certification.tipoEsoneroId, esoneri));
      }
    }
  }, [accrediti, esoneriAll]);

  const handleSubmit = (value: ICertificationEntity) => {
    dispatch(putCertification({ certification: value } as IPutCertificationThunkPayload))
      .unwrap()
      .then(() => setSubmitted(true))
      .catch((e) => setError(e))
      .finally(() => setLoading(false));  
  };

  let schema = yup.object().shape({
    idStatoCertificazione: yup.number(),
    titolo: yup.string().max(500, ({ max }) => `Raggiunta lunghezza massima consentita di ${max} caratteri`).nullable(),
    organizzatore: yup.string().max(250, ({ max }) => `Raggiunta lunghezza massima consentita di ${max} caratteri`).nullable(),
    codiceCorso: yup.string().max(50, ({ max }) => `Raggiunta lunghezza massima consentita di ${max} caratteri`).nullable(),
    noteRichiedente: yup.string().max(300, ({ max }) => `Raggiunta lunghezza massima consentita di ${max} caratteri`).nullable().when("idStatoCertificazione", {
      is: 3,
      then: (schema) => schema.required("Campo obbligatorio"),
    }),
    cfpAssegnati: yup.number().required("Campo obbligatorio"),
    dataRiferimentoDa: yup.string().required("Campo obbligatorio"),
    // aggiungere controllo su coerenza date (dataRiferimentoA > dataRiferimentoDa)
    dataRiferimentoA: yup.string().nullable(),    
  });

  return (
    <Fragment>
      {submitted ? (
        <div className="text-center">
          <CheckCircleFill size={100} className="text-success mb-5" />
          <h3 className="text-success">Ben fatto!</h3>
          <p className="mt-3">Operazione completata.</p>
        </div>
      ) : (
        <Formik initialValues={initialValues} validationSchema={schema} onSubmit={handleSubmit}>
          {({ handleSubmit, handleChange, values, touched, errors }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Row className="mb-3">
                <Col md={6} sm={12}>
                  <h6>Professionista</h6>
                  <span>{props.exception.cognome} {props.exception.nome} ({props.exception.codiceFiscale})</span>
                </Col>
                <Col md={6} sm={12}>
                  <h6>Stato richiesta</h6>
                  {!props.nonstandard ? (
                    <Form.Control as="select" name="idStatoCertificazione" onChange={handleChange} value={values.idStatoCertificazione} >
                      {
                        (staticertificazione.length > 0) ? (
                          staticertificazione.map((item: IMetadataOption) => {
                            return (
                              <option key={"selectStatoCertificazione" + item.id} value={item.id}>
                                {item.nome}
                              </option>
                            );
                          })
                        ) : (
                          <Fragment />
                        )
                      }
                    </Form.Control>
                  ) : (
                    <Form.Control
                      as="input"
                      name="idStatoCertificazione"
                      readOnly={props.nonstandard}
                      value={staticertificazione.find((item: IMetadataOption) => item.id === values.idStatoCertificazione)?.nome}
                    />
                  )}
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={6} sm={12}>
                  <h6>Oggetto</h6>
                  <span>{props.exception.idOggetto}. {getNome(props.exception.idOggetto, oggettiCertificazione)}</span>
                </Col>
                <Col md={6} sm={12}>
                  <h6>Data di riferimento</h6>
                  <Form.Control
                    type="date"
                    onChange={handleChange}
                    name="dataRiferimentoDa"
                    value={values.dataRiferimentoDa}
                    placeholder="Inizio"
                    readOnly={props.nonstandard}
                    isInvalid={!!touched.dataRiferimentoDa && !!errors.dataRiferimentoDa}
                  />
                  <Form.Control.Feedback type="invalid">{errors.dataRiferimentoDa}</Form.Control.Feedback>
                </Col>
                {/* <Col md={3} sm={12}>
                  <h6>&nbsp;</h6>
                  <Form.Control
                    type="date"
                    onChange={handleChange}
                    name="dataRiferimentoA"
                    value={values.dataRiferimentoA}
                    placeholder="Fine"
                    readOnly={props.nonstandard}
                    isInvalid={!!touched.dataRiferimentoA && !!errors.dataRiferimentoA}
                  />
                  <Form.Control.Feedback type="invalid">{errors.dataRiferimentoA}</Form.Control.Feedback>
                </Col> */}
              </Row>
              <Row className="mb-3">
                <Col md={6} sm={12}>
                  <h6>Tipologia</h6>
                  <span className="small">
                    {idTipologia}. {nomeTipologia}
                  </span>
                </Col>
                {/* <Col md={3} sm={12}>
                  <h6>CFP dichiarati</h6>
                  <p>{props.exception.cfpDichiarati}</p>
                </Col> */}
                <Col md={6} sm={12}>
                  <h6>CFP equivalenti</h6>
                  <Form.Control
                    as="input"
                    type="number"
                    min={0}
                    max={20}
                    onChange={handleChange}
                    name="cfpAssegnati"
                    value={values.cfpAssegnati}
                    isInvalid={!!touched.cfpAssegnati && !!errors.cfpAssegnati}
                    readOnly={(values.idOggetto === 2 && values.idTipologia === 0) 
                      || (values.idOggetto === 3 && values.idTipologia === 6) 
                      || (values.idOggetto === 4 && values.idTipologia === 0) 
                      || (values.idOggetto === 4 && values.idTipologia === 1) 
                      || props.nonstandard
                    }
                  />
                  <Form.Control.Feedback type="invalid">{errors.cfpAssegnati}</Form.Control.Feedback>
                </Col>
              </Row>
              {
                (props.exception.idOggetto === 1) ? (
                  <Row className="mb-3">
                    <Col md={6} sm={12}>
                      <h6>Organizzatore / Titolo / Codice</h6>
                      <span className="small">
                        {(props.exception.organizzatore != null && props.exception.organizzatore.length > 0) ? props.exception.organizzatore : "--"} &nbsp; / &nbsp;
                        {(props.exception.titolo != null && props.exception.titolo.length > 0) ? props.exception.titolo : "--"} &nbsp; / &nbsp;
                        {(props.exception.codiceCorso != null && props.exception.codiceCorso.length > 0) ? props.exception.codiceCorso : "--"}
                      </span>
                    </Col>
                    <Col md={3} sm={6}>
                      <h6>Ordine</h6>
                      <span className="small">{getOrdineProvinciale(props.exception.idAzienda, companiesOrders)}</span>
                    </Col>
                    {props.nonstandard ? (
                      <Col md={3} sm={6}>
                          <h6>Delibera</h6>
                          <span className="small">{props.exception.delibera ? props.exception.delibera : ""}</span>
                      </Col>) : (<Fragment />)}                    
                  </Row>
                ) : (<Fragment />)
              }
              <Row className="mb-3">
                <Col md={6} sm={12}>
                  {
                    (!props.nonstandard && allegatiAggiornati != null && allegatiAggiornati.length > 0) ? (
                      <ShowDetailAttachment stato={props.exception.idStatoCertificazione} 
                      allegatiAggiornati={allegatiAggiornati} setAllegatiAggiornati={setAllegatiAggiornati} />
                    ) : ("Nessun allegato")
                  }
                </Col>
                <Col md={6} sm={12}>
                  <h6>Note!</h6>
                  <Form.Control
                    as="textarea"
                    onChange={handleChange}
                    name="noteRichiedente"
                    value={values.noteRichiedente}
                    isInvalid={!!touched.noteRichiedente && !!errors.noteRichiedente}
                  />
                  <Form.Control.Feedback type="invalid">{errors.noteRichiedente}</Form.Control.Feedback>
                </Col>
              </Row>
              <Row style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                <Col style={{textAlign: 'center'}}>
                  {!props.nonstandard && (
                    <Button onClick={handleOpenAddAttachModal} disabled={isLoading || (allegatiAggiornati != null && allegatiAggiornati.length > 3)}>
                      {isLoading ? (
                        <Fragment>
                          <Spinner as="span" animation="border" size="sm" role="status" />
                          <span className="ms-1">Aggiungi Allegati</span>
                          <span>...</span>
                        </Fragment>
                      ) : (
                        <span>Aggiungi Allegati</span>
                      )}
                    </Button>
                  )}
                </Col>
                <Col style={{textAlign: 'right'}}>
                  <Button type="submit" disabled={isLoading}>
                    {isLoading ? (
                      <Fragment>
                        <Spinner as="span" animation="border" size="sm" role="status" />
                        <span className="ms-1">Attendi</span>
                        <span>...</span>
                      </Fragment>
                    ) : (
                      <span>Salva modifiche</span>
                    )}
                  </Button>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      )}

      <Modal centered show={showAddAttachModal} onHide={handleCloseAddAttachModal} >
        <Modal.Header closeButton>
            <Modal.Title><p className="display-6 m-0 p-0">Aggiungi allegato</p></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {props.exception ? (
            <AddAttachForm idCertification={props.exception.id} allegati={allegatiAggiornati} onTestChange={setAllegatiAggiornati}/>
          ) : (
            <Fragment />
          )}
        </Modal.Body>
      </Modal>

    </Fragment>
  );
};

export default ExceptionEdit;