import { Formik, FormikProps } from 'formik';
import React, { FC, Fragment, useEffect, useRef, useState } from 'react';
import { Alert, Button, Col, Form, Modal, Row, Spinner } from 'react-bootstrap';
import { Asterisk, CheckCircleFill } from 'react-bootstrap-icons';
import { FaInfoCircle } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { IAttachment } from '../../entities/attachment.entity';
import { IMetadataOption, IMetadataOptionNode } from '../../entities/metadata.entity';
import { IPostCertificationThunkPayload, postCertification } from '../../redux/slices/certification.slice';
import { getMetadataCertification, getMetadataCourse } from '../../redux/slices/metadata.slice';
import { AppDispatch, RootState } from '../../redux/store';
import { parseISOStringToStandardDate } from '../../utils/course-parser';
import { getLineeGuida } from '../../redux/slices/lineeguida.slice';
import { ILineaGuidaEntity } from '../../entities/lineeguida.entity';

interface IFormValue extends IPostCertificationThunkPayload {
    // responsabilita: boolean;
    triennio: number;
    dataRiferimento: Date | null;
    dataInserimento: Date | null;
    delibera: string;
};

interface IProps {
    codiceFiscale: string;
    defaultInitValue?: IPostCertificationThunkPayload;
    onSubmitSuccess?: () => void;
}

const CertificationNonStandardForm: FC<IProps> = (props) => {
    const dispatch = useDispatch<AppDispatch>();

    const tipoAccreditoCertificazioneNonStandard: Array<IMetadataOptionNode> = useSelector((s: RootState) => s.metadata.certification.tipo_accredito_certificazione_non_standard);
    const [tipologiaCertificazioneLG, setTipologiaCertificazioneLG] = useState<Array<IMetadataOptionNode>>([]);
    const trienni: Array<IMetadataOptionNode> = useSelector((s: RootState) => s.metadata.course.trienni);
    const [trienniSelezionabili, setTrienniSelezionabili] = useState<Array<IMetadataOptionNode>>([]);
    const [isOrdinario, setIsOrdinario] = useState<boolean>(false);
    const [lineaGuida, setlineaGuida] = useState<number>(0);

    // const lineeGuida: Array<ILineaGuidaEntity> = useSelector((s: RootState) => s.lineeGuida.lineeGuida);

    // const [attachNotRequired, setAttachNotRequired] = useState<boolean>(true);

    const initialValues: IFormValue = {
        codiceFiscale: props.codiceFiscale,
        idOggetto: 1,
        idTipologia: 0,
        annoRiferimento: new Date().getFullYear(),
        dataRiferimentoDa: "",
        dataRiferimentoA: "",
        // dataRiferimentoDa: parseISOStringToStandardDate(new Date((new Date()).getFullYear(), 0, 1).toISOString()),
        // dataRiferimentoA: parseISOStringToStandardDate(new Date((new Date()).getFullYear(), 11, 31).toISOString()),
        dataInserimento: null,
        dataRiferimento: null,
        delibera: '',
        titolo: '',
        organizzatore: '',
        codiceCorso: '',
        cfpDichiarati: 0,
        cfpAssegnati: 0,
        noteRichiedente: '',
        idStatoCertificazione: 4,    // verificare se lo stato è corretto
        // responsabilita: true,
        triennio: trienniSelezionabili?.length ? trienniSelezionabili.length : 0,
        allegati: new Array<IAttachment>(),
    };

    const formikRef = useRef<FormikProps<any>>(null);

    const today = new Date();
    useEffect(() => {
        // il campo data in questo caso rappresenta la data di fine ravvedimento operoso
        if (trienni && trienni.length > 0) {
            setTrienniSelezionabili(trienni.filter((item) => new Date(item.data) < today));
        }
    }, [trienni]);
    
    useEffect(() => {
        if (trienniSelezionabili && trienniSelezionabili.length > 0 && tipoAccreditoCertificazioneNonStandard && tipoAccreditoCertificazioneNonStandard.length > 0) {
            const ultimoTriennio = trienniSelezionabili[trienniSelezionabili.length - 1];
            setTipologiaCertificazioneLG(tipoAccreditoCertificazioneNonStandard.filter(item => item.lg === ultimoTriennio.id));
            setlineaGuida(ultimoTriennio.id);
            // console.log("trienni selezionabili: ", trienniSelezionabili);
            // Imposta il valore di triennio sull'ultimo elemento dell'array
            formikRef.current?.setFieldValue('triennio', ultimoTriennio.id);
            formikRef.current?.setFieldValue('dataRiferimento', ultimoTriennio.massimo + "-12-31");
            formikRef.current?.setFieldValue('dataRiferimentoA', ultimoTriennio.massimo + "-12-31");
            formikRef.current?.setFieldValue('dataRiferimentoDa', ultimoTriennio.massimo + "-12-31");

            // parseISOStringToStandardDate(new Date((new Date()).getFullYear(), 0, 1).toISOString())
        }
    }, [trienniSelezionabili, tipoAccreditoCertificazioneNonStandard]);

    useEffect(() => {  
        formikRef.current?.setFieldValue('idTipologia', tipologiaCertificazioneLG[0]?.id);
        setIsOrdinario(tipologiaCertificazioneLG[0]?.cfp_o === 1);
    }, [tipologiaCertificazioneLG]);

    const handleTriennioChange = (e: React.ChangeEvent<HTMLInputElement>,  
        setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void,
        setFieldTouched: (field: string, isTouched?: boolean, shouldValidate?: boolean) => void,
        handleChange: (e: React.ChangeEvent<any>) => void) => {    
            // console.log("handleTriennioChange custom, ", e.target.name, ": ", e.target.value); 
            const { name, value } = e.target;
            setFieldValue(name, value); // Aggiorna il valore del campo con Formik
            setFieldTouched(name, true, true); // Segna il campo come toccato e attiva la validazione
            setFieldValue("cfpDichiarati", 0);
            setFieldTouched("cfpDichiarati", false, true);
            handleChange(e); // Gestisce anche gli eventi di cambiamento Formik

            const triennio = trienniSelezionabili?.at(Number(value) - 1);
            // console.log("triennio selezionato: ", triennio);
            setTipologiaCertificazioneLG(tipoAccreditoCertificazioneNonStandard.filter(item => item.lg === triennio?.id));
            setlineaGuida(triennio?.id || 0);
            const dataMassimo = triennio ? triennio.massimo : 0;
            // console.log("data nel triennio: ", dataMassimo);
            setFieldValue('dataRiferimento', dataMassimo + "-12-31");
            formikRef.current?.setFieldValue('dataRiferimentoA', dataMassimo + "-12-31");
            formikRef.current?.setFieldValue('dataRiferimentoDa', dataMassimo + "-12-31");
    }

    const handleTipologiaChange = (e: React.ChangeEvent<HTMLInputElement>,
        setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void,
        setFieldTouched: (field: string, isTouched?: boolean, shouldValidate?: boolean) => void,
        handleChange: (e: React.ChangeEvent<any>) => void) => {
            const { name, value } = e.target;
            setFieldValue(name, value); // Aggiorna il valore del campo con Formik
            setFieldTouched(name, true, true); // Segna il campo come toccato e attiva la validazione
            setFieldValue("cfpDichiarati", 0);
            setFieldTouched("cfpDichiarati", false, true);
            handleChange(e); // Gestisce anche gli eventi di cambiamento Formik
            setFieldValue("titolo", tipologiaCertificazioneLG.find((item) => item.id === Number(value))?.nome || "");
            // console.log("titolo: ", tipologiaCertificazioneLG.find((item) => item.id === Number(value))?.nome || "nessun titolo");
            // console.log("value: ", value);
            // console.log("cfp_o: ", tipologiaCertificazioneLG);
            setIsOrdinario((tipologiaCertificazioneLG.find((item) => item.id === Number(value))?.cfp_o === 1) ? true : false);
    }
    
    const handleGenericChange = (e: React.ChangeEvent<HTMLInputElement>,  
        setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void,
        setFieldTouched: (field: string, isTouched?: boolean, shouldValidate?: boolean) => void,
        handleChange: (e: React.ChangeEvent<any>) => void) => {    
            // console.log("handleGenericChange custom:", e.target.value); 
            const { name, value } = e.target;
            setFieldValue(name, value); // Aggiorna il valore del campo con Formik
            setFieldTouched(name, true, true); // Segna il campo come toccato e attiva la validazione
            handleChange(e); // Gestisce anche gli eventi di cambiamento Formik
            setIsDisable(false);
    }

    // Function to build the dynamic validation schema for `cfpDichiarati`
        const getCfpValidationSchema = (lineaGuida: number, idTipologia: number) => {
            // console.log("linea guida:", lineaGuida);
            // console.log("id tipologia:", idTipologia);
            return yup.number().required("Campo obbligatorio")
            .when(['lineaGuida', 'idTipologia'], {
                is: (lineaG: number, id: number) => {
                // console.log("linea guida:", lineaGuida, " id:", id);
                // console.log(tipologiaCertificazioneLG.some( (tip) => tip.id === Number(id) && tip.lg === lineaGuida));
                // console.log("tip.id:", tipologiaCertificazioneLG.map((tip) => typeof tip.id), "id:", typeof id);
                // console.log("tip.lg:", tipologiaCertificazioneLG.map((tip) => typeof tip.lg), "lineaGuida:", typeof lineaGuida);
                return tipologiaCertificazioneLG.some(
                    (tip) => tip.id === Number(id) && tip.lg === lineaGuida
                );
                },
                then: schema => {
                    // console.log("tipologiaCertificazioneLG:", tipologiaCertificazioneLG);
                    const rule = tipologiaCertificazioneLG.find(
                        (tip) => tip.id === Number(idTipologia) && tip.lg === lineaGuida
                    );
                    // console.log("rule:", rule);
                    // console.log("massimo: ", rule?.massimo);
                    // controllare lg_tipo_accreditocfp   -> invertiti massimo e minimo
                    return rule ? schema.min(rule.minimo, ({ min }) => `Errore di validazione! Valore minimo consentito ${min}`)
                    .test('max-rule', 'Errore di validazione! Valore massimo consentito ${}', function(value) {
                        // console.log("value:", value);
                        if (rule.massimo === 0) {
                            return true;
                        }
                        return (value == null || value <= rule.massimo) || this.createError({ message: `Errore di validazione! Valore massimo consentito ${rule.massimo}` });
                    }) : schema;
                    // .max(rule.massimo, ({ max }) => `Errore di validazione! Valore massimo consentito ${max}`) : schema;
                },
    
                
                otherwise: yup.number().max(0, () => `Errore di validazione! Impossibile leggere i valori minimi e massimi`),
            });
        };

    const schema = yup.object().shape({
        triennio: yup.number().required("Campo obbligatorio"),
        cfpDichiarati: yup.lazy((value, {parent}) => getCfpValidationSchema(lineaGuida, parent.idTipologia)),
        noteRichiedente: yup.string().max(300, ({ max }) => `Raggiunta lunghezza massima consentita di ${max} caratteri`).nullable(),
    });

    const [isLoading, setLoading] = useState(false);
    const [show, setShow] = useState(false);
    const [error, setError] = useState(false);
    const [isDisable, setIsDisable] = useState(true);
    const [submittedConfirmed, setSubmittedConfirmed] = useState(false);

    const handleClose = () => {
        setShow(false);
        setSubmittedConfirmed(true);
        if (props?.onSubmitSuccess) {
            props.onSubmitSuccess();
        };
    };

    useEffect(() => {
        dispatch(getMetadataCertification({}));
        dispatch(getMetadataCourse({}));
        dispatch(getLineeGuida({}));
    }, [dispatch]);

    const handleSubmit = (value: IFormValue) => {
        const val: IFormValue = { ...value, cfpAssegnati: value.cfpDichiarati };
        console.log(val);
        setLoading(true);
        setError(false);
        dispatch(postCertification(val))
            .unwrap()
            .then(() => {
                setShow(true);
            })
            .catch(() => {
                setError(true);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <Fragment>
            <div className={`${submittedConfirmed ? 'd-none' : ''}`}>
                <Formik
                    innerRef={formikRef}
                    initialValues={initialValues}
                    validationSchema={schema}
                    onSubmit={handleSubmit}
                >
                    {({ handleSubmit, handleChange, values, errors, touched, setFieldValue, setFieldTouched }) => (
                        <div className="mx-3">
                            <Form onSubmit={handleSubmit}>

                                <Form.Group as={Row} className="my-3" >
                                    <Form.Label as={Col} lg={3} md={4} sm={12}>N° delibera consiglio</Form.Label>
                                    <Col lg={9} md={9} sm={12}>
                                        <Form.Control
                                            type="text"
                                            name="delibera"
                                            disabled={isLoading || submittedConfirmed}
                                            value={values.delibera}
                                            onChange={handleChange}
                                            isInvalid={!!touched.delibera && !!errors.delibera}
                                        />
                                        <Form.Control.Feedback type="invalid">{errors.titolo}</Form.Control.Feedback>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} className="my-3" >
                                    <Form.Label as={Col} lg={3} md={4} sm={12}>Triennio<Asterisk size={8} className="mb-3" /></Form.Label>
                                    <Col lg={9} md={9} sm={12}>
                                        <Form.Control
                                            as="select"
                                            name="triennio"
                                            value={values.triennio}
                                            // verificare se + l'handle giusto
                                            onChange={(e) => handleTriennioChange(e as React.ChangeEvent<HTMLInputElement>, setFieldValue, setFieldTouched, handleChange)}
                                            disabled={isLoading || submittedConfirmed}
                                            // isInvalid={!!touched.triennio && !!errors.triennio}
                                            // onInput={(e: React.ChangeEvent<HTMLInputElement>) => { values.idTipologia=0; setTest(Number(e.target.value)); values.cfpDichiarati=0}}
                                        >
                                            {trienniSelezionabili.map((item: IMetadataOptionNode, index: number) => {
                                                return (<option key={"idTriennio" + item.id + index} value={item.id}>{item.nome}</option>);
                                            })}
                                        </Form.Control>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} className="my-3" >
                                    <Form.Label as={Col} lg={3} md={4} sm={12}>Tipologia<Asterisk size={8} className="mb-3" /></Form.Label>
                                    <Col lg={9} md={9} sm={12}>
                                        <Form.Control
                                            as="select"
                                            name="idTipologia"
                                            value={values.idTipologia}
                                            onChange={(e) => handleTipologiaChange(e as React.ChangeEvent<HTMLInputElement>, setFieldValue, setFieldTouched, handleChange)}
                                            disabled={isLoading || submittedConfirmed}
                                            // isInvalid={!!touched.idTipologia && !!errors.idTipologia}
                                            >

                                            <option key={"idTipologia0"} value={0}></option>
                                            {tipologiaCertificazioneLG.length && (
                                                tipologiaCertificazioneLG.map((item: IMetadataOption, index: number) => {
                                                    return (<option key={"idTipologia" + item.id} value={item.id}>{item.nome}</option>);
                                                })
                                            )}
                                        </Form.Control>
                                        {/* <Form.Control.Feedback type="invalid">{errors.idTipologia}</Form.Control.Feedback> */}
                                    </Col>
                                </Form.Group>
                                
                                <Form.Group as={Row} className="my-3" >
                                    <Form.Label as={Col} lg={3} md={4} sm={12}>Data riferimento</Form.Label>
                                    <Col lg={9} md={9} sm={12}>
                                        <Form.Control type="date" 
                                            onChange={handleChange}
                                        // onChange={(e) => handleDateChange(e as React.ChangeEvent<HTMLInputElement>, setFieldValue, setFieldTouched, handleChange)} // Usa la funzione custom
                                            name="dataRiferimento" value={values?.dataRiferimento?.toString() || ""}
                                            isInvalid={!!touched.dataRiferimento && !!errors.dataRiferimento}
                                            disabled={true} />
                                        <Form.Control.Feedback type="invalid">{errors.dataRiferimento}</Form.Control.Feedback>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} className="my-3" >
                                    <Form.Label as={Col} lg={3} md={4} sm={12}>CFP {isOrdinario? "ORDINARI" : "DEONTOLOGICI"} dichiarati<Asterisk size={8} className="mb-3" />
                                        <a href="/assets/tutorial/CNAPPC_linee-guida-formazione_2024_ALL2.pdf" target="_blank" rel="noreferrer">
                                            <FaInfoCircle className="text-dark" title="Vedi la tabella di assegnazione cfp" size={22} />
                                        </a>
                                    </Form.Label>
                                    <Col lg={9} md={9} sm={12}>
                                        <Form.Control
                                            // min={0}
                                            type="number"
                                            name="cfpDichiarati"
                                            disabled={isLoading || submittedConfirmed || !values.delibera }
                                            value={values.cfpDichiarati}
                                            // onChange={handleChange}
                                            onChange={(e) => {handleGenericChange(e as React.ChangeEvent<HTMLInputElement>, setFieldValue, setFieldTouched, handleChange)}}
                                            isInvalid={!!touched.cfpDichiarati && !!errors.cfpDichiarati}
                                        />
                                        <Form.Control.Feedback type="invalid">{errors.cfpDichiarati}</Form.Control.Feedback>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} className="my-3">
                                    <Form.Label as={Col} lg={3} md={4} sm={12}>Note</Form.Label>
                                    <Col lg={9} md={9} sm={12}>
                                        <Form.Control
                                            as="textarea"
                                            name="noteRichiedente"
                                            disabled={isLoading || submittedConfirmed}
                                            value={values.noteRichiedente}
                                            onChange={handleChange}
                                            isInvalid={!!touched.noteRichiedente && !!errors.noteRichiedente}
                                        />
                                        <Form.Control.Feedback type="invalid">{errors.noteRichiedente}</Form.Control.Feedback>
                                    </Col>
                                </Form.Group>

                                {
                                    error ? (
                                        <Alert variant='danger' className="my-3">
                                            Si è verificato un errore durante il salvataggio della richiesta.
                                        </Alert>
                                    ) : (
                                        <Fragment />
                                    )
                                }

                                <Button type="submit" className="w-10" size="lg" variant="primary" disabled={isLoading || isDisable || submittedConfirmed}>
                                    {isLoading ? (
                                        <Fragment>
                                            <Spinner as="span" animation="border" size="sm" role="status" />
                                            <span className="ms-1">Attendi</span>
                                            <span>...</span>
                                        </Fragment>
                                    ) : (
                                        <span>Invia</span>
                                    )}
                                </Button>
                            </Form>
                        </div>
                    )}
                </Formik>
            </div>

            <Modal show={show} centered>
                <Modal.Header >
                    <Modal.Title>Operazione completata con successo! </Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center">
                    <CheckCircleFill size={100} className="text-success" />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleClose} >
                        Chiudi
                    </Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
};

export default CertificationNonStandardForm;
