import { FC, Fragment, useState } from 'react';
import { Alert, Tab, Tabs } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { IProfessionalEntity } from '../../entities/professional.entity';
import { RootState } from '../../redux/store';
import ProfessionalCertifications from '../shared/ProfessionalCertifications';
import ProfessionalCourses from '../shared/ProfessionalCourses';
import ProfessionalPersonalData from '../shared/ProfessionalPersonalData';
import ProfessionalTrainingCredits from '../shared/ProfessionalTrainingCredits';
import CertficationForm from './CertficationForm';
import CertificationListNonStandard from './CertificationListNonStandard';
import ExeptionsListNonStandard from './ExeptionsListNonStandard';

interface IProps {
    codiceFiscale: string;
}

const Professional: FC<IProps> = (props) => {
    const [show, setShow] = useState(true);

    const [activeTab, setActiveTab] = useState<string>("personalData");
    const [refreshTrigger, setRefreshTrigger] = useState<number>(0);

    const handleFormSubmitSuccess = () => {
        // Cambia tab al submit del form
        setActiveTab("certifications");
        setRefreshTrigger((prev) => prev + 1);
    };

    const handleFormSubmitSuccessNonStandard = () => {
        setRefreshTrigger((prev) => prev + 1);
    };

    const professional: IProfessionalEntity | null = useSelector((s: RootState) => (s.professional.value));

    return (
        <Fragment>
            {
                professional != null && (professional.dataNascita == null || professional.dataNascita.length == 0 ||
                    professional.dataPrimaIscrizione == null || professional.dataPrimaIscrizione.length == 0) ? (
                    <Alert variant="warning" show={show} onClose={() => setShow(false)} dismissible>
                        <Alert.Heading>Attenzione! dati anagrafici incompleti.</Alert.Heading>
                        <p>I campi DATA NASCITA e/o DATA PRIMA ISCRIZIONE non sono valorizzati.</p>
                        <hr />
                        <span>Accedere ad albo unico per popolarli ed entro 24 ore le anagrafiche verranno allineate automaticamente.</span>
                    </Alert>
                ) : ""
            }
            <Tabs activeKey={activeTab} onSelect={(tabKey) => setActiveTab(tabKey!)}
                // defaultActiveKey="personalData" 
                >
                <Tab eventKey="personalData" title="Anagrafica">
                    <ProfessionalPersonalData codiceFiscale={props.codiceFiscale} />
                </Tab>
                <Tab eventKey="trainingCredits" title="Report Status">
                    <ProfessionalTrainingCredits codiceFiscale={props.codiceFiscale} />
                </Tab>
                {/* 
                <Tab eventKey="trainingCreditsImateria" title="Status CFP Im@teria - Aggiornato al 21/12/21">
                    <ProfessionalTrainingCreditsImateria codiceFiscale={props.codiceFiscale} />
                </Tab> 
                */}
                <Tab eventKey="courses" title="Corsi">
                    <ProfessionalCourses codiceFiscale={props.codiceFiscale} disableButtonExportPdf={true} disableButtonMoodle={false} />
                </Tab>
                <Tab eventKey="certifications" title="Certificazioni / Esoneri">
                    <ProfessionalCertifications codiceFiscale={props.codiceFiscale} refreshTrigger={refreshTrigger}/>
                </Tab>
                <Tab eventKey="cfp_assignment" title="Assegna CFP">
                    <CertficationForm codiceFiscale={props.codiceFiscale} onSubmitSuccess={handleFormSubmitSuccess} />
                </Tab>
                <Tab eventKey="cfp_non_standard_list" title="Attribuzioni Retroattive">
                    <CertificationListNonStandard codiceFiscale={props.codiceFiscale} onSubmitSuccess={handleFormSubmitSuccessNonStandard}/>
                </Tab>
                <Tab eventKey="esoneri_non_standard_list" title="Esoneri Retroattivi">
                    <ExeptionsListNonStandard codiceFiscale={props.codiceFiscale} onSubmitSuccess={handleFormSubmitSuccessNonStandard}/>
                </Tab>
            </Tabs>
        </Fragment>
    );
};

export default Professional;