import { Formik, FormikProps } from 'formik';
import React, { FC, Fragment, useEffect, useRef, useState } from 'react';
import { Alert, Button, Col, Form, Modal, Row, Spinner } from 'react-bootstrap';
import { Asterisk, CheckCircleFill } from 'react-bootstrap-icons';
import { FaInfoCircle } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { IAttachment } from '../../entities/attachment.entity';
import { IMetadataOption, IMetadataOptionNode } from '../../entities/metadata.entity';
import { IPostCertificationThunkPayload, postCertification } from '../../redux/slices/certification.slice';
import { getMetadataCertification, getMetadataCourse } from '../../redux/slices/metadata.slice';
import { AppDispatch, RootState } from '../../redux/store';
import { getLineeGuida } from '../../redux/slices/lineeguida.slice';
import { parse, setHours, setMinutes, setSeconds } from 'date-fns';
import { parseISOStringToStandardDate } from '../../utils/course-parser';

interface IFormValue extends IPostCertificationThunkPayload {
    // responsabilita: boolean;
    triennio: number;
    dataRiferimentoSelect: string;
    dataRiferimento: Date | null;
    dataInserimento: Date | null;
    delibera: string;
};

interface IProps {
    codiceFiscale: string;
    defaultInitValue?: IPostCertificationThunkPayload;
    onSubmitSuccess?: () => void;
}

const ExceptionNonStandardForm: FC<IProps> = (props) => {
    const dispatch = useDispatch<AppDispatch>();

    const tipoEsoneroNonStandard: Array<IMetadataOptionNode> = useSelector((s: RootState) => s.metadata.certification.tipo_esonero_non_standard);
    // const tipoAccreditoCertificazioneNonStandard: Array<IMetadataOptionNode> = useSelector((s: RootState) => s.metadata.certification.tipo_accredito_certificazione_non_standard);
    const [tipologiaEsoneroLG, setTipologiaEsoneroLG] = useState<Array<IMetadataOptionNode>>([]);
    const trienni: Array<IMetadataOptionNode> = useSelector((s: RootState) => s.metadata.course.trienni);
    const [trienniSelezionabili, setTrienniSelezionabili] = useState<Array<IMetadataOptionNode>>([]);
    const [anniSelezionabili, setAnniSelezionabili] = useState<Array<IAnnoSelezionabile>>([]);
    const [lineaGuida, setlineaGuida] = useState<number>(0);
    const [annoInserito, setAnnoInserito] = useState<boolean>(false);
    const [tipologiaSelezionata, setTipologiaSelezionata] = useState<boolean>(false);
    const [dateRiferimentoSelect, setDateRiferimentoSelect] = useState<Array<IDataSelezionabile>>([]);

    // const lineeGuida: Array<ILineaGuidaEntity> = useSelector((s: RootState) => s.lineeGuida.lineeGuida);

    // const [attachNotRequired, setAttachNotRequired] = useState<boolean>(true);

    const initialValues: IFormValue = {
        codiceFiscale: props.codiceFiscale,
        idOggetto: 3,
        idTipologia: 0,
        annoRiferimento: new Date().getFullYear(),
        dataRiferimentoSelect: "",
        dataRiferimentoDa: "",
        dataRiferimentoA: "",
        // dataRiferimentoDa: parseISOStringToStandardDate(new Date((new Date()).getFullYear(), 0, 1).toISOString()),
        // dataRiferimentoA: parseISOStringToStandardDate(new Date((new Date()).getFullYear(), 11, 31).toISOString()),
        dataInserimento: null,
        dataRiferimento: null,
        delibera: '',
        titolo: '',
        organizzatore: '',
        codiceCorso: '',
        cfpDichiarati: 0,
        cfpAssegnati: 0,
        noteRichiedente: '',
        idStatoCertificazione: 4,    // verificare se lo stato è corretto
        // responsabilita: true,
        triennio: trienniSelezionabili?.length ? trienniSelezionabili.length : 0,
        allegati: new Array<IAttachment>(),
    };

    const formikRef = useRef<FormikProps<any>>(null);

    // export interface IMetadataOptionNode extends IMetadataOption {
    //     extId: number;
    //     cfp_o: number;
    //     cfp_d: number;
    //     minimo: number;
    //     massimo: number;
    //     tipoAccreditoCfpId : number;
    //     tipoEsoneroId: number;
    //     data: string;
    //     lg: number;
    // }

    // const calcolaAnni = (minAnno: number, maxAnno: number): Array<number> => {
    //     const anni: Array<number> = [];
    //     for (let anno = minAnno; anno <= maxAnno; anno++) {
    //       anni.push({
    //         anno, // o un altro formato che ti serve per 'value'
    //       });
    //     }
    //     return anni;
    //   };

    interface IAnnoSelezionabile {
        anno: number;
        lineaGuida: number;
    }

    interface IDataSelezionabile {
        data: string;
        id: number;
    }

    const today = new Date();
    useEffect(() => {
        // il campo data in questo caso rappresenta la data di fine ravvedimento operoso
        if (trienni && trienni.length > 0) {
            // console.log(trienni);
            setTrienniSelezionabili(trienni.filter((item) => new Date(item.data) < today));
        }
    }, [trienni]);

    useEffect(() => {
        
        const anni: Array<IAnnoSelezionabile> = trienniSelezionabili.flatMap((triennio) => {
            const { minimo, massimo, id } = triennio;
            return Array.from({ length: massimo - minimo + 1 }, (_, index) => ({
              anno: minimo + index,
              lineaGuida: id,
            }));
          });
    
          setAnniSelezionabili(anni);

        //   console.log("anni selezionabili: ", anni);
    }, [trienniSelezionabili]);

    useEffect(() => {
        if (anniSelezionabili && anniSelezionabili.length > 0) {
            const annoCorrente = today.getFullYear();
            const annoCorrenteInfo = anniSelezionabili.find((item) => item.anno === annoCorrente);
            if (annoCorrenteInfo) {
                setAnnoInserito(true);
                setTipologiaEsoneroLG(tipoEsoneroNonStandard.filter(item => item.lg === annoCorrenteInfo.lineaGuida));
                setlineaGuida(annoCorrenteInfo.lineaGuida);
            }
            formikRef.current?.setFieldValue('annoRiferimento', anniSelezionabili[anniSelezionabili.length - 1].anno);
            formikRef.current?.setFieldTouched('annoRiferimento', true, true);
        }
    }, [anniSelezionabili]);
    
    
    useEffect(() => {
        if (trienniSelezionabili && trienniSelezionabili.length > 0 && tipoEsoneroNonStandard && tipoEsoneroNonStandard.length > 0) {
            const ultimoTriennio = trienniSelezionabili[trienniSelezionabili.length - 1];
            setTipologiaEsoneroLG(tipoEsoneroNonStandard.filter(item => item.lg === ultimoTriennio.id));
            setlineaGuida(ultimoTriennio.id);
            // console.log("trienni selezionabili: ", trienniSelezionabili);
            // console.log("tipo esonero non standard: ", tipoEsoneroNonStandard);
            // Imposta il valore di triennio sull'ultimo elemento dell'array
            formikRef.current?.setFieldValue('triennio', ultimoTriennio.id);
            formikRef.current?.setFieldValue('annoRiferimento', ultimoTriennio.massimo + "-12-31");
            formikRef.current?.setFieldTouched('annoRiferimento', true, true);
            // formikRef.current?.setFieldValue('dataRiferimento', ultimoTriennio.massimo + "-12-31");
            // formikRef.current?.setFieldValue('dataRiferimentoA', ultimoTriennio.massimo + "-12-31");
            // formikRef.current?.setFieldValue('dataRiferimentoDa', ultimoTriennio.massimo + "-12-31");

            // parseISOStringToStandardDate(new Date((new Date()).getFullYear(), 0, 1).toISOString())
        }
    }, [trienniSelezionabili, tipoEsoneroNonStandard]);

    useEffect(() => {  
        // formikRef.current?.setFieldValue('idTipologia', tipologiaEsoneroLG[0]?.id);
        if (anniSelezionabili && anniSelezionabili.length > 0) {
            if (tipologiaEsoneroLG.length == 1) {
                formikRef.current?.setFieldValue('dataRiferimento', anniSelezionabili[0].anno + "-01-01");
                formikRef.current?.setFieldValue('idTipologia', tipologiaEsoneroLG[0].id); 
                formikRef.current?.setFieldValue('idTipologia', tipologiaEsoneroLG[0].cfp_d); 
            } else {                    
                formikRef.current?.setFieldValue('dataRiferimento', ""); 
                formikRef.current?.setFieldValue('idTipologia', 0); 
            }    
            
            formikRef.current?.setFieldTouched('dataRiferimento', true, true); // Segna il campo come toccato e attiva la validazione
            formikRef.current?.setFieldTouched('idTipologia', true, true); // Segna il campo come toccato e attiva la validazione
        }
        // console.log(tipologiaEsoneroLG.length);    
    }, [tipologiaEsoneroLG, anniSelezionabili]);

    // const handleTriennioChange = (e: React.ChangeEvent<HTMLInputElement>,  
    //     setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void,
    //     setFieldTouched: (field: string, isTouched?: boolean, shouldValidate?: boolean) => void,
    //     handleChange: (e: React.ChangeEvent<any>) => void) => {    
    //         // console.log("handleTriennioChange custom, ", e.target.name, ": ", e.target.value); 
    //         const { name, value } = e.target;
    //         setFieldValue(name, value); // Aggiorna il valore del campo con Formik
    //         setFieldTouched(name, true, true); // Segna il campo come toccato e attiva la validazione
    //         // setFieldValue("cfpDichiarati", 0);
    //         // setFieldTouched("cfpDichiarati", false, true);
    //         handleChange(e); // Gestisce anche gli eventi di cambiamento Formik

    //         const triennio = trienniSelezionabili?.at(Number(value) - 1);
    //         // console.log("triennio selezionato: ", triennio);
    //         setTipologiaEsoneroLG(tipoEsoneroNonStandard.filter(item => item.lg === triennio?.id));
    //         setlineaGuida(triennio?.id || 0);
    //         const dataMassimo = triennio ? triennio.massimo : 0;
    //         // console.log("data nel triennio: ", dataMassimo);
    //         setFieldValue('dataRiferimento', dataMassimo + "-12-31");
    //         formikRef.current?.setFieldValue('dataRiferimentoA', dataMassimo + "-12-31");
    //         formikRef.current?.setFieldValue('dataRiferimentoDa', dataMassimo + "-12-31");
    // }

    const handleAnnoChange = (e: React.ChangeEvent<HTMLInputElement>,
        setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void,
        setFieldTouched: (field: string, isTouched?: boolean, shouldValidate?: boolean) => void,
        handleChange: (e: React.ChangeEvent<any>) => void,
        anniSelezionabili: IAnnoSelezionabile[]
            ) => {
            const { name, value } = e.target;
            const annoSelezionato = parseInt(value, 10); // Convertire il valore selezionato in un numero
            const annoInfo = anniSelezionabili.find((item) => item.anno === annoSelezionato); // Trovare l'idTriennio associato

            if (annoInfo) {
                const { lineaGuida } = annoInfo; // Estrarre l'idTriennio dal record associato
            
                // Aggiornare il valore del campo con Formik
                setFieldValue(name, value);
            
                // Aggiornare la tipologia di esonero e la linea guida
                setTipologiaEsoneroLG(tipoEsoneroNonStandard.filter((item) => item.lg === lineaGuida)); // Filtra per lg = idTriennio
                setlineaGuida(lineaGuida); // Imposta la linea guida al valore di idTriennio
            
                // Aggiornare altri campi Formik relativi a date
                // setFieldValue('dataRiferimento', `${value}-01-01`);
                // formikRef.current?.setFieldValue('dataRiferimentoA', `${value}-01-01`);
                // formikRef.current?.setFieldValue('dataRiferimentoDa', `${value}-01-01`);
            
                // Log per debugging
                // console.log("Anno selezionato:", value);
                // console.log("ID Triennio:", lineaGuida);
                
                setTipologiaSelezionata(false);
                setDateRiferimentoSelect([]);
                setIsDisable(true);
                setFieldValue("dataRiferimentoSelect", "");
                setFieldTouched(name, true, true); // Segnare il campo come toccato
                handleChange(e); // Continuare con il normale flusso di Formik
              } else {
                console.error("Anno selezionato non trovato in anniSelezionabili");
              }
    }

    const handleTipologiaChange = (e: React.ChangeEvent<HTMLInputElement>,
        setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void,
        setFieldTouched: (field: string, isTouched?: boolean, shouldValidate?: boolean) => void,
        handleChange: (e: React.ChangeEvent<any>) => void) => {
            const { name, value } = e.target;
            setFieldValue(name, value); // Aggiorna il valore del campo con Formik
            setFieldTouched(name, true, true); // Segna il campo come toccato e attiva la validazione
            // setFieldValue("cfpDichiarati", 0);
            // setFieldTouched("cfpDichiarati", false, true);
            handleChange(e); // Gestisce anche gli eventi di cambiamento Formik
            setFieldValue("titolo", tipologiaEsoneroLG.find((item) => item.id === Number(value))?.nome || "");
            // console.log("titolo: ", tipologiaEsoneroLG.find((item) => item.id === Number(value))?.nome || "nessun titolo");
            // console.log("value: ", value);
            // console.log(tipologiaEsoneroLG.find((item) => item.id === Number(value)));
            // console.log("anno selezionat0: ", formikRef.current?.values.annoRiferimento);

            const dichiarati = tipologiaEsoneroLG.find((item) => item.id === Number(value)) ? tipologiaEsoneroLG.find((item) => item.id === Number(value))?.massimo == 10 ? 10 : 20 : 0;

            setFieldValue("cfpDichiarati", dichiarati);
            setFieldTouched("cfpDichiarati", true, true);
            setTipologiaSelezionata(true);
            if (dichiarati == 10) {
                setDateRiferimentoSelect([{data: "01/01/" + formikRef.current?.values.annoRiferimento.toString(), id: 0}, 
                    {data: "01/07/" + formikRef.current?.values.annoRiferimento.toString(), id: 1 }]);
            } else {
                setDateRiferimentoSelect([{data: "01/01/" + formikRef.current?.values.annoRiferimento.toString(), id: 0},]);
            }
            // console.log(tipologiaEsoneroLG.find((item) => item.id === Number(value)) ? tipologiaEsoneroLG.find((item) => item.id === Number(value))?.massimo == 10 ? 10 : 20 : 0);
    }
    
    const handleGenericChange = (e: React.ChangeEvent<HTMLInputElement>,  
        setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void,
        setFieldTouched: (field: string, isTouched?: boolean, shouldValidate?: boolean) => void,
        handleChange: (e: React.ChangeEvent<any>) => void) => {    
            // console.log("handleGenericChange custom:", e.target.value); 
            const { name, value } = e.target;
            setFieldValue(name, value); // Aggiorna il valore del campo con Formik
            setFieldTouched(name, true, true); // Segna il campo come toccato e attiva la validazione
            handleChange(e); // Gestisce anche gli eventi di cambiamento Formik
            setIsDisable(false);
    }

    const schema = yup.object().shape({
        delibera: yup.string().required("Campo obbligatorio").max(50, ({ max }) => `Raggiunta lunghezza massima consentita di ${max} caratteri`),
        noteRichiedente: yup.string().max(300, ({ max }) => `Raggiunta lunghezza massima consentita di ${max} caratteri`).nullable(),
    });

    const [isLoading, setLoading] = useState(false);
    const [show, setShow] = useState(false);
    const [error, setError] = useState(false);
    const [isDisable, setIsDisable] = useState(true);
    const [submittedConfirmed, setSubmittedConfirmed] = useState(false);

    const handleClose = () => {
        setShow(false);
        setSubmittedConfirmed(true);
        if (props?.onSubmitSuccess) {
            props.onSubmitSuccess();
        };
    };

    useEffect(() => {
        dispatch(getMetadataCertification({}));
        dispatch(getMetadataCourse({}));
        dispatch(getLineeGuida({}));
    }, [dispatch]);

    const handleSubmit = (value: IFormValue) => {
        const dataSelezionata = parse(value.dataRiferimentoSelect, "dd/MM/yyyy", new Date());
        console.log("data selezionata: ", dataSelezionata);
        console.log("data riferimento: ", value.dataRiferimentoSelect);
        const val: IFormValue = { ...value, 
            cfpAssegnati: value.cfpDichiarati, 
            dataInserimento: new Date(), 
            dataRiferimento: parse(value.dataRiferimentoSelect + "T02:00:00", "yyyy-MM-dd'T'HH:mm:ss", new Date()),
            dataRiferimentoA: setSeconds(setMinutes(setHours(dataSelezionata, 2), 0), 0).toISOString(),
            dataRiferimentoDa: setSeconds(setMinutes(setHours(dataSelezionata, 2), 0), 0).toISOString(),
            // dataRiferimentoA: value.dataRiferimentoSelect + "T02:00:00",
            // dataRiferimentoDa: value.dataRiferimentoSelect + "T02:00:00"
            // parseISOStringToStandardDate(new Date((new Date()).getFullYear(), 11, 31).toISOString()),
         };
        console.log(val);
        setLoading(true);
        setError(false);
        dispatch(postCertification(val))       
            .unwrap()
            .then(() => {
                setShow(true);
            })
            .catch(() => {
                setError(true);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <Fragment>
            <div className={`${submittedConfirmed ? 'd-none' : ''}`}>
                <Formik
                    innerRef={formikRef}
                    initialValues={initialValues}
                    validationSchema={schema}
                    onSubmit={handleSubmit}
                >
                    {({ handleSubmit, handleChange, values, errors, touched, setFieldValue, setFieldTouched }) => (
                        <div className="mx-3">
                            <Form onSubmit={handleSubmit}>

                                <Form.Group as={Row} className="my-3" >
                                    <Form.Label as={Col} lg={3} md={4} sm={12}>N° delibera consiglio<Asterisk size={8} className="mb-3" /></Form.Label>
                                    <Col lg={9} md={9} sm={12}>
                                        <Form.Control
                                            type="text"
                                            name="delibera"
                                            disabled={isLoading || submittedConfirmed}
                                            value={values.delibera}
                                            onChange={handleChange}
                                            // onChange={(e) => handleGenericChange(e as React.ChangeEvent<HTMLInputElement>, setFieldValue, setFieldTouched, handleChange)}
                                            isInvalid={!!touched.delibera && !!errors.delibera}
                                        />
                                        <Form.Control.Feedback type="invalid">{errors.delibera}</Form.Control.Feedback>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} className="my-3" >
                                    <Form.Label as={Col} lg={3} md={4} sm={12}>Anno<Asterisk size={8} className="mb-3" /></Form.Label>
                                    <Col lg={9} md={9} sm={12}>
                                        <Form.Control
                                            as="select"
                                            name="annoRiferimento"
                                            value={values.annoRiferimento}
                                            onChange={(e) => handleAnnoChange(e as React.ChangeEvent<HTMLInputElement>, setFieldValue, setFieldTouched, handleChange, anniSelezionabili )}
                                            disabled={isLoading || submittedConfirmed}
                                        >
                                            {anniSelezionabili.map((item) => (
                                                <option key={`${item.anno}-${item.lineaGuida}`} value={item.anno}>
                                                {item.anno}
                                                </option>
                                            ))}
                                        </Form.Control>
                                    </Col>
                                </Form.Group>
{/* 
                                <Form.Group as={Row} className="my-3" >
                                    <Form.Label as={Col} lg={3} md={4} sm={12}>Triennio<Asterisk size={8} className="mb-3" /></Form.Label>
                                    <Col lg={9} md={9} sm={12}>
                                        <Form.Control
                                            as="select"
                                            name="triennio"
                                            value={values.triennio}
                                            onChange={(e) => handleTriennioChange(e as React.ChangeEvent<HTMLInputElement>, setFieldValue, setFieldTouched, handleChange)}
                                            disabled={isLoading || submittedConfirmed}
                                        >
                                            {trienniSelezionabili.map((item: IMetadataOptionNode, index: number) => {
                                                return (<option key={"idTriennio" + item.id + index} value={item.id}>{item.nome}</option>);
                                            })}
                                        </Form.Control>
                                    </Col>
                                </Form.Group> */}

                                <Form.Group as={Row} className="my-3" >
                                    <Form.Label as={Col} lg={3} md={4} sm={12}>Tipologia<Asterisk size={8} className="mb-3" /></Form.Label>
                                    <Col lg={9} md={9} sm={12}>
                                        <Form.Control
                                            as="select"
                                            name="idTipologia"
                                            value={values.idTipologia}
                                            onChange={(e) => handleTipologiaChange(e as React.ChangeEvent<HTMLInputElement>, setFieldValue, setFieldTouched, handleChange)}
                                            disabled={isLoading || submittedConfirmed }
                                            >
                                            <option key={"idTipologia0"} value={0}></option>
                                            {tipologiaEsoneroLG.length && (
                                                tipologiaEsoneroLG.map((item: IMetadataOption, index: number) => {
                                                    return (<option key={"idTipologia" + item.id} value={item.id}>{item.nome}</option>);
                                                })
                                            )}
                                        </Form.Control>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} className="my-3" >
                                    <Form.Label as={Col} lg={3} md={4} sm={12}>Data riferimento<Asterisk size={8} className="mb-3" /></Form.Label>
                                    <Col lg={9} md={9} sm={12}>
                                        <Form.Control 
                                            as="select" 
                                            // onChange={handleChange}
                                            onChange={(e) => handleGenericChange(e as React.ChangeEvent<HTMLInputElement>, setFieldValue, setFieldTouched, handleChange)}
                                            name="dataRiferimentoSelect"
                                            // value={values?.dataRiferimento?.toString() || ""}     
                                            value={values.dataRiferimentoSelect}
                                            isInvalid={!!touched.dataRiferimento && !!errors.dataRiferimento}
                                            disabled={isLoading || submittedConfirmed || !tipologiaSelezionata} 
                                            >
                                                <option value="" disabled>
                                                        Seleziona una data
                                                </option>
                                            {dateRiferimentoSelect.length > 0 && (
                                                dateRiferimentoSelect.map((item: IDataSelezionabile) => {
                                                    return (<option key={"dataRiferimento" + item.id} value={item.data}>{item.data}</option>);
                                                })
                                            )}                                        
                                        </Form.Control>
                                        <Form.Control.Feedback type="invalid">{errors.dataRiferimento}</Form.Control.Feedback>
                                        {dateRiferimentoSelect.length > 1 && (
                                                <Form.Text>Fare attenzione a non sovrapporre esoneri semestrali</Form.Text>
                                            )}                                        
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} className="my-3" >
                                    <Form.Label as={Col} lg={3} md={4} sm={12}>CFP equivalenti totali
                                        <a href="/assets/tutorial/CNAPPC_linee-guida-formazione_2024_ALL2.pdf" target="_blank" rel="noreferrer">
                                            <FaInfoCircle className="text-dark" title="Vedi la tabella di assegnazione cfp" size={22} />
                                        </a>
                                    </Form.Label>
                                    <Col lg={9} md={9} sm={12}>
                                        <Form.Control
                                            type="number"
                                            name="cfpDichiarati"
                                            disabled={true}
                                            value={values.cfpDichiarati}
                                        />
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} className="my-3">
                                    <Form.Label as={Col} lg={3} md={4} sm={12}>Note</Form.Label>
                                    <Col lg={9} md={9} sm={12}>
                                        <Form.Control
                                            as="textarea"
                                            name="noteRichiedente"
                                            disabled={isLoading || submittedConfirmed}
                                            value={values.noteRichiedente}
                                            onChange={handleChange}
                                            isInvalid={!!touched.noteRichiedente && !!errors.noteRichiedente}
                                        />
                                        <Form.Control.Feedback type="invalid">{errors.noteRichiedente}</Form.Control.Feedback>
                                    </Col>
                                </Form.Group>

                                {
                                    error ? (
                                        <Alert variant='danger' className="my-3">
                                            Si è verificato un errore durante il salvataggio della richiesta.
                                        </Alert>
                                    ) : (
                                        <Fragment />
                                    )
                                }

                                <Button type="submit" className="w-10" size="lg" variant="primary" disabled={isLoading || isDisable || submittedConfirmed}>
                                    {isLoading ? (
                                        <Fragment>
                                            <Spinner as="span" animation="border" size="sm" role="status" />
                                            <span className="ms-1">Attendi</span>
                                            <span>...</span>
                                        </Fragment>
                                    ) : (
                                        <span>Invia</span>
                                    )}
                                </Button>
                            </Form>
                        </div>
                    )}
                </Formik>
            </div>

            <Modal show={show} centered>
                <Modal.Header >
                    <Modal.Title>Operazione completata con successo! </Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center">
                    <CheckCircleFill size={100} className="text-success" />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleClose} >
                        Chiudi
                    </Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
};

export default ExceptionNonStandardForm;
