import { format } from 'date-fns';
import { FC, Fragment, useEffect, useState } from 'react';
import { Alert, Button, Col, Container, InputGroup, Modal, Pagination, Row } from 'react-bootstrap';
import DataTable, { TableColumn } from 'react-data-table-component';
import { AiOutlineEdit } from "react-icons/ai";
import { BsTrash } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { IRejectResponse } from '../../api/api.interface';
import { ICertificationEntity } from '../../entities/certification.entity';
import { deleteCertification, IDeleteCertificationThunkPayload } from '../../redux/slices/certification.slice';
import { ICertificationsPagination } from '../../redux/slices/certifications.slice';
import { getExceptionsNonStandardProfessional, ICertificationsNonStandardThunkPayload } from '../../redux/slices/professional.slice';
import { AppDispatch, RootState } from '../../redux/store';
import ExceptionEdit from '../shared/ExceptionEdit';
import ExceptionNonStandardForm from './ExceptionNonStandardForm';

interface IPagination extends ICertificationsPagination { }
interface IProps {  
  codiceFiscale: string;
  onSubmitSuccess?: () => void;
}

const ExeptionsListNonStandard: FC<IProps> = (props) => {
  const dispatch = useDispatch<AppDispatch>();
 
  const countTotalExceptions: number = useSelector((s: RootState) => s.certifications.total);

  const [isLoading, setLoading] = useState(true);
  const [selectedExceptionRows, setSelectedExceptionRows] = useState<Array<ICertificationEntity>>(new Array<ICertificationEntity>());
  const [pagination, setPagination] = useState<IPagination>({ page: 1, limit: 25 });
  const [showDelete, setShowDelete] = useState(false);
  const [selectedId, setSelectedId] = useState<number | null>(null);

  const error = useSelector((s: RootState) => s.certifications.error as IRejectResponse);
  
  const exceptionsNonStandard: Array<ICertificationEntity> = useSelector((s: RootState) => s.professional.exceptionsNonStandard);
  // const certificationsNonStandard: Array<ICertificationEntity> = useSelector((s: RootState) => s.professional.certificationsNonStandard);

  const [toggleSelectedExceptionRowsCleared, setToggleSelectedExceptionsRowsCleared] = useState(false);
  const [selectedItem, setSelectedItem] = useState<ICertificationEntity | null>(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showInsertExceptionNoNStandard, setShowInsertExceptionNoNStandard] = useState(false);

  const handleCloseDelete = () => {
    if (props?.onSubmitSuccess) {
      props.onSubmitSuccess();
    };
    setShowDelete(false);
    setSelectedId(null);
  };
  const handleShowDelete = () => setShowDelete(true);

  const handleOpenEditModal = () => setShowEditModal(true);

  const handleCloseEditModal = () => {
    setShowEditModal(false);
    setSelectedItem(null);
    setPagination({ ...pagination });
    if (props?.onSubmitSuccess) {
      props.onSubmitSuccess();
    };
  };

  const handleClearChangeStatoEsonero = () => {
    if (selectedExceptionRows.length) {
      setToggleSelectedExceptionsRowsCleared(!toggleSelectedExceptionRowsCleared);
      setSelectedExceptionRows(new Array<ICertificationEntity>());
    }
  };

  useEffect(() => {
    setLoading(true);
    handleClearChangeStatoEsonero();
    dispatch(getExceptionsNonStandardProfessional({ codiceFiscale: props.codiceFiscale, pagination: pagination } as ICertificationsNonStandardThunkPayload))
      .unwrap()
      .finally(() => setLoading(false));
  }, [dispatch, pagination]);

  const handlePaginationLoad = (pag: number) => {
    setPagination({ ...pagination, page: pag });
  };

  const handlePaginationLimit = (limit: number) => {
    setPagination({ page: 1, limit: limit });
  };

  const handlePaginationLoadMore = () => {
    setPagination({ ...pagination, page: pagination.page + 1 });
  };

  const handlePaginationLoadMinus = () => {
    const previuosPage = pagination.page - 1;
    if (previuosPage === 0) return;
    setPagination({ ...pagination, page: pagination.page - 1 });
  };

  const handleClickItemEdit = (cert: ICertificationEntity) => {
    setSelectedItem(cert);
    handleOpenEditModal();
  };

  const handleOpenModalNewCertificationNonStandard = () => {
    setShowInsertExceptionNoNStandard(true);
  };

  const handleCloseInsertExceptionNonStandardModal = () => {
    if (props?.onSubmitSuccess) {
        props.onSubmitSuccess();
    };
    setPagination({ ...pagination, page: 1 });
    setShowInsertExceptionNoNStandard(false);
  };

  const columns: TableColumn<ICertificationEntity>[] = [
    {
      id: 'id',
      name: '#Id',
      selector: (row: ICertificationEntity) => row.id,
      cell: (row: ICertificationEntity) => (<span className="blockquote small m-0 p-0" title={'#' + row?.id.toString()}><b>#</b><small><u>{row.id}</u></small></span>),
      grow: 1,
      omit: true,
    },
    {
      name: 'CFP assegnati',
      selector: (row: ICertificationEntity) => (((row.idOggetto === 2 && row.idTipologia === 0) 
      || (row.idOggetto === 3 && row.idTipologia === 6) 
      || (row.idOggetto === 4 && row.idTipologia === 0) 
      || (row.idOggetto === 4 && row.idTipologia === 1)) ? "L.G./Aut." : row.cfpAssegnati),
      // selector: (row: ICertificationEntity) => row.cfpDichiarati + " / " + row.cfpAssegnati,
      grow: 1,
    },
    {
      name: 'Delibera',
      selector: (row: ICertificationEntity) => (row.delibera ? row.delibera : ""),
      grow: 2,
    },
    {
      name: 'Anno di riferimento',
      selector: (row: ICertificationEntity) => (row.dataRiferimentoDa ? format(new Date(row.dataRiferimentoDa), 'yyyy') : ""),
      grow: 2,
    },
    {
      selector: (row: ICertificationEntity) => "",
      cell: (row: ICertificationEntity) => (
        <span>
          <BsTrash className="text-danger c-pointer p-0 my-0 ms-0 me-2" title="elimina" size={18} onClick={(e: React.MouseEvent<SVGElement>) => {
              e.preventDefault();
              setSelectedId(row.id);
              handleShowDelete();
            }} />
          <AiOutlineEdit className="text-dark c-pointer p-0 my-0 ms-0 me-1" title="modifica" size={20} onClick={(e: React.MouseEvent<SVGAElement>) => {
              e.preventDefault();
              handleClickItemEdit(row);
            }} />
          {/* <IoEllipsisVerticalCircle className="text-dark c-pointer p-0 m-0" title="dettagli" size={20} onClick={(e: React.MouseEvent<SVGElement>) => {
              e.preventDefault();
              handleClickItem(row);
            }} /> */}
        </span>),
      ignoreRowClick: true,
      button: true,
      grow: 1,
    },
  ] as TableColumn<ICertificationEntity>[];

  const handleChange = (selected: any) => {
    setSelectedExceptionRows(selected.selectedRows);
    // You can set state or dispatch with something like Redux so we can use the retrieved data
    //console.log('Selected Rows: ', selected.selectedRows);
  };

  const handleDelete = () => {
    if (selectedId) {
      setLoading(true);
      dispatch(deleteCertification({ id: selectedId } as IDeleteCertificationThunkPayload))
        .unwrap()
        .then(() => {
          handleCloseDelete();
          setSelectedItem(null);
          setPagination({ ...pagination, page: 1 });
        })
        .finally(() => setLoading(false));
    }
  };

  return (
    <Fragment>
      <Container fluid as="section">        
        <Row className="mb-3">
          <Col className="mx-1">
          {(exceptionsNonStandard.length > 0 ? "certificazioni " + (1 + pagination.limit * (pagination.page-1)) + "-" + (exceptionsNonStandard.length + pagination.limit * (pagination.page-1)) + 
                                (exceptionsNonStandard.length >= countTotalExceptions ? "" : " di " + countTotalExceptions) : "0 certificazioni TOTALI")}
          </Col>          
          <Col className="d-flex justify-content-center"><h3>ESONERI RETROATTIVI</h3></Col>
          <Col>
            <InputGroup className="d-flex justify-content-end">
              <Button variant="primary" size="sm" onClick={handleOpenModalNewCertificationNonStandard}>
                Nuovo esonero retroattivo
              </Button>
              {/* <PageSelect defaultSelectedId={1} totPagine={Math.ceil(countTotalCertifications / pagination.limit)} onSelect={handlePaginationLoad} />
              <PaginationLimit defaultSelectedId={pagination.limit} onSelect={handlePaginationLimit} enableUnlimit={false} /> */}
            </InputGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            {
              isLoading ? (
                <Fragment />
              ) : (
                selectedExceptionRows.length ? (
                  <Alert variant="dark">
                    <section className="d-flex">
                      <div className="flex-grow-1 d-flex">
                        <h6><b>{selectedExceptionRows.length}</b> record selezionati</h6>
                      </div>
                      <div className="flex-shrink-1 d-flex">
                        <Button variant="secondary" className="mx-1" onClick={handleClearChangeStatoEsonero}>
                          Annulla
                        </Button>
                      </div>
                    </section>
                  </Alert>
                ) : (
                  <Fragment />
                )
              )
            }
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <DataTable columns={columns} data={exceptionsNonStandard} selectableRows onSelectedRowsChange={handleChange}
              responsive striped dense progressPending={isLoading} noDataComponent={`${error ? error.error : 'Nessun risultato'}`}
              clearSelectedRows={toggleSelectedExceptionRowsCleared} />
          </Col>
        </Row>
        <Row>
          <Col>
            <Pagination className="d-flex justify-content-center">
              <Pagination.First onClick={() => handlePaginationLoad(1)} disabled={(pagination.page - 1) === 0}>Prima pagina</Pagination.First>
              <Pagination.Prev key={"idPaginationItemPrev"} onClick={handlePaginationLoadMinus} disabled={isLoading || (pagination.page - 1) === 0}>Precedente</Pagination.Prev>
              <Pagination.Item key={pagination.page} disabled={isLoading} onClick={() => handlePaginationLoad(pagination.page)}>{pagination.page}</Pagination.Item>
              <Pagination.Next key={"idPaginationItemNext"} onClick={handlePaginationLoadMore} disabled={isLoading || pagination.limit != exceptionsNonStandard.length}>Successiva</Pagination.Next>
              <Pagination.Last onClick={() => handlePaginationLoad(Math.ceil(countTotalExceptions / pagination.limit))} disabled={pagination.limit != exceptionsNonStandard.length}>Ultima pagina</Pagination.Last>
            </Pagination>
          </Col>
        </Row>
      </Container>

      <Modal size="lg" centered show={showInsertExceptionNoNStandard} onHide={handleCloseInsertExceptionNonStandardModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            <p className="display-6 m-0 p-0">Esonero retroattivo con Delibera</p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ExceptionNonStandardForm codiceFiscale={props.codiceFiscale} onSubmitSuccess={handleCloseInsertExceptionNonStandardModal}/>
        </Modal.Body>
      </Modal>

      <Modal size="xl" centered show={showEditModal} onHide={handleCloseEditModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            <p className="display-6 m-0 p-0">Esonero</p>
            {selectedItem ? (<p className="blockquote m-0 p-0">richiesta {/*<b>#</b><u>{selectedItem.id}</u>*/} inserita il {format(new Date(selectedItem.dataCreazione), 'dd/MM/yyyy')}</p>) : ""}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedItem ? (
            <ExceptionEdit exception={selectedItem} submitted={false} nonstandard={true} onSubmitSuccess={handleCloseInsertExceptionNonStandardModal}/>
          ) : (
            <Fragment />
          )}
        </Modal.Body>
      </Modal>

      <Modal centered show={showDelete} onHide={handleCloseDelete}>
        <Modal.Header closeButton>
          <Modal.Title><p className="display-6 m-0 p-0">Esonero</p> {selectedId ? (<p className="blockquote m-0 p-0">CANCELLA richiesta {/*<b>#</b><u>{selectedId}</u>*/}</p>) : ""}</Modal.Title>
        </Modal.Header>
        <Modal.Body><p>Confermi la cancellazione?<br /><small>Cliccando sul bottone ELIMINA verrà cancellata la richiesta selezionata.</small></p></Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDelete} disabled={isLoading}>Annulla</Button>
          <Button variant="danger" onClick={handleDelete} disabled={isLoading}>Elimina</Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default ExeptionsListNonStandard;