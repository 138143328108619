import { format } from 'date-fns';
import { FC, Fragment, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import DataTable, { TableColumn } from 'react-data-table-component';
import { ICertificationEntity } from '../../entities/certification.entity';

interface IProps { 
  validati?: Array<ICertificationEntity>;
  scarti?: Array<ICertificationEntity>;
}

const CertificationListImportResults: FC<IProps> = (props) => {

  const [certificationsList, setCertificationsList] = useState(props.scarti || new Array<ICertificationEntity>());

  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
  }, [certificationsList]);

  const columns: TableColumn<ICertificationEntity>[] = [
    {
      id: 'id',
      name: '#Id',
      selector: (row: ICertificationEntity) => row.id,
      cell: (row: ICertificationEntity) => (<span className="blockquote small m-0 p-0" title={'#' + row?.id.toString()}><b>#</b><small><u>{row.id}</u></small></span>),
      grow: 1,
      omit: true,
    },
    {
      name: 'Codice fiscale',
      selector: (row: ICertificationEntity) => row.codiceFiscale,
      grow: 2,
    },
    {
      name: 'Oggetto',
      selector: (row: ICertificationEntity) =>row.idOggetto,
      grow: 1,
    },
    {
      name: 'Tipologia',
      selector: (row: ICertificationEntity) =>row.idTipologia,
      grow: 1,
    },
    {
      name: 'Note',
      selector: (row: ICertificationEntity) =>row.noteRichiedente,
      wrap: true,
      grow: 2,
    },
    {
      name: 'CFP',
      selector: (row: ICertificationEntity) => (((row.idOggetto === 2 && row.idTipologia === 0) 
      || (row.idOggetto === 3 && row.idTipologia === 6) 
      || (row.idOggetto === 4 && row.idTipologia === 0) 
      || (row.idOggetto === 4 && row.idTipologia === 1)) ? "L.G./Aut." : (row.cfpDichiarati + " / " + row.cfpAssegnati)),
      grow: 1,
    },
    {
      name: 'Delibera',
      selector: (row: ICertificationEntity) => (row.delibera ? row.delibera : ""),
      grow: 2,
    },
    {
      name: 'Periodo rif.',
      selector: (row: ICertificationEntity) => (row.dataRiferimentoDa ? format(new Date(row.dataRiferimentoDa), 'dd/MM/yyyy') : "") 
      + " - " + (row.dataRiferimentoA ? format(new Date(row.dataRiferimentoA), 'dd/MM/yyyy') : ""),
      grow: 2,
    },
  ] as TableColumn<ICertificationEntity>[];

  return (
    <Fragment>
      <Container fluid as="section">        
        <Row className="mb-3">
          {((props.validati? props.validati.length > 0 : false) || (props.scarti ? props.scarti.length > 0 : false))
           ? ( <Fragment>
                  <p>certificazioni validate: {props.validati?.length || 0}</p>
                  <p>certificazioni scartate: {props.scarti?.length || 0}</p>
                </Fragment>
              ) : 
                (<Fragment>
                  <p>Non è stato possibile leggere il file da importare, controllare il formato</p>
                </Fragment>)}
        </Row>
        <Row className="text-center text-warning">
          <h3>Lista certificazioni scartate:</h3>
        </Row>
        <Row className="mb-3">
          <Col>
            <DataTable columns={columns} data={certificationsList} pagination={false}
              responsive striped dense progressPending={isLoading}              
              noDataComponent="Non ci sono scarti da visualizzare"
            />
          </Col>
        </Row>
      </Container>

    </Fragment>
  );
};

export default CertificationListImportResults;