import { Formik } from 'formik';
import React, { FC, Fragment, useEffect, useState, useRef  } from 'react';
import { Accordion, Alert, Button, Card, Col, Form, InputGroup, Modal, Row, Spinner, Tab, Tabs,  } from 'react-bootstrap';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import styled from 'styled-components';
import { Asterisk, CheckCircleFill } from 'react-bootstrap-icons';
import { AiOutlineCopy } from "react-icons/ai";
import { MdUpload } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
// import { subMonths } from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
// import { format } from 'date-fns';
import it from "date-fns/locale/it";
import { ICompanyEntity } from '../../entities/company.entity';
import { ICourseEntityRaw } from '../../entities/course.entity';
import { IMetadataOption } from '../../entities/metadata.entity';
import { IMetadataOptionNode } from '../../entities/metadata.entity';
import { IUser } from '../../entities/user.entity';
import { UserRole } from '../../enums/user-roles.enum';
import { getCompaniesOrder, getCompaniesPartner } from '../../redux/slices/companies.slice';
import { clearCourseDetail, getCourseDetail, IGetCourseDetailThunkPayload } from '../../redux/slices/course.slice';
import { getCompanies, getCompany } from '../../redux/slices/company.slice';
import { getMetadataCourse } from '../../redux/slices/metadata.slice';
import { AppDispatch, RootState } from '../../redux/store';
import { linkMoodleBaseUrl } from '../../utils/course-default';
import MyRichTextEditor from '../shared/MyRichTextEditor';
import AttachmentForm from './AttachmentForm';
import CourseAttachmentList from './CourseAttachmentList';
import CourseSubscriptionList from './CourseSubscriptionList';
import { FaInfoCircle, FaCalendarAlt } from 'react-icons/fa';
import { getEnteTerzoAbbinato } from '../shared/dropdown/EnteTerzoAbbinato';
import { BsArrowRight } from 'react-icons/bs';
import CourseDetailAttachmentList from '../shared/CourseDetailAttachmentList';
import { getLineeGuida } from '../../redux/slices/lineeguida.slice';
import { ILineaGuidaEntity } from '../../entities/lineeguida.entity';
// import { clear } from 'console';
// import { getValue } from '@testing-library/user-event/dist/utils';
// import DatePickerCustomStyles from '../shared/DatePickerCustomStyles';
//import CourseTeacherList from './CourseTeacherList';

registerLocale('it', it);

interface IProps {
    course: ICourseEntityRaw;
    isLoading: boolean;
    submitted: boolean;
    readonly?: boolean;
    onSave: (values: ICourseEntityRaw) => void;
}

const DateInputWrapper = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
`;

const StyledInput = styled.input`
    flex: 1;
    padding: 10px;
    padding-right: 40px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
`;

const CalendarIcon = styled(FaCalendarAlt)`
    position: absolute;
    right: 10px;
    cursor: pointer;
    color: #555;
`;

// Utilizza React.forwardRef per consentire l'utilizzo di ref
const CustomInput = React.forwardRef(({ value, onClick, placeholder }: any, ref) => (
    <DateInputWrapper onClick={onClick}>
        <StyledInput ref={ref} value={value || ''} placeholder={placeholder} readOnly />
        <CalendarIcon onClick={onClick} />
    </DateInputWrapper>
));

CustomInput.displayName = "CustomInput";

const  CourseForm: FC<IProps> = (props) => {
    const dispatch = useDispatch<AppDispatch>();

    const [tabKey, setTabKey] = useState('generale');
    const [showAttachmentModal, setShowAttachmentModal] = useState(false);
    const [showUpdateMessageModal, setShowUpdateMessageModal] = useState(false);
    const [showModalApprove, setShowModalApprove] = useState(false);
    //nuovo inserimento
    const [isDisabledTab,] = useState(props.course.id === 0);
    //verifico se è un corso in replica
    const [isReplicateCourse,] = useState<boolean>(props.course.idCorsoRiferimento != null && props.course.idCorsoRiferimento > 0);
    // serve per verificare se è un corso in replica legacy
    const [isReplicateLegacy, setIsReplicateLegacy] = useState<boolean | null>(null);
    //è un corso inserito da un Partner
    const [isCoursePartner,] = useState<boolean>(props.course.idAzienda !== null && props.course.idAziendaProponente !== null && props.course.idAzienda !== props.course.idAziendaProponente);

    const handleOpenAttachmentModal = () => setShowAttachmentModal(true);
    const handleCloseAttachmentModal = () => setShowAttachmentModal(false);
    const handleOpenUpdateMessageModal = () => setShowUpdateMessageModal(true);
    // let handleCloseUpdateMessageModal = () => setShowUpdateMessageModal(false);
    const [pendingSaveValue, setPendingSaveValue] = useState<ICourseEntityRaw | null>(null);
    const isMounted = useRef(true); // Variabile di controllo montaggio

    const [trienniSelezionabili, setTrienniSelezionabili] = useState<Array<IMetadataOptionNode>>([]);
    const trienni: Array<IMetadataOptionNode> = useSelector((s: RootState) => s.metadata.course.trienni);    
    const [dataInizioTriennio, setDataInizioTriennio] = useState<string>("");
    const [daSbiancare, setDaSbiancare] = useState<boolean>(false);
    const corsoPadre: ICourseEntityRaw | null = useSelector((s: RootState) => s.course.detail);
    const [cfpCorsoPadre, setCfpCorsoPadre] = useState<number | null>(null);

    const user: IUser | null = useSelector((s: RootState) => s.auth.user?.value ? s.auth.user.value : null);
    const companies: ICompanyEntity[] = useSelector((s: RootState) => s.company.joinorders);
    const company: ICompanyEntity | null = useSelector((s: RootState) => s.company.value);
    const partners: Array<ICompanyEntity> = useSelector((s: RootState) => s.companies.partners);
    const allcompanies: ICompanyEntity[] = useSelector((s: RootState) => s.companies.orders);

    const metaAccessibilitaCorso: Array<IMetadataOption> = useSelector((s: RootState) => s.metadata.course.accessibilita_corso);

    const metaAreaFormativaCorso: Array<IMetadataOptionNode> = useSelector((s: RootState) => s.metadata.course.area_formativa_corso);
    const [metaAreaFormativaCorsoLG, setMetaAreaFormativaCorsoLG] = useState<Array<IMetadataOptionNode>>([]);

    const metaErogazioneCorso: Array<IMetadataOption> = useSelector((s: RootState) => s.metadata.course.erogazione_corso);
    const metaStatoCorso: Array<IMetadataOption> = useSelector((s: RootState) => s.metadata.course.stato_corso);
    const metaStatoPubblicazioneCorso: Array<IMetadataOption> = useSelector((s: RootState) => s.metadata.course.stato_pubblicazione_corso);

    const metaTipologiaCorso: Array<IMetadataOptionNode> = useSelector((s: RootState) => s.metadata.course.tipologia_corso);
    const [metaTipologiaCorsoLG, setMetaTipologiaCorsoLG] = useState<Array<IMetadataOptionNode>>([]);

    const [lineaGuida, setlineaGuida] = useState<number>(0);
    const [dataInizioInserita, setDataInizioInserita] = useState<boolean>(false);
    const [cfpModificati, setCfpModificati] = useState<boolean>(false);

    const [dataMinimaInizioCorso, setDataMinimaInizioCorso] = useState<string>((new Date("2023-01-01")).toISOString().slice(0, 16));
    const [dataMassimaInizioCorso, setDataMassimaInizioCorso] = useState<string>((new Date("2025-12-31")).toISOString().slice(0, 16));
    const [dataMassimafineCorso, setDataMassimaFineCorso] = useState<string>((new Date("2025-12-31")).toISOString().slice(0, 16));

    const [dataFineCorsoModificabile, setDataFineCorsoModificabile] = useState<boolean>(false);
    const [dataFineCorsoOriginale, setDataFineCorsoOriginale] = useState<string>("");

    const [isCorsoLegacy, setIsCorsoLegacy] = useState<boolean>(false);

    // Gestore per chiudere la modale e salvare il corso
    const handleCloseUpdateMessageModal = async () => {
        setShowUpdateMessageModal(false);
        if (pendingSaveValue && isMounted.current) {
            setCfpModificati(false);         // Reset dello stato
            await props.onSave(pendingSaveValue);  // Salvataggio effettivo
            setPendingSaveValue(null);       // Reset del valore pendente
        }
    };

    useEffect(() => {
        // console.log("varie variabili:" , dataInizioInserita, isDisabledTab, isReplicateCourse, isCoursePartner);
        isMounted.current = true;
        return () => {
            isMounted.current = false; // Cleanup durante lo smontaggio
        };
    }, []);
    
    const lineeGuida: Array<ILineaGuidaEntity> = useSelector((s: RootState) => s.lineeGuida.lineeGuida);

    useEffect(() => {
        if (!props.course || !lineeGuida || lineeGuida.length === 0 || dataInizioTriennio === "") {
            console.log("dati non pronti", props.course, lineeGuida);
            // Se i dati non sono pronti, non fare nulla
            return;
        } else {
            // console.log("data inizio:", props.course.dataInizio ? props.course.dataInizio : "nulla");
            // console.log(lineeGuida);
            if (props.course.dataInizio && dataInizioTriennio !== "") {
                // console.log("dati pronti e corso presente");
                console.log(props.course);
                setDataInizioInserita(true);
                setIsCorsoLegacy(props.course.dataInizio.slice(0, 10) < dataInizioTriennio.slice(0, 10));
                const selectedDate = new Date(props.course.dataInizio.toString());
                const lg = lineeGuida.filter((item) => (new Date(item.inizioValidita)) < selectedDate && 
                ((item.fineValidita === null) || (new Date(item.fineValidita)) > selectedDate)).pop()?.id || 0;

                setlineaGuida(lg);
                // se il corso è in stato pubblicato, la data di fine può essere procrastinata -> segno la data di fine che diventerà il minimo possibile
                // if ((props.course.idStatoPubblicazione === 3 && user?.role === UserRole.Admin) || props.course.idStatoPubblicazione < 3) {
                if (((props.course.idStatoPubblicazione === 3 && user?.role === UserRole.Admin) || props.course.idStatoPubblicazione < 3) &&
                    (props.course.dataInizio !== "") && (props.course.dataInizio.slice(0, 10) > dataInizioTriennio.slice(0, 10))) {

                    console.log("corso non pubblicato o pubblicato con nuove linee guida ma ruolo admin -> data fine modificabile: ");
                    setDataFineCorsoModificabile(true);
                    if (props.course.dataFine) {
                        setDataFineCorsoOriginale((new Date(props.course.dataFine)).toISOString().slice(0, 16));
                    } else {
                        setDataFineCorsoOriginale((new Date(props.course.dataInizio)).toISOString().slice(0, 16));
                    }
                }

            }            
        }        
        // console.log("linea guida selezionata:", lg);        
    }, [props.course, lineeGuida, dataInizioTriennio]);

    useEffect(() => {
        // console.log(props.course);
        if (props.course.idCorsoRiferimento && props.course.idCorsoRiferimento > 0) {
            dispatch(getCourseDetail({ id: props.course.idCorsoRiferimento } as IGetCourseDetailThunkPayload))
                .unwrap()
                .then()
                .catch()
                .finally();
        } else {
            dispatch(clearCourseDetail());    // RESETTA IL CORSO PADRE SE NON C'è
            setIsReplicateLegacy(false)
        }
    }, [props.course]);

    useEffect(() => {
        if (corsoPadre && corsoPadre.id > 0 && dataInizioTriennio !== "") {
            console.log("corso padre:", corsoPadre, " data inizio triennio:", dataInizioTriennio);
            setCfpCorsoPadre(corsoPadre.cpfAssegnati);
            // console.log(corsoPadre.dataInizio);
            let dataCorsoPadre= new Date(corsoPadre.dataInizio);
            let dataLimite = new Date("2024-01-01");
            // let dataLimite = new Date(dataInizioTriennio);
            const lgCorsoPadre = lineeGuida.filter((item) => (new Date(item.inizioValidita)) < dataCorsoPadre && 
                ((item.fineValidita === null) || (new Date(item.fineValidita)) > dataCorsoPadre)).pop()?.id || 0;
            // rivedere il controllo estraendo la data di inizio triennio
            if (dataCorsoPadre.getTime() < dataLimite.getTime()) {
                // const inizioDelTriennio = (new Date("2023-01-01")).toISOString().slice(0, 16);
                setDataMinimaInizioCorso(dataInizioTriennio);
                setIsReplicateLegacy(true);
                console.log("replica legacy");
                if (props.course.dataInizio === "") {
                    setMetaTipologiaCorsoLG([]);
                    setMetaAreaFormativaCorsoLG([]);
                    setDataInizioInserita(false);
                }
            } else {
                // impostare la data minima di inizio corso alla data di inizio del corso padre
                setlineaGuida(lgCorsoPadre);
                setDataMinimaInizioCorso((new Date(corsoPadre.dataInizio.slice(0,10))).toISOString().slice(0, 16));
                console.log("data minima inizio corso:", (new Date(corsoPadre.dataInizio.slice(0,10))).toISOString().slice(0, 16));
                setIsReplicateLegacy(false);
            }
        }
    }, [corsoPadre, dataInizioTriennio]);

    // const metaArticolazioneCorso: Array<IMetadataOption> = useSelector((s: RootState) => s.metadata.course.articolazione_corso);

    //const metaTipoCorsoAllegato: Array<IMetadataOption> = useSelector((s: RootState) => s.metadata.course.tipo_corso_allegato);
    //const metaQualificaDocente: Array<IMetadataOption> = useSelector((s: RootState) => s.metadata.teacher.qualifica_docente);
    //const metaSituazioneCvDocente: Array<IMetadataOption> = useSelector((s: RootState) => s.metadata.teacher.situazione_cv_docente);
    
    const handleValidaOre = (ore: number, tip: number): number => {
        if(!(tip === 3 || tip === 1 || tip === 10 || tip === 5 || tip === 6)) {
            return 0;
        }

        const min = 1;
        const max = tip === 3 ? 8 : 30;
       
        if(ore >= min && ore <= max) {
            return ore;
        }
        else if(ore > max) {
            return max;
        }

        return min;
    }

    useEffect(() => {
        dispatch(getCompany({}));
        dispatch(getCompanies({}));
        dispatch(getCompaniesPartner({}));
        dispatch(getCompaniesOrder({}));
        dispatch(getMetadataCourse({}));
        dispatch(getLineeGuida({}));
    }, [dispatch]);   

    const schemaModalita = yup.object().shape({
        dettaglioModalitaIscrizione: yup.string().max(400, "Massimo caratteri consentiti 400.").nullable(),
        dataIscrizioneAl: yup.string()
            .nullable()
    });

    const formatDateForInput = (date: string | Date | null) => {
        if (!date) return ""; // Se null o vuoto, restituisce stringa vuota
        const parsedDate = new Date(date);
        return parsedDate.toISOString().slice(0, 16); // Formattazione corretta per datetime-local
    };

    const schemaValutazioneAdmin = yup.object().shape({
        idStato: yup.number().nullable(),
        dettagliStato: yup.string().nullable()
    });

    const schemaValutazionePartner = yup.object().shape({
        idStato: yup.number().required("Campo obbligatorio").moreThan(0, "Scegli stato"),
        dettagliStato: yup.string().required("Campo obbligatorio")
    });

    // const today = new Date().toISOString().slice(0, 16);
    // const sixMonthsAgo = subMonths(new Date(), 6).toISOString().slice(0, 16);
    // const inizioDelTriennio = (new Date("2023-01-01")).toISOString().slice(0, 16);
    // setDataMinimaInizioCorso((new Date("2023-01-01")).toISOString().slice(0, 16));

    useEffect(() => {
        if (trienni && trienni.length > 0) {
            const today = new Date();
            setTrienniSelezionabili(trienni.filter((item) => new Date(item.data) > today));
        }
    }, [trienni]);
    
    useEffect(() => {
        if (trienniSelezionabili && trienniSelezionabili.length > 0) {

            const oggi = new Date();
            const annoCorrente = oggi.getFullYear();

            const triennioCorrente = trienniSelezionabili.find((item) => annoCorrente >= item.minimo && annoCorrente <= item.massimo);

            if (triennioCorrente) {
                // la data minima di inizio corso è la data di inizio del triennio corrente
                const dataInizioTri = (new Date(triennioCorrente.minimo.toString() + "-01-01T02:00:00")).toISOString().slice(0, 16);
                setDataMinimaInizioCorso(dataInizioTri);
                setDataInizioTriennio(dataInizioTri);

                // la data massima di inizio corso è la data di fine dell'ultimo triennio selezionabile.
                // la data massima di fine corso dipende dalla data di inizio inserita ma viene inizializzata alla fine dell'ultimo triennio 
                // selezionabile per evitare che la maschera vada in errore.
                const ultimoTriennio = trienniSelezionabili[trienniSelezionabili.length - 1]; 
                const dataFineUltimoTri = (new Date(ultimoTriennio.massimo.toString() + "-01-01T02:00:00")).toISOString().slice(0, 16);
                setDataMassimaInizioCorso(dataFineUltimoTri);
                setDataMassimaFineCorso(dataFineUltimoTri);
                // console.log("data inizio triennio: ", dataInizioTri);
                // console.log("data fine triennio: ", dataFineUltimoTri);
                // console.log("trienni selezionabili: ", trienniSelezionabili);
            }
        }
    }, [trienniSelezionabili]);

    useEffect(() => {
        // console.log("linea guida:", lineaGuida);
        // console.log("data inizio triennio:", dataInizioTriennio);
        // console.log("data inizio inserita:", dataInizioInserita);
        // console.log("data inizio: ", props.course.dataInizio);
        if (lineaGuida > 0 && dataInizioInserita && dataInizioTriennio !== "") {
            // console.log("linea guida:", lineaGuida);
            // console.log("data inizio triennio:", dataInizioTriennio);
            // console.log("data inizio inserita:", dataInizioInserita);            
            if (((props.course.idStatoPubblicazione === 3 && user?.role === UserRole.Admin) || props.course.idStatoPubblicazione < 3) &&
                (props.course.dataInizio !== "") && (props.course.dataInizio.slice(0, 10) > dataInizioTriennio.slice(0, 10))) {
                setDataFineCorsoModificabile(true);
            }
        }
    }, [dataInizioTriennio, lineaGuida, dataInizioInserita]);

    const limiteData = new Date(2025, 0, 16); // 15 gennaio 2025
    // Function to build the dynamic validation schema for `cfpDichiarati`
    const getCfpValidationSchema = (lineaGuida: number, idTipologia: number, idStatoPubblicazione: number) => {
        // console.log("linea guida:", lineaGuida);
        // console.log("id tipologia:", idTipologia);
        // console.log(pendingSaveValue);
        // console.log("stato del corso: ", idStatoPubblicazione);
        return yup.number().required("Campo obbligatorio")
        .when(['lineaGuida', 'idTipologia'], {
            is: (lineaG: number, id: number) => {
                // console.log("linea guida:", lineaGuida, " id:", id, " lineaG:", lineaG);
                return metaTipologiaCorso.some((tip) => tip.id === id && tip.lg === lineaGuida);
            },
            then: schema => {                
                if (idStatoPubblicazione === 1) {
                    return schema;
                }
                const rule = metaTipologiaCorso.find((tip) => tip.id === idTipologia && tip.lg === lineaGuida);
                // console.log("regola:", rule);

                // Se la data di creazione è prima del 15/01/2025, nessun limite
                if (props.course.dataCreazione && new Date(props.course.dataCreazione) < limiteData) {
                    // console.log("data creazione inferiore a 15/01/2025");
                    setCfpModificati(true);
                    return schema
                    .max(30, ({ max }) => `Errore di validazione! Valore massimo consentito ${max}`);; // Nessun limite applicato
                } else {
                    // console.log("data creazione superiore a 15/01/2025");
                }

                if (rule) {
                    const { minimo, massimo } = rule;

                    // Se massimo.massimo === 0, nessun limite superiore
                    if (massimo === 0) {
                        // console.log("massimo 0");
                        return schema.min(minimo, ({ min }) => `Errore di validazione! Valore minimo consentito ${min}`);
                    } else {
                        // console.log("massimo diverso da 0", massimo);
                        return schema
                            .min(minimo, ({ min }) => `Errore di validazione! Valore minimo consentito ${min}`)
                            .max(massimo, ({ max }) => `Errore di validazione! Valore massimo consentito ${max}`);
                    }
                } else {
                    // console.log("nessuna regola trovata");
                }
                return schema;

                // return rule ? schema.min(rule.minimo, ({ min }) => `Errore di validazione! Valore minimo consentito ${min}`)
                // .max(rule.massimo, ({ max }) => `Errore di validazione! Valore massimo consentito ${max}`) : schema;
            },
            // otherwise: yup.number().max(20, ({ max }) => `Errore di validazione! Valore massimo consentito ${max}`),
        });
    };

    const getDataFineSchema = (dataInizio: string) => {
        return yup
        .date()
        .required("Campo obbligatorio")
        .transform((value, originalValue) => {
            if (originalValue === "") return null; // Se è vuoto, restituisci null  
            return originalValue instanceof Date ? originalValue : new Date(originalValue); // Se non è una data, crea un oggetto Date
        })      
        .test("min-date-with-original", "La data di fine del corso non può essere precedente all'originale", function (value) {
            // console.log("data fine corso originale: ", dataFineCorsoOriginale);
            // console.log("data fco come data: ", new Date(dataFineCorsoOriginale));
            // console.log("value: ", value);
            // console.log("esito: ", (dataFineCorsoOriginale && value && dataFineCorsoOriginale !== "" && dataFineCorsoModificabile) ?
            // value.getTime() > new Date(dataFineCorsoOriginale).getTime() : true);

            return (dataFineCorsoOriginale && value && dataFineCorsoOriginale !== "" && dataFineCorsoModificabile) ?
            value.getTime() > new Date(dataFineCorsoOriginale).getTime() : true;
        })
        .test("min-date", "Data fine deve essere maggiore o uguale alla data di inizio", function (value) {
            // const { dataInizio } = this.parent; // Ottiene la data di inizio corso
            // console.log("dataInizio:", dataInizio);
            // console.log("value: ", value);
            // console.log((dataInizio && value) ? value >= new Date(dataInizio) : true);
            return (dataInizio && value) ? value >= new Date(dataInizio) : true;
        })
        .test('max-date', 'La data di fine corso deve essere minore o uguale alla data di fine ' + 
            ( user?.role === UserRole.Admin ? "del triennio attuale" : "dell'anno" ), function (value) {
            // console.log("data massima fine corso: ", dataMassimafineCorso);
            // console.log("value: ", value);
            if ( user?.role === UserRole.Admin ) {
                // se è una segreteria la data massima è alla fine del triennio
                // if (!value) return true; // Se non è definita, restituisci true
                const annoSelezionato = new Date(dataInizio).getFullYear();
                const triennioSelezionato = trienniSelezionabili.find((item) => annoSelezionato >= item.minimo && annoSelezionato <= item.massimo);
                const dataMFC = triennioSelezionato ? new Date(triennioSelezionato.massimo.toString() + "-12-31T23:59:59") : "";
                // const dataMassimafineCorso = (new Date(dataMFC.toString() + "-01-01T02:00:00")).toISOString().slice(0, 16);
                // console.log("dataMFC: ", dataMFC);
                // console.log("check data massima fine corso: ", value ? value <= new Date(dataMFC) : true);
                return (value ? value <= new Date(dataMFC) : true);
            } else {
                // se è un partner la data massima è la data di fine dell'anno
                // console.log("sono un partner");
                if (value) {
                    const oggi = new Date(dataInizio);
                    const endOfYear = new Date(oggi.getFullYear(), 11, 31, 23, 59, 59);
                    return value <= endOfYear;
                }
                return true;
            }
        })
        .typeError("Inserire una data valida")
    };

    // useEffect(() => {
    //     console.log(dataMinimaInizioCorso);
    // }, [dataMinimaInizioCorso]);

    // inizioDelTriennio  vs dataMinimaInizioCorso

    let schema = yup.object().shape({
        idAziendaProponente: yup.number().required("Campo obbligatorio").moreThan(0, "Campo obbligatorio"),
        dataInizio: yup.date().required("Campo obbligatorio")
            .min(dataMinimaInizioCorso, "Data inizio deve essere maggiore o uguale alla data di inizio del triennio attuale o della data di inizio del corso padre se in replica")
            .max(dataMassimaInizioCorso, "Data inizio deve essere minore o uguale alla data di fine dell'ultimo triennio attivo"),
        // dataFine: yup.date().required("Campo obbligatorio")
        //     .min(yup.ref('dataInizio'), "Data fine deve essere maggiore o uguale alla data di inizio")
        //     .max(dataMassimafineCorso, "Data fine deve essere minore o uguale alla data di fine del triennio attuale"),
        dataFine: yup.lazy((value, { parent }) => getDataFineSchema(parent.dataInizio)),
        idAreaFormativa: yup.number().required("Campo obbligatorio").moreThan(0, "Campo obbligatorio"),
        idErogazione: yup.number().required("Campo obbligatorio").moreThan(0, "Campo obbligatorio"),
        idTipologia: yup.number().required("Campo obbligatorio").moreThan(0, "Campo obbligatorio"),
        nPartecipantiMax: yup.number().required("Valorizzare il campo"),
        nPartecipantiExt: yup.number().required("Valorizzare il campo").when("nPartecipantiMax", (maxi, schem) => schem.max(maxi, `Max quota esterni: ${maxi}`)),
        titolo: yup.string().required("Campo obbligatorio").min(1, "Campo obbligatorio").max(200, "Raggiunta lunghezza massima consentita di 200 caratteri"),
        cpfRichiesti: yup.lazy((value, {parent}) => getCfpValidationSchema(lineaGuida, parent.idTipologia, parent.idStatoPubblicazione)),
        cpfAssegnati:  
            (user?.role === UserRole.Admin ?
                yup.number().typeError("Deve essere un numero valido").required("Campo obbligatorio")
                .test(
                    'cpf-range',
                    function (value) {
                        if (value === 0 && this.parent.idStatoPubblicazione === 1) {
                            return true;
                        }
                        if (value == null) {
                            return this.createError({ message: "Campo obbligatorio" });
                        }

                        const parsedValue = Number(value); // Converte il valore in numero
                        if (isNaN(parsedValue)) {
                            return this.createError({ message: "Il valore deve essere un numero valido." });
                        }

                        const { idTipologia } = this.parent;
                        const rule = metaTipologiaCorso.find((tip) => tip.id === idTipologia && tip.lg === lineaGuida);
                        const minimo = rule?.minimo ?? 0;
                        const massimo = (rule?.massimo && rule.massimo > 0) ? rule.massimo : Infinity;

                        if (parsedValue < minimo || parsedValue > massimo) {
                            return this.createError({ 
                                message: `Il valore deve essere compreso tra ${minimo} e ${massimo}.` 
                            });
                        }

                        return true;

                    }
                )
                : yup.number()),
        moodleCourseId: yup.string().nullable(),
        valutazione:  (user?.role === UserRole.Admin ? schemaValutazioneAdmin : schemaValutazionePartner),
        modalita: schemaModalita
    });

    const handleSelect = (key: string | null) => {
        if (key == null) {
            key = 'generale';
        }
        setTabKey(key);
    }

    const buttonGoToTabHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        const button: HTMLButtonElement = event.currentTarget;
        setTabKey(button.name);
    }

    const handleSaveCourse = (values: ICourseEntityRaw) => {
        props.onSave(values);
    }

    const handleSubmit = async (value: ICourseEntityRaw) => {
        try {
            await schema.validate(value);
        } catch (err) {
            console.log("Schema non valido: ", err);
            return; // Interrompi il flusso se la validazione fallisce
        }
        
        console.log(value);
        // value.lineaGuidaId = lineaGuida;
        const updatedValue = { ...value, lineaGuidaId: lineaGuida } as ICourseEntityRaw;
        console.log(updatedValue);
        if (cfpModificati) {
            console.log("cfp modificati, mostro modale");

            setPendingSaveValue(updatedValue); // Salvataggio rimandato dopo la chiusura
            setShowUpdateMessageModal(true);

            //  // Imposta un listener per eseguire l'operazione dopo la chiusura
            // const handleCloseAndSave = () => {
            //     setShowUpdateMessageModal(false); 
            //     setCfpModificati(false); 
            //     props.onSave(value);  // Salvataggio eseguito solo dopo la chiusura della modale
            // };

            // // Associa il listener al gestore della modale
            // handleCloseUpdateMessageModal = handleCloseAndSave;
            // setCfpModificati(false);
        } else {
            console.log("cfp non modificati, proseguo");
            props.onSave(value);
        }
        // console.log(value);
        
    }

    const validateField = (name: string, value: any) => {
        schema.validateAt(name, { [name]: value }).catch((err) => {
            console.log(err);
        });
    }

    const handleApprove = (val: ICourseEntityRaw) => {
        const thecourse: ICourseEntityRaw = {
            ...val,
            cpfAssegnati: val.cpfRichiesti,
            idStatoPubblicazione: 3,
            lineaGuidaId: lineaGuida,
        } as ICourseEntityRaw;
        if (cfpModificati) {
            // console.log("cfp modificati, mostro modale");
            handleOpenUpdateMessageModal();
            setCfpModificati(false);
        } else {
            // console.log("cfp non modificati, proseguo");
            props.onSave(thecourse);
        }
        // props.onSave(thecourse);
    }

    const handleClose = () => setShowModalApprove(false);
    const handleShow = () => setShowModalApprove(true);

    const handleGenericChange = (e: React.ChangeEvent<HTMLInputElement>,  
        setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void,
        setFieldTouched: (field: string, isTouched?: boolean, shouldValidate?: boolean) => void,
        handleChange: (e: React.ChangeEvent<any>) => void) => {    
            // console.log("handleGenericChange custom:", e.target); 
            const { name, value } = e.target;
            if (name === "idTipologia") {
                setFieldValue('cpfRichiesti', 0);
                setFieldValue('cpfAssegnati', 0);
                setFieldTouched('cpfRichiesti', true, true);
                setFieldTouched('cpfAssegnati', true, true);
            }
            setFieldValue(name, value);
            setFieldTouched(name, true, true); // Segna il campo come toccato e attiva la validazione         
            handleChange(e); // Gestisce anche gli eventi di cambiamento Formik
    }
    const timeZone = 'Europe/Rome'; // Definisci il fuso orario desiderato

    const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>,  
        setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void,
        setFieldTouched: (field: string, isTouched?: boolean, shouldValidate?: boolean) => void,
        handleChange: (e: React.ChangeEvent<any>) => void) => {
            // console.log("handleDateChange custom:", e.target); 
            // console.log("linea guida di provenienza: ", lineaGuida);
            const { name, value } = e.target;
            setFieldValue(name, value); // Aggiorna il valore del campo con Formik
            if (name === "dataInizio") {
                // console.log("data inizio:", value);
                // console.log("inizio del triennio:", dataInizioTriennio);
                const dataSelezionata = new Date(value + ":00");
                const inizioTriennio = new Date(dataInizioTriennio);

                if (value >= dataMinimaInizioCorso.slice(0, 10)) {
                    // if (value >= inizioDelTriennio.slice(0, 10)) {
                    // console.log("data inizio inserita");
                    setDataInizioInserita(true);
                    setDataFineCorsoModificabile(true);
                } else if (!isReplicateCourse || (isReplicateCourse && isReplicateLegacy)) {
                    // console.log("cancello!!!!");
                    setMetaTipologiaCorsoLG([]);
                    setMetaAreaFormativaCorsoLG([]);
                    setDataInizioInserita(false);
                }

                const selectedDate = new Date(value);
                const lg = lineeGuida.filter((item) => (new Date(item.inizioValidita)) < selectedDate && 
                ((item.fineValidita === null) || (new Date(item.fineValidita)) > selectedDate)).pop()?.id || 0;

                // console.log("linea guida selezionata:", lg);
                if (lineaGuida != lg) {
                // if (lineaGuida != lg && (!isReplicateCourse || (isReplicateCourse && isReplicateLegacy))) {
                    // azzero i campi se cambia la linea guida oppure se è un corso in replica legacy
                    // chiedere a Federico la conferma!!!!
                    setFieldValue('cpfRichiesti', 0);
                    setFieldValue('idTipologia', 0); 
                    setDaSbiancare(true);
                    setlineaGuida(lg);
                }                
            }     
            
            setFieldTouched(name, true, true); // Segna il campo come toccato e attiva la validazione
            handleChange(e); // Gestisce anche gli eventi di cambiamento Formik       
    };

    const handleEventoGratuitoChange = (e: React.ChangeEvent<HTMLInputElement>,  
        setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void,
        setFieldTouched: (field: string, isTouched?: boolean, shouldValidate?: boolean) => void,
        handleChange: (e: React.ChangeEvent<any>) => void) => {    
            console.log("handleEventoGratuitoChange custom:", e.target.value); 
            const { name, value } = e.target;
            setFieldValue(name, value); // Aggiorna il valore del campo con Formik
            setFieldTouched(name, true, true); // Segna il campo come toccato e attiva la validazione
            handleChange(e); // Gestisce anche gli eventi di cambiamento Formik
    }

    useEffect(() => {        
        // console.log("dataInizioInserita:", dataInizioInserita);
        // console.log("lineaGuida:", lineaGuida, "dataInizioInserita:", dataInizioInserita, "isReplicateCourse:", isReplicateCourse, "isReplicateLegacy:", isReplicateLegacy);

        if (metaTipologiaCorso.length > 0 && isReplicateLegacy != null) {
            // console.log("useEffect particolare: ", lineaGuida, dataInizioInserita, isReplicateCourse, isReplicateLegacy);            
            if (isReplicateCourse && !isReplicateLegacy) {
                console.log("corso in replica regular: ", props.course.idTipologia);
                setMetaTipologiaCorsoLG(metaTipologiaCorso.filter(item => item.id === props.course.idTipologia));
            } else if ((!isReplicateCourse || (isReplicateCourse && isReplicateLegacy)) && dataInizioInserita && lineaGuida > 0) {   
                console.log("corso non in replica o replica legacy ma con data inserita: ");
                const filteredData = metaTipologiaCorso.filter(item => item.lg === lineaGuida);
                setMetaTipologiaCorsoLG(filteredData);
            } else if (daSbiancare) {
                console.log("corso non in replica o replica legacy ma da sbiancare: ");    
                setMetaTipologiaCorsoLG([]);
                setDaSbiancare(false);
            } else {
                // console.log("alternativa uno");
            }
        } else {
            // console.log("alternativa due");
        }
        
    }, [lineaGuida, dataInizioInserita, metaTipologiaCorso, isReplicateLegacy]);

    useEffect(() => {
        if (metaAreaFormativaCorso.length > 0 && isReplicateCourse && !isReplicateLegacy) {
            // if (metaAreaFormativaCorso.length > 0 && isReplicateCourse) {
            setMetaAreaFormativaCorsoLG(metaAreaFormativaCorso.filter(item => item.id === props.course.idAreaFormativa));
        } else if (metaAreaFormativaCorso.length > 0 && dataInizioInserita) {   //lineaGuidaCorrente && lineaGuida >= lineaGuidaCorrente) {
            const filteredData = metaAreaFormativaCorso.filter(item => item.lg === lineaGuida);       
            setMetaAreaFormativaCorsoLG(filteredData);
            // console.log(filteredData);
        } else {
            setMetaAreaFormativaCorsoLG([]);
            // console.log("metaAreaFormativaCorsoLG svuotato");
        }
        
    }, [lineaGuida, dataInizioInserita, metaAreaFormativaCorso]);

    return (
        <Fragment>
            {props.submitted ? (
                <div className="text-center">
                    <CheckCircleFill size={100} className="text-success mb-5" />
                    <h3 className="text-success">Ben fatto!</h3>
                    <p className="mt-3">Operazione completata.</p>
                </div>
            ) : (
                <Formik initialValues={props.course} validationSchema={schema} 
                    onSubmit={handleSubmit} 
                    validateOnChange={true} validateOnBlur={true}
                >
                    {({ handleSubmit, handleChange, handleBlur, values, touched, isValid, errors, setFieldValue, setFieldTouched }) => {
                        // console.log('Errors:', errors);
                        // console.log('Touched:', touched);
                        // console.log('Values:', values);

                        // if (touched?.idErogazione && touched.idErogazione) {
                        //     console.log(values.idErogazione);
                            // if (errors?.dataInizio && errors.dataInizio.length > 0) {
                            //     setDataInizioInserita(false);
                            // } else {
                            //     setDataInizioInserita(true);
                            // }
                        // }

                        return (
                        <>
                            <Form noValidate onSubmit={handleSubmit}>
                                <Tabs activeKey={tabKey} onSelect={handleSelect}>

                                    {
                                        // DATI GENERALI
                                    }

                                    <Tab eventKey="generale" title="Dati generali" className="bg-light p-3">
                                        <h4 className="mb-3">Dati proponente</h4>
                                        <Form.Group as={Row} className={`mb-3 ${company == null ? 'd-none' : ''}`}>
                                            <Form.Label as={Col} lg={2} md={4} sm={12}>Soggetto proponente</Form.Label>
                                            <Col lg={6} md={8} sm={12}>
                                                {
                                                    props.readonly ? (
                                                        <b>{isCoursePartner ? getEnteTerzoAbbinato(props.course.idAzienda, partners) : getEnteTerzoAbbinato(props.course.idAzienda, allcompanies)}</b>
                                                    ) : (
                                                        <b>{isDisabledTab ? company?.nome : (isCoursePartner ? getEnteTerzoAbbinato(props.course.idAzienda, partners) : company?.nome)}</b>
                                                    )
                                                }
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className={`mb-3 ${companies.length === 1 ? 'd-none' : ''}`} controlId="idAziendaProponenteValidation">
                                            <Form.Label as={Col} lg={2} md={4} sm={12}>Autorizzato da<Asterisk size={8} className="mb-3" /></Form.Label>
                                            <Col lg={6} md={8} sm={12}>
                                                <Form.Control
                                                    as="select"
                                                    onChange={handleChange}
                                                    name="idAziendaProponente"
                                                    value={values.idAziendaProponente}
                                                    isInvalid={!!touched.idAziendaProponente && !!errors.idAziendaProponente}
                                                    disabled={props.isLoading || isReplicateCourse || props.course.idStatoPubblicazione > 2}>
                                                    <option key={"idAziendaProponente0"} value={0}></option>
                                                    {companies.length && (
                                                        companies.map((item: ICompanyEntity, index: number) => {
                                                            return (<option key={"idAziendaProponente" + item.idAzienda} value={item.idAzienda}>{item.nome}</option>);
                                                        })
                                                    )}
                                                </Form.Control>
                                                <Form.Control.Feedback type="invalid">{errors.idAziendaProponente}</Form.Control.Feedback>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className="mb-3" controlId="mailInformazioniValidation">
                                            <Form.Label as={Col} lg={2} md={4} sm={12}>Mail informazioni</Form.Label>
                                            <Col lg={6} md={8} sm={12}>
                                                <Form.Control
                                                    as="input"
                                                    type="email"
                                                    onChange={(e) => handleGenericChange(e as React.ChangeEvent<HTMLInputElement>, setFieldValue, setFieldTouched, handleChange)}
                                                    disabled={props.isLoading || isReplicateCourse || props.course.idStatoPubblicazione > 2}
                                                    name="mailInformazioni" value={values?.mailInformazioni || ""}
                                                    isInvalid={!!touched.mailInformazioni && !!errors.mailInformazioni} />
                                                <Form.Control.Feedback type="invalid">{errors.mailInformazioni}</Form.Control.Feedback>
                                                <Form.Text>Questa mail verrà pubblicata allo scopo di fornire assistenza agli interessati all'evento.</Form.Text>
                                            </Col>
                                        </Form.Group>
                                        <hr />
                                        <h4 className="mb-3">Dati evento</h4>
                                        <p><b>La data di inizio svolgimento determina la Linea Guida di riferimento e di conseguenza i valori inseribili. Ogni sua variazione comporta il ricalcolo della tipologia di evento, dell'area formativa e dei relativi vincoli sui CFP.</b></p>
                                        <Form.Group as={Row} className="mb-3" controlId="svolgimentoValidation">
                                            <Form.Text>&nbsp;</Form.Text>
                                            <Form.Label as={Col} lg={2} md={4} sm={12}>Svolgimento<Asterisk size={8} className="mb-3" /></Form.Label>
                                            <Col lg={2} md={3} sm={12}>
                                                <Form.Text>Inizio</Form.Text>                                                
                                                <Form.Control type="date"                                                 
                                                // <Form.Control type="datetime-local" 
                                                // onChange={handleChange}
                                                    name="dataInizio" value={values.dataInizio ? values.dataInizio.slice(0, 16) : ""}
                                                    isInvalid={!!touched.dataInizio && !!errors.dataInizio}
                                                    placeholder="Data inizio" disabled={props.isLoading || values.flagNonSvolto || props.course.idStatoPubblicazione > 2}
                                                    onChange={(e) => handleDateChange(e as React.ChangeEvent<HTMLInputElement>, setFieldValue, setFieldTouched, handleChange)} // Usa la funzione custom
                                                />

                                                <Form.Text>Inserire una data prima di proseguire.</Form.Text>
                                                <Form.Control.Feedback type="invalid">{errors.dataInizio}</Form.Control.Feedback>
                                            </Col>
                                            <Col lg={2} md={3} sm={12}>
                                                <Form.Text>Fine</Form.Text>
                                                <Form.Control type="date" 
                                                    // onChange={handleChange}
                                                    onChange={(e) => handleDateChange(e as React.ChangeEvent<HTMLInputElement>, setFieldValue, setFieldTouched, handleChange)} // Usa la funzione custom
                                                    name="dataFine" value={values.dataFine ? values.dataFine.slice(0, 16) : ""}
                                                    isInvalid={!!touched.dataFine && !!errors.dataFine}
                                                    placeholder="Data fine" disabled={props.isLoading || values.flagNonSvolto || !dataInizioInserita || !dataFineCorsoModificabile} />
                                                
                                                <Form.Text>Data obbligatoria.</Form.Text>
                                                <Form.Control.Feedback type="invalid">{errors.dataFine}</Form.Control.Feedback>
                                            </Col>
                                            <Col lg={2} md={2} sm={12} >
                                                <Form.Text>&nbsp;</Form.Text>
                                                <Form.Check type="switch">
                                                    <Form.Check.Input onChange={(e) => setFieldValue('flagNonSvolto', e.target.checked)}
                                                        name="flagNonSvolto" defaultChecked={values.flagNonSvolto} disabled={props.isLoading || props.course.idStatoPubblicazione > 2}
                                                        isInvalid={!!touched.flagNonSvolto && !!errors.flagNonSvolto} />
                                                    <Form.Check.Label>Non svolto</Form.Check.Label>
                                                    <Form.Control.Feedback type="invalid">{errors.flagNonSvolto}</Form.Control.Feedback>
                                                </Form.Check>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className="mb-3" controlId="titoloValidation">
                                            <Form.Label as={Col} lg={2} md={4} sm={12}>Titolo <Asterisk size={8} className="mb-3" /></Form.Label>
                                            <Col lg={6} md={8} sm={12}>
                                                <Form.Control as="input" type="text" onChange={handleChange}
                                                    name="titolo" value={values?.titolo || ""}
                                                    isInvalid={!!touched.titolo && !!errors.titolo}
                                                    disabled={props.isLoading || isReplicateCourse || props.course.idStatoPubblicazione > 2} />
                                                <Form.Control.Feedback type="invalid">{errors.titolo}</Form.Control.Feedback>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className="mb-3" controlId="dataIstanzaValidation">
                                            <Form.Label as={Col} lg={2} md={4} sm={12}>Data istanza</Form.Label>
                                            <Col lg={6} md={8} sm={12}>
                                                <Form.Control type="date" onChange={handleChange}
                                                    name="dataIstanza" value={values?.dataIstanza || ""}
                                                    isInvalid={!!touched.dataIstanza && !!errors.dataIstanza}
                                                    disabled={props.isLoading || isReplicateCourse || props.course.idStatoPubblicazione > 2} />
                                                <Form.Control.Feedback type="invalid">{errors.dataIstanza}</Form.Control.Feedback>
                                            </Col>
                                        </Form.Group>
                                        {/* <Form.Group as={Row} className="mb-3" controlId="nCorsoValidation">
                                            <Form.Label as={Col} lg={2} md={4} sm={12}>Numero</Form.Label>
                                            <Col lg={6} md={8} sm={12}>
                                                <Form.Control as="input" type="text" onChange={handleChange}
                                                    name="nCorso" value={values?.nCorso || ""}
                                                    isInvalid={!!touched.nCorso && !!errors.nCorso}
                                                    disabled={props.isLoading || isReplicateCourse || props.course.idStatoPubblicazione > 2} />
                                                <Form.Control.Feedback type="invalid">{errors.nCorso}</Form.Control.Feedback>
                                            </Col>
                                        </Form.Group> */}
                                        <Form.Group as={Row} className="mb-3" controlId="protocolloEntrataValidation">
                                            <Form.Label as={Col} lg={2} md={4} sm={12}>Protocollo entrata</Form.Label>
                                            <Col lg={3} md={4} sm={6}>
                                                <Form.Control as="input" type="text" onChange={handleChange}
                                                    name="nProtIn" value={values?.nProtIn || ""}
                                                    isInvalid={!!touched.nProtIn && !!errors.nProtIn}
                                                    disabled={props.isLoading || isReplicateCourse || props.course.idStatoPubblicazione > 2} placeholder="Numero" />
                                                <Form.Control.Feedback type="invalid">{errors.nProtIn}</Form.Control.Feedback>
                                            </Col>
                                            <Col lg={3} md={4} sm={6}>
                                                <Form.Control type="date" onChange={handleChange}
                                                    name="dataProtIn" value={values?.dataProtIn || ""}
                                                    isInvalid={!!touched.dataProtIn && !!errors.dataProtIn}
                                                    disabled={props.isLoading || isReplicateCourse || props.course.idStatoPubblicazione > 2} placeholder="Data" />
                                                <Form.Control.Feedback type="invalid">{errors.dataProtIn}</Form.Control.Feedback>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className="mb-3" controlId="idErogazioneValidation">
                                            <Form.Label as={Col} lg={2} md={4} sm={12}>Erogazione<Asterisk size={8} className="mb-3" /></Form.Label>
                                            <Col lg={6} md={8} sm={12}>
                                                <Form.Control as="select" 
                                                    // onChange={handleChange} 
                                                    onChange={(e) => handleGenericChange(e as React.ChangeEvent<HTMLInputElement>, setFieldValue, setFieldTouched, handleChange)}
                                                    disabled={props.isLoading || props.course.idStatoPubblicazione > 2}
                                                    name="idErogazione" value={values.idErogazione}
                                                    isInvalid={!!touched.idErogazione && !!errors.idErogazione}>
                                                    <option key={"idErogazione0"} value={0}></option>
                                                    {metaErogazioneCorso.length && (
                                                        metaErogazioneCorso.map((item: IMetadataOption, index: number) => {
                                                            return (<option key={"idErogazione" + item.id} value={item.id}>{item.nome}</option>);
                                                        })
                                                    )}
                                                </Form.Control>
                                                <Form.Control.Feedback type="invalid">{errors.idErogazione}</Form.Control.Feedback>
                                            </Col>
                                        </Form.Group>
                                        {(Number(values.idErogazione) === 3) ? ( 
                                            // il codice moodle si inserisce solo per i corsi in modalità FAD Sincrona
                                         <Form.Group as={Row} className="mb-3" controlId="codiceMoodle">
                                            <Form.Label as={Col} lg={2} md={4} sm={12}>Codice Moodle</Form.Label>
                                            <Col lg={6} md={8} sm={12}>
                                                <InputGroup>
                                                    <Button variant="outline-primary" onClick={() => { navigator.clipboard.writeText(linkMoodleBaseUrl + values?.moodleCourseId) }}>
                                                        <AiOutlineCopy size={22} />
                                                    </Button>
                                                    <InputGroup.Text className="bgCodiceMoodle">{linkMoodleBaseUrl}</InputGroup.Text>
                                                    <Form.Control as="input" type="text" onChange={handleChange}
                                                        name="moodleCourseId" value={values?.moodleCourseId || ""}
                                                        isInvalid={!!touched.moodleCourseId && !!errors.moodleCourseId}
                                                        disabled={props.isLoading || props.course.idStatoPubblicazione > 2} />
                                                </InputGroup>
                                                <Form.Text>Copia link negli appunti</Form.Text>
                                            </Col>
                                        </Form.Group> ) : (
                                                        <Fragment />
                                                    )}
                                        <Form.Group as={Row} className="mb-3" controlId="idTipologiaValidation">
                                            <Form.Label as={Col} lg={2} md={4} sm={12}>Tipologia<Asterisk size={8} className="mb-3" /></Form.Label>
                                            <Col lg={6} md={8} sm={12}>
                                                <Form.Control as="select" 
                                                    onChange={(e) => handleGenericChange(e as React.ChangeEvent<HTMLInputElement>, setFieldValue, setFieldTouched, handleChange)}
                                                    disabled={props.isLoading || (isReplicateCourse && !isReplicateLegacy) || props.course.idStatoPubblicazione > 2}
                                                    name="idTipologia" value={values.idTipologia}
                                                    isInvalid={!!touched.idTipologia && !!errors.idTipologia}>
                                                    <option key={"idTipologia0"} value={0}></option>
                                                    {metaTipologiaCorsoLG.length && (
                                                        metaTipologiaCorsoLG.map((item: IMetadataOption, index: number) => {
                                                            return (<option key={"idTipologia" + item.id} value={item.id}>{item.nome}</option>);
                                                        })
                                                    )}
                                                </Form.Control>
                                                <Form.Control.Feedback type="invalid">{errors.idTipologia}</Form.Control.Feedback>
                                                <Form.Text>Gli eventi svolti nel passato saranno archiviati e non soggetti alla valutazione di conformità.</Form.Text>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className="mb-3" controlId="idAreaFormativaValidation">
                                            <Form.Label as={Col} lg={2} md={4} sm={12}>Categoria / Area formativa <Asterisk size={8} className="mb-3" /></Form.Label>
                                            <Col lg={6} md={8} sm={12}>
                                                <Form.Control as="select" onChange={handleChange} disabled={props.isLoading || (isReplicateCourse && !isReplicateLegacy) || props.course.idStatoPubblicazione > 2}
                                                    name="idAreaFormativa" value={values.idAreaFormativa}
                                                    isInvalid={!!touched.idAreaFormativa && !!errors.idAreaFormativa}>
                                                    <option key={"idAreaFormativa0"} value={0}></option>
                                                    {metaAreaFormativaCorsoLG.length && (
                                                        metaAreaFormativaCorsoLG.map((item: IMetadataOption, index: number) => {
                                                            return (<option key={"idAreaFormativa" + item.id} value={item.id}>{item.nome}</option>)
                                                        })
                                                    )}
                                                </Form.Control>
                                                <Form.Control.Feedback type="invalid">{errors.idAreaFormativa}</Form.Control.Feedback>
                                            </Col>
                                        </Form.Group>
                                        {/* <Form.Group as={Row} className="mb-3" controlId="idArticolazioneCorsoValidation">
                                            <Form.Label as={Col} lg={2} md={4} sm={12}>Articolazione corso/evento</Form.Label>
                                            <Col lg={6} md={8} sm={12}>
                                                <Form.Control as="select" onChange={handleChange} disabled={props.isLoading || isReplicateCourse || props.course.idStatoPubblicazione > 2}
                                                    name="valutazione.idArticolazioneCorso" value={values.valutazione?.idArticolazioneCorso}
                                                    isInvalid={!!touched.valutazione?.idArticolazioneCorso && !!errors.valutazione?.idArticolazioneCorso}>
                                                    <option key={"idArticolazioneCorso0"} value={0}></option>
                                                    {metaArticolazioneCorso.length && (
                                                        metaArticolazioneCorso.map((item: IMetadataOption, index: number) => {
                                                            return (<option key={"idArticolazioneCorso" + item.id} value={item.id}>{item.nome}</option>);
                                                        })
                                                    )}
                                                </Form.Control>
                                                <Form.Control.Feedback type="invalid">{errors.valutazione?.idArticolazioneCorso}</Form.Control.Feedback>
                                            </Col>
                                        </Form.Group> */}
                                        <Form.Group as={Row} className="mb-3" controlId="obiettiviValidation">
                                            <Form.Label as={Col} lg={2} md={4} sm={12}>Descrizione</Form.Label>
                                            <Col lg={6} md={8} sm={12}>
                                                <Form.Control as="textarea" onChange={handleChange}
                                                    name="obiettivi" value={values?.obiettivi || ""}
                                                    isInvalid={!!touched.obiettivi && !!errors.obiettivi}
                                                    disabled={props.isLoading || isReplicateCourse || props.course.idStatoPubblicazione > 2} />
                                                <Form.Control.Feedback type="invalid">{errors.obiettivi}</Form.Control.Feedback>
                                                <Form.Text>Descrivere dettagliatamente gli obiettivi del corso (max 400 caratteri)</Form.Text>
                                            </Col>
                                        </Form.Group><hr />
                                        <h4 className="mb-3">Crediti formativi</h4>
                                        {/* <Accordion className="mb-3">
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Header>Caratteristiche</Accordion.Header>
                                                <Accordion.Body>
                                                <Form.Group as={Row} className="mb-3">
                                                    <Form.Label as={Col} lg={2} md={4} sm={12}></Form.Label>
                                                        <Col lg={6} md={8} sm={12}>
                                                            <Form.Check type="switch" id="valutazione.flagAttestatoPartecipazione">
                                                                <Form.Check.Input disabled={props.isLoading || isReplicateCourse || props.course.idStatoPubblicazione > 2}
                                                                    onChange={(e) => setFieldValue('valutazione.flagAttestatoPartecipazione', e.target.checked)}
                                                                    name="valutazione.flagAttestatoPartecipazione" defaultChecked={values.valutazione?.flagAttestatoPartecipazione}
                                                                    isInvalid={!!touched.valutazione?.flagAttestatoPartecipazione && !!errors.valutazione?.flagAttestatoPartecipazione} 
                                                                />
                                                                <Form.Check.Label>Attestazione di partecipazione</Form.Check.Label>
                                                                <Form.Control.Feedback type="invalid">{errors.valutazione?.flagAttestatoPartecipazione}</Form.Control.Feedback>
                                                            </Form.Check>
                                                            <Form.Check type="switch" id="valutazione.flagGiudizioPerDocente">
                                                                <Form.Check.Input disabled={props.isLoading || isReplicateCourse || props.course.idStatoPubblicazione > 2}
                                                                    onChange={(e) => setFieldValue('valutazione.flagGiudizioPerDocente', e.target.checked)}
                                                                    name="valutazione.flagGiudizioPerDocente" defaultChecked={values.valutazione?.flagGiudizioPerDocente}
                                                                    isInvalid={!!touched.valutazione?.flagGiudizioPerDocente && !!errors.valutazione?.flagGiudizioPerDocente}
                                                                />
                                                                <Form.Check.Label>Giudizio per docente</Form.Check.Label>
                                                                <Form.Control.Feedback type="invalid">{errors.valutazione?.flagGiudizioPerDocente}</Form.Control.Feedback>
                                                            </Form.Check>
                                                            <Form.Check type="switch" id="valutazione.flagGiudizioPerCorso">
                                                                <Form.Check.Input disabled={props.isLoading || isReplicateCourse || props.course.idStatoPubblicazione > 2}
                                                                    onChange={(e) => setFieldValue('valutazione.flagGiudizioPerCorso', e.target.checked)}
                                                                    name="valutazione.flagGiudizioPerCorso" defaultChecked={values.valutazione?.flagGiudizioPerCorso}
                                                                    isInvalid={!!touched.valutazione?.flagGiudizioPerCorso && !!errors.valutazione?.flagGiudizioPerCorso}
                                                                />
                                                                <Form.Check.Label>Giudizio per corso</Form.Check.Label>
                                                                <Form.Control.Feedback type="invalid">{errors.valutazione?.flagGiudizioPerCorso}</Form.Control.Feedback>
                                                            </Form.Check>
                                                        </Col>
                                                    </Form.Group>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion> */}

                                        <Row className="mb-3">
                                            <Col lg={{ span: 6, offset: 2}} md={{ span: 8, offset: 4}} sm={12}> {/* Stessa larghezza del Form.Group */}
                                                <Accordion>
                                                    <Accordion.Item eventKey="0">
                                                        <Accordion.Header>Caratteristiche</Accordion.Header>
                                                        <Accordion.Body>
                                                            <Form.Group as={Row} className="mb-3">
                                                                <Form.Label as={Col} lg={2} md={4} sm={12}></Form.Label>
                                                                <Col lg={6} md={8} sm={12}>
                                                                    <Form.Check type="switch" id="valutazione.flagAttestatoPartecipazione">
                                                                        <Form.Check.Input disabled={props.isLoading || isReplicateCourse || props.course.idStatoPubblicazione > 2}
                                                                            onChange={(e) => setFieldValue('valutazione.flagAttestatoPartecipazione', e.target.checked)}
                                                                            name="valutazione.flagAttestatoPartecipazione"
                                                                            defaultChecked={values.valutazione?.flagAttestatoPartecipazione}
                                                                            isInvalid={!!touched.valutazione?.flagAttestatoPartecipazione && !!errors.valutazione?.flagAttestatoPartecipazione} 
                                                                        />
                                                                        <Form.Check.Label>Attestazione di partecipazione</Form.Check.Label>
                                                                        <Form.Control.Feedback type="invalid">{errors.valutazione?.flagAttestatoPartecipazione}</Form.Control.Feedback>
                                                                    </Form.Check>

                                                                    <Form.Check type="switch" id="valutazione.flagGiudizioPerDocente">
                                                                        <Form.Check.Input disabled={props.isLoading || isReplicateCourse || props.course.idStatoPubblicazione > 2}
                                                                            onChange={(e) => setFieldValue('valutazione.flagGiudizioPerDocente', e.target.checked)}
                                                                            name="valutazione.flagGiudizioPerDocente"
                                                                            defaultChecked={values.valutazione?.flagGiudizioPerDocente}
                                                                            isInvalid={!!touched.valutazione?.flagGiudizioPerDocente && !!errors.valutazione?.flagGiudizioPerDocente}
                                                                        />
                                                                        <Form.Check.Label>Giudizio per docente</Form.Check.Label>
                                                                        <Form.Control.Feedback type="invalid">{errors.valutazione?.flagGiudizioPerDocente}</Form.Control.Feedback>
                                                                    </Form.Check>

                                                                    <Form.Check type="switch" id="valutazione.flagGiudizioPerCorso">
                                                                        <Form.Check.Input disabled={props.isLoading || isReplicateCourse || props.course.idStatoPubblicazione > 2}
                                                                            onChange={(e) => setFieldValue('valutazione.flagGiudizioPerCorso', e.target.checked)}
                                                                            name="valutazione.flagGiudizioPerCorso"
                                                                            defaultChecked={values.valutazione?.flagGiudizioPerCorso}
                                                                            isInvalid={!!touched.valutazione?.flagGiudizioPerCorso && !!errors.valutazione?.flagGiudizioPerCorso}
                                                                        />
                                                                        <Form.Check.Label>Giudizio per corso</Form.Check.Label>
                                                                        <Form.Control.Feedback type="invalid">{errors.valutazione?.flagGiudizioPerCorso}</Form.Control.Feedback>
                                                                    </Form.Check>
                                                                </Col>
                                                            </Form.Group>
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                </Accordion>
                                            </Col>
                                        </Row>




                                        <Form.Group as={Row} className="mb-3" controlId="durataValidation">
                                            <Form.Label as={Col} lg={2} md={4} sm={12}>Durata (ore)</Form.Label>
                                            <Col lg={6} md={8} sm={12}>
                                                <Form.Control type="number"
                                                    onChange={(e) => {
                                                        const ore: number = isNaN(Number(e.target.value)) ? 0 : Number(e.target.value);
                                                        const pubbl: number = isNaN(Number(values.idStatoPubblicazione)) ? 0 : Number(values.idStatoPubblicazione);
                                                        const tipologia: number = isNaN(Number(values.idTipologia)) ? 3 : Number(values.idTipologia);
                                                        setFieldValue('nOre', ore);
                                                        // if(isDisabledTab || pubbl < 3) {
                                                        //     const cfps: number = handleValidaOre(ore, tipologia);
                                                        //     setFieldValue('cpfRichiesti', cfps);
                                                        // }
                                                    }}
                                                    name="nOre" value={values.nOre}
                                                    isInvalid={!!touched.nOre && !!errors.nOre}
                                                    disabled={props.isLoading || isReplicateCourse || props.course.idStatoPubblicazione > 2}
                                                />
                                                <Form.Control.Feedback type="invalid">{errors.nOre}</Form.Control.Feedback>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className="mb-3">
                                            <Form.Label as={Col} lg={2} md={4} sm={12}>CFP <Asterisk size={8} className="mb-3" />
                                                <a href="/assets/tutorial/A_TabellaAssegnazioneCfp.pdf" target="_blank" rel="noreferrer">
                                                    <FaInfoCircle className="text-dark" title="Vedi la tabella di assegnazione cfp" size={22} />
                                                </a>
                                                {cfpCorsoPadre && cfpCorsoPadre != null ? (<p>assegnati al corso padre: {cfpCorsoPadre}</p>) : 
                                                    (
                                                        <Fragment><div>&nbsp;</div></Fragment>
                                                    )}
                                            </Form.Label>
                                            <Col lg={2} md={3} sm={12}>
                                                <Form.Control type="number" 
                                                    // onChange={handleChange} 
                                                    min={0}
                                                    onChange={(e) => {handleGenericChange(e as React.ChangeEvent<HTMLInputElement>, setFieldValue, setFieldTouched, handleChange)}}
                                                    name="cpfRichiesti" value={values.cpfRichiesti}
                                                    isInvalid={!!touched.cpfRichiesti && !!errors.cpfRichiesti}
                                                    disabled={props.isLoading || (isReplicateCourse && !isReplicateLegacy) || !values.idTipologia || values.idTipologia == 0 || props.course.idStatoPubblicazione > 2}
                                                    id="cpfRichiesti"
                                                />
                                                <Form.Control.Feedback type="invalid">{errors.cpfRichiesti}</Form.Control.Feedback>
                                                <Form.Text>CFP richiesti</Form.Text>
                                                {/* {
                                                    (values.idTipologia == 3 && values.cpfRichiesti > 0 && (values.cpfRichiesti < 2 || values.cpfRichiesti > 8)) ? (
                                                        <Form.Text className="text-dark">CFP richiesti <u>NON rispettano le linee guida</u></Form.Text>
                                                    ) : (
                                                        <Form.Text>CFP richiesti</Form.Text>
                                                    )
                                                } */}
                                            </Col>
                                            <Col lg={1} md={1} sm={12}>
                                                {
                                                    user?.role === UserRole.Admin && (isDisabledTab || values.idStatoPubblicazione < 3) && (!isReplicateCourse || isReplicateLegacy) ? (
                                                        <Button className="w-100 btn-dark" disabled={props.isLoading || props.course.idStatoPubblicazione > 2}
                                                            onClick={() => setFieldValue('cpfAssegnati', values.cpfRichiesti)}>
                                                                <BsArrowRight size={20} />
                                                        </Button>
                                                    ) : (
                                                        <Fragment><div>&nbsp;</div></Fragment>
                                                    )
                                                }
                                            </Col>
                                            <Col lg={3} md={4} sm={12}>
                                                <Form.Control type="number" 
                                                    onChange={(e) => {
                                                        setFieldValue('cpfAssegnati', e.target.value, true); // Attiva la validazione
                                                        setFieldTouched('cpfAssegnati', true, true); // Segna il campo come toccato
                                                        handleChange(e as React.ChangeEvent<HTMLInputElement>); // Esegue la funzione handleChange
                                                    }}
                                                    name="cpfAssegnati" value={values.cpfAssegnati} readOnly={user?.role === UserRole.Partner}
                                                    isInvalid={!!touched.cpfAssegnati && !!errors.cpfAssegnati}
                                                    disabled={props.isLoading|| ((isReplicateCourse && !isReplicateLegacy)) || !values.idTipologia || values.idTipologia == 0 || props.course.idStatoPubblicazione > 2}
                                                    id="cpfAssegnati"
                                                />
                                                <Form.Control.Feedback type="invalid">{errors.cpfAssegnati}</Form.Control.Feedback>
                                                {
                                                    (values.idTipologia == 3 && values.cpfAssegnati > 0 && (values.cpfAssegnati < 2 || values.cpfAssegnati > 8)) ? (
                                                        <Form.Text className="text-dark">CFP assegnati <u>NON rispettano le linee guida</u></Form.Text>
                                                    ) : (
                                                        <Form.Text>CFP assegnati</Form.Text>
                                                    )
                                                }
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className="mb-3" controlId="cfpDescrizioneValidation">
                                            <Form.Label as={Col} lg={2} md={4} sm={12}></Form.Label>
                                            <Col lg={6} md={8} sm={12}>
                                                <Form.Control as="textarea" onChange={handleChange}
                                                    name="valutazione.cpfDescrizione" value={values.valutazione?.cpfDescrizione || ""}
                                                    isInvalid={!!touched.valutazione?.cpfDescrizione && !!errors.valutazione?.cpfDescrizione}
                                                    disabled={props.isLoading || isReplicateCourse || props.course.idStatoPubblicazione > 2}
                                                />
                                                <Form.Control.Feedback type="invalid">{errors.valutazione?.cpfDescrizione}</Form.Control.Feedback>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className="mb-3" controlId="assegnazioneCodiceValidation">
                                            <Form.Label as={Col} lg={2} md={4} sm={12}>Assegnazione automatica codice</Form.Label>
                                            <Col lg={6} md={8} sm={12}>
                                                <Form.Check type="switch">
                                                    <Form.Check.Input disabled={props.isLoading || isReplicateCourse || props.course.idStatoPubblicazione > 2}
                                                        onChange={(e) => setFieldValue('flagConforme', e.target.checked)}
                                                        name="flagConforme" defaultChecked={values.flagConforme}
                                                        isInvalid={!!touched.flagConforme && !!errors.flagConforme} />
                                                    <Form.Check.Label>L'evento è conforme a quanto previsto dal Regolamento e dalle Linee Guida del CNAPPC</Form.Check.Label>
                                                    <Form.Control.Feedback type="invalid">{errors.flagConforme}</Form.Control.Feedback>
                                                </Form.Check>
                                                <Form.Text>(In caso di replica si dichiara che l'evento non è mutato nelle sue caratteristiche e nei crediti formativi professionali rispetto a quello già accreditato.)</Form.Text>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className="mb-3" controlId="dataInvioValidation">
                                            <Form.Label as={Col} lg={2} md={4} sm={12}>Data invio istanza</Form.Label>
                                            <Col lg={6} md={8} sm={12}>
                                                <Form.Control type="date" onChange={handleChange}
                                                    name="valutazione.dataInvioIstanza" value={values.valutazione?.dataInvioIstanza || ""}
                                                    isInvalid={!!touched.valutazione?.dataInvioIstanza && !!errors.valutazione?.dataInvioIstanza}
                                                    disabled={props.isLoading || isReplicateCourse || props.course.idStatoPubblicazione > 2} />
                                                <Form.Control.Feedback type="invalid">{errors.valutazione?.dataInvioIstanza}</Form.Control.Feedback>
                                            </Col>
                                        </Form.Group>
                                        <Row className={`mb-3 ${props?.readonly ? "d-none" : ""}`}>
                                            {/* <Col lg={{ span: 8 }} md={{ span: 6 }} sm={{ span: 12 }}>
                                                {
                                                    ((!!touched.idAziendaProponente && !!errors.idAziendaProponente) ||
                                                        (!!touched.titolo && !!errors.titolo) ||
                                                        (!!touched.idAreaFormativa && !!errors.idAreaFormativa)) ? (
                                                        <Alert variant="danger">
                                                            <h6>
                                                                <span>Validazione fallita! Ricontrollare: </span>
                                                                <b>{!!touched.idAziendaProponente && !!errors.idAziendaProponente ? <u>Soggetto proponente</u> : ""} </b>
                                                                <b>{!!touched.titolo && !!errors.titolo ? <u>Titolo</u> : ""} </b>
                                                                <b>{!!touched.idAreaFormativa && !!errors.idAreaFormativa ? <u>Categoria</u> : ""} </b>
                                                            </h6>
                                                        </Alert>
                                                    ) : (
                                                        <Fragment />
                                                    )
                                                }
                                            </Col> */}
                                            <Col lg={{ span: 8 }} md={{ span: 6 }} sm={{ span: 12 }}>
                                                {
                                                    ((!!touched.idErogazione && !!errors.idErogazione) ||
                                                        (!!touched.dataFine && !!errors.dataFine) ||
                                                        (!!touched.idTipologia && !!errors.idTipologia) ||
                                                        (!!touched.cpfRichiesti && !!errors.cpfRichiesti) ||
                                                        (!!touched.cpfAssegnati && !!errors.cpfAssegnati) ||
                                                        (!!touched.valutazione?.idStato && !!errors.valutazione?.idStato) ||
                                                        (!!touched.valutazione?.dettagliStato && !!errors.valutazione?.dettagliStato)) ? (
                                                        <Alert variant="danger">
                                                            <h6>
                                                                <span>Validazione fallita! Ricontrollare: </span>
                                                                <b>{!!touched.idErogazione && !!errors.idErogazione ? <u>Erogazione</u> : ""} </b>
                                                                <b>{!!touched.dataFine && !!errors.dataFine ? <u>data fine corso</u> : ""} </b>
                                                                <b>{!!touched.idTipologia && !!errors.idTipologia ? <u>Tipologia</u> : ""} </b>
                                                                <b>{!!touched.cpfRichiesti && !!errors.cpfRichiesti ? <u>CFP richiesti</u> : ""} </b>
                                                                <b>{!!touched.cpfAssegnati && !!errors.cpfAssegnati ? <u>CFP assegnati</u> : ""} </b>
                                                                <b>{!!touched.valutazione?.idStato && !!errors.valutazione?.idStato ? <u>Stato</u> : ""} </b>
                                                                <b>{!!touched.valutazione?.dettagliStato && !!errors.valutazione?.dettagliStato ? <u>Note autorizzazione / bonifico</u> : ""} </b>
                                                            </h6>
                                                        </Alert>
                                                    ) : (
                                                        <Fragment />
                                                    )
                                                }
                                            </Col>
                                            <Col lg={{ span: 2 }} md={{ span: 3 }} sm={{ span: 6 }}>
                                                {user?.role === UserRole.Admin && isCoursePartner && props.course.idStatoPubblicazione === 2 ? (
                                                    <Button
                                                        onClick={handleShow}
                                                        name="submitGenerale"
                                                        className="w-100 mb-2"
                                                        disabled={props.isLoading || props.course.idStatoPubblicazione > 2}
                                                    >
                                                        {props.isLoading ? (
                                                            <Fragment>
                                                                <Spinner as="span" animation="border" size="sm" role="status" />
                                                                <span className="ms-1">Attendere</span>
                                                                <span>...</span>
                                                            </Fragment>
                                                        ) : (
                                                            <span>Approva</span>
                                                        )}
                                                    </Button>
                                                ) : (
                                                    <Button
                                                        type="submit"
                                                        name="submitGenerale"
                                                        className="w-100 mb-2"
                                                        disabled={props.isLoading || !dataInizioInserita 
                                                            || (user?.role !== UserRole.Admin && props.course.idStatoPubblicazione > 2)
                                                            || isCorsoLegacy}
                                                    >
                                                        {props.isLoading ? (
                                                            <Fragment>
                                                                <Spinner as="span" animation="border" size="sm" role="status" />
                                                                <span className="ms-1">Attendere</span>
                                                                <span>...</span>
                                                            </Fragment>
                                                        ) : (
                                                            <span>Salva tutto</span>
                                                        )}
                                                    </Button>
                                                )}
                                            </Col>
                                            <Col lg={{ span: 2 }} md={{ span: 3 }} sm={{ span: 6 }}>
                                                <Button type="button" disabled={!dataInizioInserita} className="w-100 mb-2" variant="dark" name="valutazione" onClick={buttonGoToTabHandler}>Successivo</Button>
                                            </Col>
                                        </Row>
                                    </Tab>

                                    {
                                        // VALUTAZIONE
                                    }

                                    <Tab eventKey="valutazione" title="Valutazione" className="bg-light p-3" disabled={!dataInizioInserita}>
                                        {/* <h4 className="mb-3">Dati evento</h4>
                                        
                                        <hr /> */}
                                        <h4 className="mb-3">Valutazione interna</h4>
                                        <Form.Group as={Row} className="mb-3" controlId="deliberaValidation">
                                            <Form.Label as={Col} lg={2} md={4} sm={12}>Delibera</Form.Label>
                                            <Col lg={3} md={4} sm={6}>
                                                <Form.Control as="input" type="text" onChange={handleChange}
                                                    name="valutazione.nDelibera" value={values.valutazione?.nDelibera || ""}
                                                    isInvalid={!!touched.valutazione?.nDelibera && !!errors.valutazione?.nDelibera}
                                                    disabled={props.isLoading || isReplicateCourse || props.course.idStatoPubblicazione > 2} placeholder="Numero" />
                                                <Form.Control.Feedback type="invalid">{errors.valutazione?.nDelibera}</Form.Control.Feedback>
                                            </Col>
                                            <Col lg={3} md={4} sm={6}>
                                                <Form.Control type="date" onChange={handleChange}
                                                    name="valutazione.dataDelibera" value={values.valutazione?.dataDelibera || ""}
                                                    isInvalid={!!touched.valutazione?.dataDelibera && !!errors.valutazione?.dataDelibera}
                                                    disabled={props.isLoading || isReplicateCourse || props.course.idStatoPubblicazione > 2} placeholder="Data" />
                                                <Form.Control.Feedback type="invalid">{errors.valutazione?.dataDelibera}</Form.Control.Feedback>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className="mb-3" controlId="valutazioneInternaValidation">
                                            <Form.Label as={Col} lg={2} md={4} sm={12}>Valutazione interna</Form.Label>
                                            <Col lg={3} md={4} sm={12}>
                                                <Form.Check type="switch">
                                                    <Form.Check.Input onChange={(e) => setFieldValue('valutazione.flagValutazioneInterna', e.target.checked)}
                                                        name="valutazione.flagValutazioneInterna" disabled={props.isLoading || isReplicateCourse || props.course.idStatoPubblicazione > 2}
                                                        defaultChecked={values.valutazione?.flagValutazioneInterna}
                                                        isInvalid={!!touched.valutazione?.flagValutazioneInterna && !!errors.valutazione?.flagValutazioneInterna} />
                                                    <Form.Check.Label></Form.Check.Label>
                                                    <Form.Control.Feedback type="invalid">{errors.valutazione?.flagValutazioneInterna}</Form.Control.Feedback>
                                                </Form.Check>
                                                <Form.Text>Dati facoltativi</Form.Text>
                                            </Col>
                                            <Col lg={3} md={4} sm={12}>
                                                <Form.Control type="date" onChange={handleChange}
                                                    name="valutazione.dataValutazioneInterna" value={values.valutazione?.dataValutazioneInterna || ""}
                                                    isInvalid={!!touched.valutazione?.dataValutazioneInterna && !!errors.valutazione?.dataValutazioneInterna}
                                                    placeholder="Data" disabled={props.isLoading || isReplicateCourse || props.course.idStatoPubblicazione > 2}
                                                />
                                                <Form.Control.Feedback type="invalid">{errors.valutazione?.dataValutazioneInterna}</Form.Control.Feedback>
                                            </Col>
                                        </Form.Group>

                                        <Form.Group as={Row} className="mb-3" controlId="idStatoValidation">
                                            <Form.Label as={Col} lg={2} md={4} sm={12}>Stato
                                                {user != null && user.role === UserRole.Partner ? (
                                                    <Asterisk size={8} className="mb-3" />
                                                ) : ""}
                                            </Form.Label>
                                            <Col lg={6} md={8} sm={12}>
                                                <Form.Control
                                                    as="select"
                                                    onChange={handleChange}
                                                    disabled={props.isLoading || props.course.idStatoPubblicazione > 2}
                                                    name="valutazione.idStato"
                                                    value={values.valutazione?.idStato}
                                                    isInvalid={!!touched.valutazione?.idStato && !!errors.valutazione?.idStato}>
                                                    <option key={"idStato0"} value={0}></option>
                                                    {metaStatoCorso.length && (
                                                        metaStatoCorso.map((item: IMetadataOption, index: number) => {
                                                            return (<option key={"idStato" + item.id} value={item.id}>{item.nome}</option>);
                                                        })
                                                    )}
                                                </Form.Control>
                                                <Form.Control.Feedback type="invalid">{errors.valutazione?.idStato}</Form.Control.Feedback>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className="mb-3" controlId="dettagliStatoValidation">
                                            <Form.Label as={Col} lg={2} md={4} sm={12}>Note autorizzazione / bonifico
                                                {user != null && user.role === UserRole.Partner ? (
                                                    <Asterisk size={8} className="mb-3" />
                                                ) : ""}
                                            </Form.Label>
                                            <Col lg={6} md={8} sm={12}>
                                                <Form.Control
                                                    as="textarea"
                                                    onChange={handleChange}
                                                    name="valutazione.dettagliStato"
                                                    value={values.valutazione?.dettagliStato || ""}
                                                    isInvalid={!!touched.valutazione?.dettagliStato && !!errors.valutazione?.dettagliStato}
                                                    disabled={props.isLoading || props.course.idStatoPubblicazione > 2}
                                                />
                                                <Form.Control.Feedback type="invalid">{errors.valutazione?.dettagliStato}</Form.Control.Feedback>
                                                <Form.Text>Inserire note autorizzazione o dettagli pagamento.</Form.Text>
                                            </Col>
                                        </Form.Group>
                                        <hr />
                                        <h4 className="mb-3">Sede e strutture</h4>
                                        <Form.Group as={Row} className="mb-3" controlId="svolgimentoInSedeValidation">
                                            <Form.Label as={Col} lg={2} md={4} sm={12}>Svolgimento in sede</Form.Label>
                                            <Col lg={6} md={8} sm={12}>
                                                <Form.Check type="switch">
                                                    <Form.Check.Input disabled={props.isLoading || props.course.idStatoPubblicazione > 2}
                                                        onChange={(e) => setFieldValue('valutazione.flagSvolgimentoInSede', e.target.checked)}
                                                        name="valutazione.flagSvolgimentoInSede" defaultChecked={values.valutazione?.flagSvolgimentoInSede}
                                                        isInvalid={!!touched.valutazione?.flagSvolgimentoInSede && !!errors.valutazione?.flagSvolgimentoInSede} />
                                                    <Form.Check.Label>(Per sede si intende l'ambito territoriale di competenza dell'ordine)</Form.Check.Label>
                                                    <Form.Control.Feedback type="invalid">{errors.valutazione?.flagSvolgimentoInSede}</Form.Control.Feedback>
                                                </Form.Check>
                                                <Form.Text>Rimuovere la spunta solo per i corsi FRONTALI che si svolgono FUORI dalla provincia di competenza.</Form.Text>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className="mb-3" controlId="indirizzoSedeValidation">
                                            <Form.Label as={Col} lg={2} md={4} sm={12}>Indirizzo sede {/* <Asterisk size={8} className="mb-3" /> */}</Form.Label>
                                            <Col lg={6} md={8} sm={12}>
                                                <Form.Control as="input" type="text" onChange={handleChange}
                                                    name="valutazione.indirizzoSede" value={values.valutazione?.indirizzoSede || ""}
                                                    isInvalid={!!touched.valutazione?.indirizzoSede && !!errors.valutazione?.indirizzoSede}
                                                    disabled={props.isLoading || props.course.idStatoPubblicazione > 2}
                                                />
                                                <Form.Control.Feedback type="invalid">{errors.valutazione?.indirizzoSede}</Form.Control.Feedback>
                                                <Form.Text>Riportare il comune e l'indirizzo completo.</Form.Text>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className="mb-3" controlId="accessibilitaValidation">
                                            <Form.Label as={Col} lg={2} md={4} sm={12}>Accessibilità {/* <Asterisk size={8} className="mb-3" /> */}</Form.Label>
                                            <Col lg={6} md={8} sm={12}>
                                                <Form.Control as="select" onChange={handleChange} disabled={props.isLoading || isReplicateCourse || props.course.idStatoPubblicazione > 2}
                                                    name="valutazione.idAccessibilita" value={values.valutazione?.idAccessibilita}
                                                    isInvalid={!!touched.valutazione?.idAccessibilita && !!errors.valutazione?.idAccessibilita}>
                                                    {metaAccessibilitaCorso.length && (
                                                        metaAccessibilitaCorso.map((item: IMetadataOption, index: number) => {
                                                            return (<option key={"idAccessibilita" + item.id} value={item.id}>{item.nome}</option>);
                                                        })
                                                    )}
                                                </Form.Control>
                                                <Form.Control.Feedback type="invalid">{errors.valutazione?.idAccessibilita}</Form.Control.Feedback>
                                                <Form.Text>Indicare il grado di accessibilità per persone con disabilità. Ulteriori dettagli e modalità vanno specificati nella scheda di pubblicazione.</Form.Text>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className="mb-3" controlId="conformitaStruttureValidation">
                                            <Form.Label as={Col} lg={2} md={4} sm={12}>Conformità strutture</Form.Label>
                                            <Col lg={6} md={8} sm={12}>
                                                <Form.Check type="switch">
                                                    <Form.Check.Input disabled={props.isLoading || isReplicateCourse || props.course.idStatoPubblicazione > 2}
                                                        onChange={(e) => setFieldValue('valutazione.flagConformitaStrutture', e.target.checked)}
                                                        name="valutazione.flagConformitaStrutture" defaultChecked={values.valutazione?.flagConformitaStrutture}
                                                        isInvalid={!!touched.valutazione?.flagConformitaStrutture && !!errors.valutazione?.flagConformitaStrutture} />
                                                    <Form.Check.Label></Form.Check.Label>
                                                    <Form.Control.Feedback type="invalid">{errors.valutazione?.flagConformitaStrutture}</Form.Control.Feedback>
                                                </Form.Check>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className="mb-3" controlId="struttureValidation">
                                            <Form.Label as={Col} lg={2} md={4} sm={12}>Strutture</Form.Label>
                                            <Col lg={6} md={8} sm={12}>
                                                <Form.Control as="textarea" onChange={handleChange}
                                                    name="valutazione.strutture" value={values.valutazione?.strutture || ""}
                                                    isInvalid={!!touched.valutazione?.strutture && !!errors.valutazione?.strutture}
                                                    disabled={props.isLoading || isReplicateCourse || props.course.idStatoPubblicazione > 2}
                                                />
                                                <Form.Control.Feedback type="invalid">{errors.valutazione?.strutture}</Form.Control.Feedback>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className="mb-3" controlId="fornituraValidation">
                                            <Form.Label as={Col} lg={2} md={4} sm={12}>Fornitura</Form.Label>
                                            <Col lg={6} md={8} sm={12}>
                                                <Form.Control as="textarea" onChange={handleChange}
                                                    name="valutazione.fornitura" value={values.valutazione?.fornitura || ""}
                                                    isInvalid={!!touched.valutazione?.fornitura && !!errors.valutazione?.fornitura}
                                                    disabled={props.isLoading || isReplicateCourse || props.course.idStatoPubblicazione > 2}
                                                />
                                                <Form.Control.Feedback type="invalid">{errors.valutazione?.fornitura}</Form.Control.Feedback>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className="mb-3" controlId="frequenzaMinimaValidation">
                                            <Form.Label as={Col} lg={2} md={4} sm={12}>Frequenza minima (%)</Form.Label>
                                            <Col lg={6} md={8} sm={12}>
                                                <Form.Control type="number" onChange={handleChange}
                                                    name="valutazione.frequenzaMin" value={values.valutazione?.frequenzaMin || ""}
                                                    isInvalid={!!touched.valutazione?.frequenzaMin && !!errors.valutazione?.frequenzaMin}
                                                    disabled={props.isLoading || isReplicateCourse || props.course.idStatoPubblicazione > 2}
                                                />
                                                <Form.Control.Feedback type="invalid">{errors.valutazione?.frequenzaMin}</Form.Control.Feedback>
                                                <Form.Text>Indicare la percentuale di frequenza minima per il riconoscimento CFP.</Form.Text>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className="mb-3" controlId="linguaValidation">
                                            <Form.Label as={Col} lg={2} md={4} sm={12}>Lingua</Form.Label>
                                            <Col lg={6} md={8} sm={12}>
                                                <Form.Control as="input" type="text" onChange={handleChange}
                                                    name="valutazione.lingua" value={values.valutazione?.lingua || ""}
                                                    isInvalid={!!touched.valutazione?.lingua && !!errors.valutazione?.lingua}
                                                    disabled={props.isLoading || isReplicateCourse || props.course.idStatoPubblicazione > 2} />
                                                <Form.Control.Feedback type="invalid">{errors.valutazione?.lingua}</Form.Control.Feedback>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className="mb-3" controlId="partnerValidation">
                                            <Form.Label as={Col} lg={2} md={4} sm={12}>Partner</Form.Label>
                                            <Col lg={6} md={8} sm={12}>
                                                <Form.Control as="input" type="text" onChange={handleChange}
                                                    name="valutazione.partner" value={values.valutazione?.partner || ""}
                                                    isInvalid={!!touched.valutazione?.partner && !!errors.valutazione?.partner}
                                                    disabled={props.isLoading || isReplicateCourse || props.course.idStatoPubblicazione > 2} />
                                                <Form.Control.Feedback type="invalid">{errors.valutazione?.partner}</Form.Control.Feedback>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className="mb-3" controlId="referenteValidation">
                                            <Form.Label as={Col} lg={2} md={4} sm={12}>Referente</Form.Label>
                                            <Col lg={6} md={8} sm={12}>
                                                <Form.Control as="input" type="text" onChange={handleChange}
                                                    name="valutazione.referente" value={values.valutazione?.referente || ""}
                                                    isInvalid={!!touched.valutazione?.referente && !!errors.valutazione?.referente}
                                                    disabled={props.isLoading || isReplicateCourse || props.course.idStatoPubblicazione > 2} />
                                                <Form.Control.Feedback type="invalid">{errors.valutazione?.referente}</Form.Control.Feedback>
                                                <Form.Text>Se inserito verrà riportato anche sugli attestati.</Form.Text>
                                            </Col>
                                        </Form.Group>                                        
                                        <hr />
                                        <h4 className="mb-3">Valutazione</h4>
                                        <Form.Group as={Row} className="mb-3" controlId="valutazioneConformitaValidation">
                                            <Form.Label as={Col} lg={2} md={4} sm={12}>Valutazione conformità</Form.Label>
                                            <Col lg={3} md={4} sm={12}>
                                                <Form.Check type="switch">
                                                    <Form.Check.Input disabled={props.isLoading || isReplicateCourse || props.course.idStatoPubblicazione > 2}
                                                        onChange={(e) => setFieldValue('valutazione.flagValutazioneConformita', e.target.checked)}
                                                        name="valutazione.flagValutazioneConformita" defaultChecked={values.valutazione?.flagValutazioneConformita}
                                                        isInvalid={!!touched.valutazione?.flagValutazioneConformita && !!errors.valutazione?.flagValutazioneConformita} />
                                                    <Form.Check.Label></Form.Check.Label>
                                                    <Form.Control.Feedback type="invalid">{errors.valutazione?.flagValutazioneConformita}</Form.Control.Feedback>
                                                </Form.Check>
                                            </Col>
                                            <Col lg={3} md={4} sm={12}>
                                                <Form.Control type="date" onChange={handleChange}
                                                    name="valutazione.dataValutazioneConformita" value={values.valutazione?.dataValutazioneConformita || ""}
                                                    isInvalid={!!touched.valutazione?.dataValutazioneConformita && !!errors.valutazione?.dataValutazioneConformita}
                                                    placeholder="Data" disabled={props.isLoading || isReplicateCourse || props.course.idStatoPubblicazione > 2}
                                                />
                                                <Form.Control.Feedback type="invalid">{errors.valutazione?.dataValutazioneConformita}</Form.Control.Feedback>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className="mb-3" controlId="protocolloUscitaValidation">
                                            <Form.Label as={Col} lg={2} md={4} sm={12}>Protocollo uscita</Form.Label>
                                            <Col lg={3} md={4} sm={6}>
                                                <Form.Control as="input" type="text" onChange={handleChange}
                                                    name="valutazione.nProtOut" value={values.valutazione?.nProtOut || ""}
                                                    isInvalid={!!touched.valutazione?.nProtOut && !!errors.valutazione?.nProtOut}
                                                    disabled={props.isLoading || isReplicateCourse || props.course.idStatoPubblicazione > 2} placeholder="Numero"
                                                />
                                                <Form.Control.Feedback type="invalid">{errors.valutazione?.nProtOut}</Form.Control.Feedback>
                                            </Col>
                                            <Col lg={3} md={4} sm={6}>
                                                <Form.Control type="date" onChange={handleChange}
                                                    name="valutazione.dataProtOut" value={values.valutazione?.dataProtOut || ""}
                                                    isInvalid={!!touched.valutazione?.dataProtOut && !!errors.valutazione?.dataProtOut}
                                                    disabled={props.isLoading || isReplicateCourse || props.course.idStatoPubblicazione > 2} placeholder="Data"
                                                />
                                                <Form.Control.Feedback type="invalid">{errors.valutazione?.dataProtOut}</Form.Control.Feedback>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className="mb-3" controlId="codiceValidation">
                                            <Form.Label as={Col} lg={2} md={4} sm={12}>
                                                Codice {isCoursePartner ? "proponente" : ""}
                                            </Form.Label>
                                            <Col lg={6} md={8} sm={12}>
                                                <Form.Control
                                                    as="input"
                                                    type="text"
                                                    onChange={handleChange}
                                                    name="codice"
                                                    value={values.codice}
                                                    readOnly={isDisabledTab || user?.role === UserRole.Partner}
                                                    isInvalid={!!touched.codice && !!errors.codice}
                                                    disabled={props.isLoading || isReplicateCourse || props.course.idStatoPubblicazione > 2} />
                                                <Form.Text>{isDisabledTab ? 'Salvare il corso per poter modificare il codice corso' : ''}</Form.Text>
                                                <Form.Control.Feedback type="invalid">{errors.codice}</Form.Control.Feedback>
                                            </Col>
                                        </Form.Group>
                                        <Row className={`mb-3 ${props?.readonly ? "d-none" : ""}`}>
                                            <Col lg={{ span: 8 }} md={{ span: 6 }} sm={{ span: 12 }}>
                                                {
                                                    ((!!touched.idErogazione && !!errors.idErogazione) ||
                                                        (!!touched.dataFine && !!errors.dataFine) ||
                                                        (!!touched.idTipologia && !!errors.idTipologia) ||
                                                        (!!touched.cpfRichiesti && !!errors.cpfRichiesti) ||
                                                        (!!touched.cpfAssegnati && !!errors.cpfAssegnati) ||
                                                        (!!touched.valutazione?.idStato && !!errors.valutazione?.idStato) ||
                                                        (!!touched.valutazione?.dettagliStato && !!errors.valutazione?.dettagliStato)) ? (
                                                        <Alert variant="danger">
                                                            <h6>
                                                                <span>Validazione fallita! Ricontrollare: </span>
                                                                <b>{!!touched.idErogazione && !!errors.idErogazione ? <u>Erogazione</u> : ""} </b>
                                                                <b>{!!touched.dataFine && !!errors.dataFine ? <u>data fine corso</u> : ""} </b>
                                                                <b>{!!touched.idTipologia && !!errors.idTipologia ? <u>Tipologia</u> : ""} </b>
                                                                <b>{!!touched.cpfRichiesti && !!errors.cpfRichiesti ? <u>CFP richiesti</u> : ""} </b>
                                                                <b>{!!touched.cpfAssegnati && !!errors.cpfAssegnati ? <u>CFP assegnati</u> : ""} </b>
                                                                <b>{!!touched.valutazione?.idStato && !!errors.valutazione?.idStato ? <u>Stato</u> : ""} </b>
                                                                <b>{!!touched.valutazione?.dettagliStato && !!errors.valutazione?.dettagliStato ? <u>Note autorizzazione / bonifico</u> : ""} </b>
                                                            </h6>
                                                        </Alert>
                                                    ) : (
                                                        <Fragment />
                                                    )
                                                }
                                            </Col>
                                            <Col lg={{ span: 2 }} md={{ span: 3 }} sm={{ span: 6 }}>
                                                {user?.role === UserRole.Admin && isCoursePartner && props.course.idStatoPubblicazione === 2 ? (
                                                    <Button
                                                        onClick={handleShow}
                                                        name="submitValutazione"
                                                        className="w-100 mb-2"
                                                        disabled={props.isLoading || props.course.idStatoPubblicazione > 2}
                                                    >
                                                        {props.isLoading ? (
                                                            <Fragment>
                                                                <Spinner as="span" animation="border" size="sm" role="status" />
                                                                <span className="ms-1">Attendere</span>
                                                                <span>...</span>
                                                            </Fragment>
                                                        ) : (
                                                            <span>Approva</span>
                                                        )}
                                                    </Button>
                                                ) : (
                                                    <Button
                                                        type="submit"
                                                        name="submitValutazione"
                                                        className="w-100 mb-2"
                                                        disabled={props.isLoading || !dataInizioInserita 
                                                            || (user?.role !== UserRole.Admin && props.course.idStatoPubblicazione > 2)
                                                            || isCorsoLegacy}
                                                    >
                                                        {props.isLoading ? (
                                                            <Fragment>
                                                                <Spinner as="span" animation="border" size="sm" role="status" />
                                                                <span className="ms-1">Attendere</span>
                                                                <span>...</span>
                                                            </Fragment>
                                                        ) : (
                                                            <span>Salva tutto</span>
                                                        )}
                                                    </Button>
                                                )}
                                            </Col>
                                            <Col lg={{ span: 2 }} md={{ span: 3 }} sm={{ span: 6 }}>
                                                <Button type="button" disabled={!dataInizioInserita || props.course.idStatoPubblicazione > 2} className="w-100 mb-2" variant="dark" name="modalita" onClick={buttonGoToTabHandler}>Successivo</Button>
                                            </Col>
                                        </Row>
                                    </Tab>

                                    {
                                        // MODALITA'
                                    }

                                    <Tab eventKey="modalita" title="Modalità" className="bg-light p-3" disabled={!dataInizioInserita}>
                                        <h4 className="mb-3">Iscrizione</h4>
                                        <Form.Group as={Row} className="mb-3" controlId="accettaIscrizioniValidation">
                                            <Form.Label as={Col} lg={2} md={4} sm={12}>Accetta iscrizioni</Form.Label>
                                            <Col lg={6} md={8} sm={12}>
                                                <Form.Check type="switch">
                                                    <Form.Check.Input disabled={props.isLoading || props.course.idStatoPubblicazione > 2}
                                                        onChange={(e) => setFieldValue('flagAccettaIscrizioni', e.target.checked)}
                                                        name="flagAccettaIscrizioni" defaultChecked={values.flagAccettaIscrizioni}
                                                        isInvalid={!!touched.flagAccettaIscrizioni && !!errors.flagAccettaIscrizioni} />
                                                    <Form.Check.Label></Form.Check.Label>
                                                    <Form.Control.Feedback type="invalid">{errors.flagAccettaIscrizioni}</Form.Control.Feedback>
                                                </Form.Check>
                                                <Form.Text>Attiva le iscrizioni online. I soggetti potranno registrarsi all'evento online.</Form.Text>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className="mb-3" controlId="chiudiIscrizioniValidation">
                                            <Form.Label as={Col} lg={2} md={4} sm={12}>Chiudi iscrizioni</Form.Label>
                                            <Col lg={6} md={8} sm={12}>
                                                <Form.Check type="switch">
                                                    <Form.Check.Input disabled={props.isLoading || props.course.idStatoPubblicazione > 2}
                                                        onChange={(e) => setFieldValue('flagChiudiIscrizioni', e.target.checked)}
                                                        name="flagChiudiIscrizioni" defaultChecked={values.flagChiudiIscrizioni}
                                                        isInvalid={!!touched.flagChiudiIscrizioni && !!errors.flagChiudiIscrizioni} />
                                                    <Form.Check.Label></Form.Check.Label>
                                                    <Form.Control.Feedback type="invalid">{errors.flagChiudiIscrizioni}</Form.Control.Feedback>
                                                </Form.Check>
                                            </Col>
                                        </Form.Group>
                                        <hr />
                                        <h4 className="mb-3">Modalità iscrizione</h4>
                                        <Form.Group as={Row} className="mb-3" controlId="aperturaIscrizioniValidation">
                                            <Form.Label as={Col} lg={2} md={4} sm={12}>Apertura iscrizioni</Form.Label>
                                            <Col lg={2} md={3} sm={12}>
                                                <DatePicker
                                                    selected={values.modalita?.dataIscrizioneDal ? utcToZonedTime(values.modalita.dataIscrizioneDal, timeZone) : null}
                                                    onChange={(date) => setFieldValue('modalita.dataIscrizioneDal', date ? zonedTimeToUtc (date, timeZone) : null)}
                                                    placeholderText="gg/mm/aaaa"
                                                    dateFormat="dd/MM/yyyy"                                                    
                                                    showYearDropdown
                                                    showIcon
                                                    locale="it"
                                                    customInput={<CustomInput placeholder="gg/mm/aaaa"/>}
                                                />
                                                {/* {<Form.Control type="date" onChange={handleChange}
                                                    name="modalita.dataIscrizioneDal" value={values.modalita?.dataIscrizioneDal || ""}
                                                    isInvalid={!!touched.modalita?.dataIscrizioneDal && !!errors.modalita?.dataIscrizioneDal}
                                                    disabled={props.isLoading || props.course.idStatoPubblicazione > 2} placeholder="Dal"
                                                /> } */}
                                                <Form.Control.Feedback type="invalid">{errors.modalita?.dataIscrizioneDal}</Form.Control.Feedback>
                                            </Col>
                                            <Col lg={3} md={4} sm={12}>
                                                <DatePicker
                                                    selected={values.modalita?.dataIscrizioneAl ? utcToZonedTime(values.modalita.dataIscrizioneAl, timeZone) : null}
                                                    onChange={(date) => setFieldValue('modalita.dataIscrizioneAl', date ? zonedTimeToUtc (date, timeZone) : null)}
                                                    placeholderText="gg/mm/aaaa hh:mm"
                                                    showTimeSelect                                                    
                                                    showYearDropdown
                                                    dateFormat="dd/MM/yyyy HH:mm"
                                                    showIcon
                                                    locale="it"
                                                    customInput={<CustomInput placeholder="gg/mm/aaaa hh:mm"/>}
                                                />
                                                <Form.Control.Feedback type="invalid">{errors.modalita?.dataIscrizioneAl}</Form.Control.Feedback>
                                            </Col>
                                                                                        
                                            {/* <Col lg={3} md={4} sm={12}>
                                                <Form.Control 
                                                    type="datetime-local" 
                                                    onChange={handleChange}
                                                    // onChange={(e) => handleGenericChange(e as React.ChangeEvent<HTMLInputElement>, setFieldValue, setFieldTouched, handleChange)}
                                                    name="modalita.dataIscrizioneAl" 
                                                    // value={values.modalita?.dataIscrizioneAl || ""}
                                                    // value={values.modalita?.dataIscrizioneAl ? values.modalita.dataIscrizioneAl.slice(0, 16) : ""}
                                                    value={values.modalita?.dataIscrizioneAl ?? ""}
                                                    isInvalid={!!touched.modalita?.dataIscrizioneAl && !!errors.modalita?.dataIscrizioneAl}
                                                    validate={validateDatetime} as={Form.Control} // Applica la validazione al campo
                                                    disabled={props.isLoading || props.course.idStatoPubblicazione > 2} placeholder="Al"
                                                    // onKeyDown={(e) => {
                                                    //     e.preventDefault();
                                                    //  }}
                                                />
                                                <Form.Control.Feedback type="invalid">{errors.modalita?.dataIscrizioneAl}</Form.Control.Feedback>
                                            </Col> */}
                                            <Col lg={{ offset: 2, span: 6 }} md={{ offset: 4, span: 8 }} sm={12}>
                                                <Form.Text>Queste date determinano l'intervallo temporale entro la quale saranno eccettate le iscrizioni all'evento. Se non verrà specificata la data di inizio le iscrizioni saranno aperte immediatamente.</Form.Text>
                                            </Col>
                                        </Form.Group>

                                        <Form.Group as={Row} className="mb-3">
                                            <Form.Label as={Col} lg={2} md={4} sm={12}>Partecipanti</Form.Label>
                                            <Col lg={1} md={2} sm={12}>
                                                <Form.Control type="number" onChange={handleChange} min={0}
                                                    name="nPartecipantiMax" value={values.nPartecipantiMax}
                                                    isInvalid={!!touched.nPartecipantiMax && !!errors.nPartecipantiMax}
                                                    disabled={props.isLoading || props.course.idStatoPubblicazione > 2}
                                                    id="nPartecipantiMax"
                                                />
                                                <Form.Control.Feedback type="invalid">{errors.nPartecipantiMax}</Form.Control.Feedback>
                                                <Form.Text>Max</Form.Text>
                                            </Col>
                                            <Col lg={1} md={2} sm={12}>
                                                <Form.Control type="number" onChange={handleChange} min={0}
                                                    name="nPartecipantiExt" value={values.nPartecipantiExt}
                                                    isInvalid={!!touched.nPartecipantiExt && !!errors.nPartecipantiExt}
                                                    disabled={props.isLoading || props.course.idStatoPubblicazione > 2}
                                                    id="nPartecipantiExt"
                                                />
                                                <Form.Control.Feedback type="invalid">{errors.nPartecipantiExt}</Form.Control.Feedback>
                                                <Form.Text>Quota esterni</Form.Text>
                                            </Col>
                                            <Col lg={4} md={4} sm={12}>
                                                <Form.Check type="switch" id="flagAbilitaListadattesa">
                                                    <Form.Check.Input onChange={(e) => setFieldValue('flagAbilitaListadattesa', e.target.checked)}
                                                        name="flagAbilitaListadattesa" defaultChecked={values.flagAbilitaListadattesa} disabled={props.isLoading || props.course.idStatoPubblicazione > 2}
                                                        isInvalid={!!touched.flagAbilitaListadattesa && !!errors.flagAbilitaListadattesa} />
                                                    <Form.Check.Label>Lista d'attesa</Form.Check.Label>
                                                    <Form.Control.Feedback type="invalid">{errors.flagAbilitaListadattesa}</Form.Control.Feedback>
                                                    <br />
                                                    <Form.Text>
                                                        Al raggiungimento del massimo partecipanti 
                                                        { values.flagAbilitaListadattesa ? " sarà aperta la lista d'attesa." : " le iscrizioni verranno chiuse." }
                                                    </Form.Text>
                                                </Form.Check>
                                            </Col>
                                        </Form.Group>
                                        {/*}
                                        <Form.Group as={Row} className="mb-3" controlId="quotaChiusuraIscrizioniValidation">
                                            <Form.Label as={Col} lg={2} md={4} sm={12}>Quota chiusura iscrizioni</Form.Label>
                                            <Col lg={3} md={4} sm={12}>
                                                <Form.Control type="number" onChange={handleChange}
                                                    name="modalita.nPartecipantiMaxPerChiudereCorso" value={values.modalita?.nPartecipantiMaxPerChiudereCorso}
                                                    isInvalid={!!touched.modalita?.nPartecipantiMaxPerChiudereCorso && !!errors.modalita?.nPartecipantiMaxPerChiudereCorso}
                                                    disabled={props.isLoading || isReplicateCourse || props.course.idStatoPubblicazione > 2}
                                                />
                                                <Form.Control.Feedback type="invalid">{errors.modalita?.nPartecipantiMaxPerChiudereCorso}</Form.Control.Feedback>
                                            </Col>
                                            <Col lg={3} md={4} sm={12}>
                                                <Form.Control type="number" onChange={handleChange}
                                                    name="modalita.nPartecipantiExtPerChiudereCorso" value={values.modalita?.nPartecipantiExtPerChiudereCorso}
                                                    isInvalid={!!touched.modalita?.nPartecipantiExtPerChiudereCorso && !!errors.modalita?.nPartecipantiExtPerChiudereCorso}
                                                    disabled={props.isLoading || isReplicateCourse || props.course.idStatoPubblicazione > 2}
                                                />
                                                <Form.Control.Feedback type="invalid">{errors.modalita?.nPartecipantiExtPerChiudereCorso}</Form.Control.Feedback>
                                                <Form.Text>Quota esterni</Form.Text>
                                            </Col>
                                        </Form.Group>
                                        {*/}

                                        {/* gestione eventi gratuiti */}
                                        <Form.Group as={Row} className="mb-3">
                                            <Form.Label as={Col} lg={2} md={4} sm={12}>Evento gratuito</Form.Label>
                                            <Col lg={1} md={1} sm={12}>
                                                <Form.Check type="switch" id="modalita.flagEventoGratuito">
                                                    <Form.Check.Input disabled={props.isLoading || props.course.idStatoPubblicazione > 2}
                                                        onChange={(e) => {
                                                            console.log('modalita.flagEventoGratuito', e.target.checked);
                                                            setFieldValue('modalita.flagEventoGratuito', e.target.checked);
                                                            
                                                            setFieldValue('modalita.descIscrizioneA', '');
                                                            setFieldValue('modalita.accontoIscrizioneA', 0);
                                                            setFieldValue('modalita.flagIscrizioneIntB', false);
                                                            setFieldValue('modalita.descIscrizioneB', '');
                                                            setFieldValue('modalita.accontoIscrizioneB', 0);
                                                            setFieldValue('modalita.flagIscrizioneIntC', false);
                                                            setFieldValue('modalita.descIscrizioneC', '');
                                                            setFieldValue('modalita.accontoIscrizioneC', 0);
                                                            setFieldValue('modalita.saldoEntroGg', 0);
                                                            
                                                        }}
                                                        name="modalita.flagEventoGratuito" defaultChecked={values.modalita?.flagEventoGratuito}
                                                        isInvalid={!!touched.modalita?.flagEventoGratuito && !!errors.modalita?.flagEventoGratuito} 
                                                    />
                                                    <Form.Check.Label></Form.Check.Label>
                                                    <Form.Control.Feedback type="invalid">{errors.modalita?.flagEventoGratuito}</Form.Control.Feedback>
                                                </Form.Check>
                                            </Col>                                           
                                        </Form.Group>

                                        <Form.Group as={Row} className="mb-3">
                                            <Form.Label as={Col} lg={2} md={4} sm={12}>Iscrizione (A) {/* <Asterisk size={8} className="mb-3" /><Asterisk size={8} className="mb-3" /> */}</Form.Label>
                                            <Col lg={1} md={1} sm={12}>
                                                <Form.Check type="switch" className="d-none" id="modalita.flagIscrizioneIntA">
                                                    <Form.Check.Input onChange={(e) => setFieldValue('modalita.flagIscrizioneIntA', e.target.checked)}
                                                        disabled={props.isLoading || props.course.idStatoPubblicazione > 2} defaultChecked={true}
                                                        name="modalita.flagIscrizioneIntA"
                                                    />
                                                    <Form.Check.Label></Form.Check.Label>
                                                </Form.Check>
                                            </Col>
                                            <Col lg={3} md={3} sm={12}>
                                                <Form.Control as="input" type="text" onChange={handleChange}
                                                    name="modalita.descIscrizioneA" value={values.modalita?.descIscrizioneA || ""}
                                                    isInvalid={!!touched.modalita?.descIscrizioneA && !!errors.modalita?.descIscrizioneA}
                                                    disabled={props.isLoading || values.modalita.flagEventoGratuito || props.course.idStatoPubblicazione > 2}
                                                    id="modalita.descIscrizioneA"
                                                />
                                                <Form.Control.Feedback type="invalid">{errors.modalita?.descIscrizioneA}</Form.Control.Feedback>
                                            </Col>
                                            <Col lg={2} md={4} sm={12}>
                                                <Form.Control type="number" onChange={handleChange} min={0}
                                                    name="modalita.accontoIscrizioneA" value={values.modalita?.accontoIscrizioneA}
                                                    isInvalid={!!touched.modalita?.accontoIscrizioneA && !!errors.modalita?.accontoIscrizioneA}
                                                    disabled={props.isLoading || values.modalita.flagEventoGratuito || props.course.idStatoPubblicazione > 2}
                                                    id="modalita.accontoIscrizioneA"
                                                />
                                                <Form.Control.Feedback type="invalid">{errors.modalita?.accontoIscrizioneA}</Form.Control.Feedback>
                                                <Form.Text>Prezzo</Form.Text>
                                            </Col>
                                            {/*
                                            <Col lg={1} md={2} sm={12}>
                                                <Form.Control type="number" onChange={handleChange}
                                                    name="modalita.saldoIscrizioneA" value={values.modalita?.saldoIscrizioneA}
                                                    isInvalid={!!touched.modalita?.saldoIscrizioneA && !!errors.modalita?.saldoIscrizioneA}
                                                    disabled={props.isLoading|| isReplicateCourse || props.course.idStatoPubblicazione > 2}
                                                />
                                                <Form.Control.Feedback type="invalid">{errors.modalita?.saldoIscrizioneA}</Form.Control.Feedback>
                                                <Form.Text>Saldo</Form.Text>
                                            </Col>
                                            */}
                                        </Form.Group>
                                        <Form.Group as={Row} className="mb-3">
                                            <Form.Label as={Col} lg={2} md={4} sm={12}>Iscrizione (B)</Form.Label>
                                            <Col lg={1} md={1} sm={12}>
                                                <Form.Check type="switch" id="modalita.flagIscrizioneIntB">
                                                    <Form.Check.Input disabled={props.isLoading || values.modalita.flagEventoGratuito || props.course.idStatoPubblicazione > 2}
                                                        onChange={(e) => {
                                                            setFieldValue('modalita.flagIscrizioneIntB', e.target.checked);
                                                            setFieldValue('modalita.descIscrizioneB', '');
                                                            setFieldValue('modalita.accontoIscrizioneB', 0);
                                                        }}
                                                        name="modalita.flagIscrizioneIntB" 
                                                        // defaultChecked={values.modalita?.flagIscrizioneIntB}
                                                        checked={values.modalita?.flagIscrizioneIntB}
                                                        isInvalid={!!touched.modalita?.flagIscrizioneIntB && !!errors.modalita?.flagIscrizioneIntB} 
                                                    />
                                                    <Form.Check.Label></Form.Check.Label>
                                                    <Form.Control.Feedback type="invalid">{errors.modalita?.flagIscrizioneIntB}</Form.Control.Feedback>
                                                </Form.Check>
                                            </Col>
                                            <Col lg={3} md={3} sm={12}>
                                                <Form.Control as="input" type="text" onChange={handleChange}
                                                    name="modalita.descIscrizioneB" value={values.modalita?.descIscrizioneB || ""}
                                                    isInvalid={!!touched.modalita?.descIscrizioneB && !!errors.modalita?.descIscrizioneB}
                                                    disabled={props.isLoading || !values.modalita?.flagIscrizioneIntB || values.modalita.flagEventoGratuito || props.course.idStatoPubblicazione > 2}
                                                    id="modalita.descIscrizioneB"
                                                />
                                                <Form.Control.Feedback type="invalid">{errors.modalita?.descIscrizioneB}</Form.Control.Feedback>
                                            </Col>
                                            <Col lg={2} md={4} sm={12}>
                                                <Form.Control type="number" onChange={handleChange} min={0}
                                                    name="modalita.accontoIscrizioneB" value={values.modalita?.accontoIscrizioneB || ""}
                                                    isInvalid={!!touched.modalita?.accontoIscrizioneB && !!errors.modalita?.accontoIscrizioneB}
                                                    disabled={props.isLoading || !values.modalita?.flagIscrizioneIntB || values.modalita.flagEventoGratuito || props.course.idStatoPubblicazione > 2}
                                                    id="modalita.accontoIscrizioneB"
                                                />
                                                <Form.Control.Feedback type="invalid">{errors.modalita?.accontoIscrizioneB}</Form.Control.Feedback>
                                                <Form.Text>Prezzo</Form.Text>
                                            </Col>
                                            {/*
                                            <Col lg={1} md={2} sm={12}>
                                                <Form.Control type="number" onChange={handleChange}
                                                    name="modalita.saldoIscrizioneB" value={values.modalita?.saldoIscrizioneB}
                                                    isInvalid={!!touched.modalita?.saldoIscrizioneB && !!errors.modalita?.saldoIscrizioneB}
                                                    disabled={props.isLoading || !values.modalita?.flagIscrizioneIntB|| isReplicateCourse || props.course.idStatoPubblicazione > 2}
                                                />
                                                <Form.Control.Feedback type="invalid">{errors.modalita?.saldoIscrizioneB}</Form.Control.Feedback>
                                                <Form.Text>Saldo</Form.Text>
                                            </Col>
                                            */}
                                        </Form.Group>
                                        <Form.Group as={Row} className="mb-3">
                                            <Form.Label as={Col} lg={2} md={4} sm={12}>Iscrizione (C) {/* <Asterisk size={8} className="mb-3" /><Asterisk size={8} className="mb-3" /> */}</Form.Label>
                                            <Col lg={1} md={1} sm={12}>
                                                <Form.Check type="switch" id="modalita.flagIscrizioneIntC">
                                                    <Form.Check.Input disabled={props.isLoading || values.modalita.flagEventoGratuito || props.course.idStatoPubblicazione > 2}
                                                        onChange={(e) => {
                                                            setFieldValue('modalita.flagIscrizioneIntC', e.target.checked);
                                                            setFieldValue('modalita.descIscrizioneC', '');
                                                            setFieldValue('modalita.accontoIscrizioneC', 0);
                                                        }}
                                                        name="modalita.flagIscrizioneIntC" 
                                                        // defaultChecked={values.modalita?.flagIscrizioneIntC}
                                                        checked={values.modalita?.flagIscrizioneIntC}
                                                        isInvalid={!!touched.modalita?.flagIscrizioneIntC && !!errors.modalita?.flagIscrizioneIntC}
                                                    />
                                                    <Form.Check.Label></Form.Check.Label>
                                                    <Form.Control.Feedback type="invalid">{errors.modalita?.flagIscrizioneIntC}</Form.Control.Feedback>
                                                </Form.Check>
                                            </Col>
                                            <Col lg={3} md={3} sm={12}>
                                                <Form.Control as="input" type="text" onChange={handleChange}
                                                    name="modalita.descIscrizioneC" value={values.modalita?.descIscrizioneC || ""}
                                                    isInvalid={!!touched.modalita?.descIscrizioneC && !!errors.modalita?.descIscrizioneC}
                                                    disabled={props.isLoading || !values.modalita?.flagIscrizioneIntC || values.modalita.flagEventoGratuito || props.course.idStatoPubblicazione > 2}
                                                    id="modalita.descIscrizioneC"
                                                />
                                                <Form.Control.Feedback type="invalid">{errors.modalita?.descIscrizioneC}</Form.Control.Feedback>
                                            </Col>
                                            <Col lg={2} md={4} sm={12}>
                                                <Form.Control type="number" onChange={handleChange} min={0}
                                                    name="modalita.accontoIscrizioneC" value={values.modalita?.accontoIscrizioneC}
                                                    isInvalid={!!touched.modalita?.accontoIscrizioneC && !!errors.modalita?.accontoIscrizioneC}
                                                    disabled={props.isLoading || !values.modalita?.flagIscrizioneIntC || values.modalita.flagEventoGratuito || props.course.idStatoPubblicazione > 2}
                                                    id="modalita.accontoIscrizioneC"
                                                />
                                                <Form.Control.Feedback type="invalid">{errors.modalita?.accontoIscrizioneC}</Form.Control.Feedback>
                                                <Form.Text>Prezzo</Form.Text>
                                            </Col>
                                            {/*
                                            <Col lg={1} md={2} sm={12}>
                                                <Form.Control type="number" onChange={handleChange}
                                                    name="modalita.saldoIscrizioneC" value={values.modalita?.saldoIscrizioneC}
                                                    isInvalid={!!touched.modalita?.saldoIscrizioneC && !!errors.modalita?.saldoIscrizioneC}
                                                    disabled={props.isLoading || !values.modalita?.flagIscrizioneIntC|| isReplicateCourse || props.course.idStatoPubblicazione > 2}
                                                />
                                                <Form.Control.Feedback type="invalid">{errors.modalita?.saldoIscrizioneC}</Form.Control.Feedback>
                                                <Form.Text>Saldo</Form.Text>
                                            </Col>
                                            */}
                                        </Form.Group>
                                        <Row>
                                            <Col lg={{ offset: 2, span: 6 }} md={{ offset: 4, span: 8 }} sm={12}>
                                                <Form.Text>
                                                    Inserire almeno una modalità di iscrizione. Se l'evento è gratuito impostare a 0 il prezzo.
                                                    <ul>
                                                        <li>A: Iscritti all'Ordine</li>
                                                        <li>B: Iscritti provenienti da altri Ordini</li>
                                                        <li>C: Neoiscritti</li>
                                                    </ul>
                                                </Form.Text>
                                            </Col>
                                        </Row>
                                        <Form.Group as={Row} className="mb-3" controlId="saldoEntroGgValidation">
                                            <Form.Label as={Col} lg={2} md={4} sm={12}>Saldo entro (gg) {/* <Asterisk size={8} className="mb-3" /> */}</Form.Label>
                                            <Col lg={6} md={8} sm={12}>
                                                <Form.Control type="number" onChange={handleChange}
                                                    name="modalita.saldoEntroGg" value={values.modalita?.saldoEntroGg}
                                                    isInvalid={!!touched.modalita?.saldoEntroGg && !!errors.modalita?.saldoEntroGg}
                                                    disabled={props.isLoading || values.modalita.flagEventoGratuito || props.course.idStatoPubblicazione > 2} min={0}
                                                />
                                                <Form.Control.Feedback type="invalid">{errors.modalita?.saldoEntroGg}</Form.Control.Feedback>
                                                <Form.Text>
                                                    Numero giorni dalla data di iscrizione, entro i quali sarà necessario registrare il saldo.
                                                    Se specificato saranno automaticamente annullate anche le iscrizioni non saldare entro: data limite di iscrizione - saldo entro gg.
                                                    Se l'evento è gratuito o se non si desidera l'annullamento automatico delle iscrizioni impostate a 0.
                                                </Form.Text>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className="mb-3" controlId="dettaglioModalitaDiIscrizioneValidation">
                                            <Form.Label as={Col} lg={2} md={4} sm={12}>Dettaglio modalità di iscrizione {/* <Asterisk size={8} className="mb-3" /> */}</Form.Label>
                                            <Col lg={6} md={8} sm={12}>
                                                <Form.Control as="textarea" onChange={handleChange}
                                                    name="modalita.dettaglioModalitaIscrizione" value={values.modalita?.dettaglioModalitaIscrizione || ""}
                                                    isInvalid={!!touched.modalita?.dettaglioModalitaIscrizione && !!errors.modalita?.dettaglioModalitaIscrizione}
                                                    disabled={props.isLoading || props.course.idStatoPubblicazione > 2}
                                                />
                                                <Form.Control.Feedback type="invalid">{errors.modalita?.dettaglioModalitaIscrizione}</Form.Control.Feedback>
                                                <Form.Text>Dettagliare le modalità di iscrizione a pagamento (es: codice IBAN, causale, ecc...)</Form.Text>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className="mb-3" controlId="accettazioneClausoleContrattualiValidation">
                                            <Form.Label as={Col} lg={2} md={4} sm={12}>Accettazione clausole contrattuali</Form.Label>
                                            <Col lg={6} md={8} sm={12}>
                                                <Form.Check type="switch">
                                                    <Form.Check.Input disabled={props.isLoading || props.course.idStatoPubblicazione > 2}
                                                        onChange={(e) => setFieldValue('modalita.flagAccettazioneClausole', e.target.checked)}
                                                        name="modalita.flagAccettazioneClausole" defaultChecked={values.modalita?.flagAccettazioneClausole}
                                                        isInvalid={!!touched.modalita?.flagAccettazioneClausole && !!errors.modalita?.flagAccettazioneClausole} />
                                                    <Form.Control.Feedback type="invalid">{errors.modalita?.flagAccettazioneClausole}</Form.Control.Feedback>
                                                </Form.Check>
                                            </Col>
                                        </Form.Group>
                                        <Row className={`mb-3 ${props?.readonly ? "d-none" : ""}`}>
                                        <Col lg={{ span: 8 }} md={{ span: 6 }} sm={{ span: 12 }}>
                                                {
                                                    ((!!touched.idAziendaProponente && !!errors.idAziendaProponente) ||
                                                        (!!touched.dataFine && !!errors.dataFine) ||
                                                        (!!touched.titolo && !!errors.titolo) ||
                                                        (!!touched.idAreaFormativa && !!errors.idAreaFormativa) ||
                                                        (!!touched.idErogazione && !!errors.idErogazione) ||
                                                        (!!touched.idTipologia && !!errors.idTipologia) ||
                                                        (!!touched.cpfRichiesti && !!errors.cpfRichiesti) ||
                                                        (!!touched.cpfAssegnati && !!errors.cpfAssegnati) ||
                                                        (!!touched.valutazione?.idStato && !!errors.valutazione?.idStato) ||
                                                        (!!touched.valutazione?.dettagliStato && !!errors.valutazione?.dettagliStato)) ? (
                                                        <Alert variant="danger">
                                                            <h6>
                                                                <span>Validazione fallita! Ricontrollare: </span>
                                                                <b>{!!touched.idAziendaProponente && !!errors.idAziendaProponente ? <u>Soggetto proponente</u> : ""} </b>
                                                                <b>{!!touched.dataFine && !!errors.dataFine ? <u>data fine corso</u> : ""} </b>
                                                                <b>{!!touched.titolo && !!errors.titolo ? <u>Titolo</u> : ""} </b>
                                                                <b>{!!touched.idAreaFormativa && !!errors.idAreaFormativa ? <u>Categoria</u> : ""} </b>
                                                                <b>{!!touched.idErogazione && !!errors.idErogazione ? <u>Erogazione</u> : ""} </b>
                                                                <b>{!!touched.idTipologia && !!errors.idTipologia ? <u>Tipologia</u> : ""} </b>
                                                                <b>{!!touched.cpfRichiesti && !!errors.cpfRichiesti ? <u>CFP richiesti</u> : ""} </b>
                                                                <b>{!!touched.cpfAssegnati && !!errors.cpfAssegnati ? <u>CFP assegnati</u> : ""} </b>
                                                                <b>{!!touched.valutazione?.idStato && !!errors.valutazione?.idStato ? <u>Stato</u> : ""} </b>
                                                                <b>{!!touched.valutazione?.dettagliStato && !!errors.valutazione?.dettagliStato ? <u>Note autorizzazione / bonifico</u> : ""} </b>
                                                            </h6>
                                                        </Alert>
                                                    ) : (
                                                        <Fragment />
                                                    )
                                                }
                                            </Col>
                                            <Col lg={{ span: 2 }} md={{ span: 3 }} sm={{ span: 6 }}>
                                            {/* <Col lg={{ offset: 8, span: 2 }} md={{ offset: 6, span: 3 }} sm={{ offset: 2, span: 5 }}> */}
                                                {user?.role === UserRole.Admin && isCoursePartner && props.course.idStatoPubblicazione === 2 ? (
                                                    <Button
                                                        onClick={handleShow}
                                                        name="submitModalita"
                                                        className="w-100 mb-2"
                                                        disabled={props.isLoading || props.course.idStatoPubblicazione > 2}
                                                    >
                                                        {props.isLoading ? (
                                                            <Fragment>
                                                                <Spinner as="span" animation="border" size="sm" role="status" />
                                                                <span className="ms-1">Attendere</span>
                                                                <span>...</span>
                                                            </Fragment>
                                                        ) : (
                                                            <span>Approva</span>
                                                        )}
                                                    </Button>
                                                ) : (
                                                    <Button
                                                        type="submit"
                                                        name="submitModalita"
                                                        className="w-100 mb-2"
                                                        disabled={props.isLoading || !dataInizioInserita 
                                                            || (user?.role !== UserRole.Admin && props.course.idStatoPubblicazione > 2)
                                                            || isCorsoLegacy}
                                                    >
                                                        {props.isLoading ? (
                                                            <Fragment>
                                                                <Spinner as="span" animation="border" size="sm" role="status" />
                                                                <span className="ms-1">Attendere</span>
                                                                <span>...</span>
                                                            </Fragment>
                                                        ) : (
                                                            <span>Salva tutto</span>
                                                        )}
                                                    </Button>
                                                )}
                                            </Col>
                                            {/* <Col lg={{ span: 2 }} md={{ span: 3 }} sm={{ span: 5 }}> */}
                                            <Col lg={{ span: 2 }} md={{ span: 3 }} sm={{ span: 6 }}>
                                                <Button type="button" disabled={!dataInizioInserita} className="w-100 mb-2" variant="dark" name="pubblicazione" onClick={buttonGoToTabHandler}>Successivo</Button>
                                            </Col>
                                        </Row>
                                    </Tab>

                                    {
                                        // DOCENTI
                                    }

                                    {/*  <Tab eventKey="docenti" title="Docenti" className="bg-light p-3" disabled={isDisabledTab}>
                                        <h4 className="mb-3">Docenti</h4>
                                        <CourseTeacherList idCorso={values.id} />
                                        <Row className="mb-3">
                                            <Col lg={{ offset: 8, span: 2 }} md={{ offset: 6, span: 3 }} sm={{ offset: 2, span: 5 }}></Col>
                                            <Col lg={{ span: 2 }} md={{ span: 3 }} sm={{ span: 5 }}>
                                                <Button type="button" className="w-100 mb-2" variant="dark" name="pubblicazione" onClick={buttonGoToTabHandler}>Successivo</Button>
                                            </Col>
                                        </Row>
                                </Tab> */}

                                    {
                                        // PUBBLICAZIONE
                                    }

                                    <Tab eventKey="pubblicazione" title="Pubblicazione" className="bg-light p-3" disabled={!dataInizioInserita}>
                                        <h4 className="mb-3">Pubblicazione</h4>
                                        <Form.Group as={Row} className="mb-3" controlId="pubblicatoValidation">
                                            <Form.Label as={Col} lg={2} md={4} sm={12}>Stato pubblicazione</Form.Label>
                                            <Col lg={6} md={8} sm={12}>
                                                <Form.Control as="select" onChange={handleChange} disabled={props.isLoading || props.course.idStatoPubblicazione > 2}
                                                    name="idStatoPubblicazione" value={values?.idStatoPubblicazione}
                                                    isInvalid={!!touched?.idStatoPubblicazione && !!errors?.idStatoPubblicazione}>
                                                    {metaStatoPubblicazioneCorso.length && (
                                                        metaStatoPubblicazioneCorso.filter((itm: IMetadataOption) => {
                                                            if (itm.id === 4) return false;

                                                            // verifico che il ruolo sia Partner
                                                            if (user != null && user.role === UserRole.Partner) {
                                                                if (props.course.idStatoPubblicazione === 3) {
                                                                    return itm.id === 3;
                                                                }
                                                                else {
                                                                    return itm.id < 3;
                                                                }
                                                            }
                                                            // verifico che il ruolo sia Admin e non è un primo inserimento
                                                            if (user != null && user.role === UserRole.Admin && !isDisabledTab) {
                                                                // verifico che il corso sia stato inserito da un partner
                                                                if (isCoursePartner) {
                                                                    if (props.course.idStatoPubblicazione === 3) {
                                                                        return itm.id === 3;
                                                                    }
                                                                    else {
                                                                        return itm.id > 1;
                                                                    }
                                                                }
                                                                else if (isReplicateCourse) {
                                                                    if (props.course.idStatoPubblicazione === 3) {
                                                                        return itm.id === 3;
                                                                    }
                                                                }
                                                            }

                                                            return true;
                                                        }).map((item: IMetadataOption, index: number) => {
                                                            return (<option key={"idStatoPubblicazione" + item.id} value={item.id}>{item.nome}</option>);
                                                        })
                                                    )}
                                                </Form.Control>
                                                <Form.Control.Feedback type="invalid">{errors?.idStatoPubblicazione}</Form.Control.Feedback>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className="mb-3" controlId="eventoApertoValidation">
                                            <Form.Label as={Col} lg={2} md={4} sm={12}>Evento aperto</Form.Label>
                                            <Col lg={6} md={8} sm={12}>
                                                <Form.Check type="switch">
                                                    <Form.Check.Input disabled={props.isLoading || props.course.idStatoPubblicazione > 2}
                                                        onChange={(e) => setFieldValue('flagEventoAperto', e.target.checked)}
                                                        name="flagEventoAperto" defaultChecked={values.flagEventoAperto}
                                                        isInvalid={!!touched.flagEventoAperto && !!errors.flagEventoAperto} />
                                                    <Form.Check.Label></Form.Check.Label>
                                                    <Form.Control.Feedback type="invalid">{errors.flagEventoAperto}</Form.Control.Feedback>
                                                </Form.Check>
                                                <Form.Text>Se spunto l'evento sarà visibile anche dagli iscritti ad altri ordini.</Form.Text>
                                            </Col>
                                        </Form.Group>
                                        <hr />
                                        <h4 className="mb-3">Area pubblica</h4>
                                        <Form.Group as={Row} className="mb-3" controlId="areaPubblicaDescrizioneValidation">
                                            <Form.Label as={Col} lg={2} md={4} sm={12}></Form.Label>
                                            <Col lg={6} md={8} sm={12}>
                                                <MyRichTextEditor name="pubblicazione.areaPubDescrizione" value={values.pubblicazione?.areaPubDescrizione || ""} onChange={handleChange} />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className={`mb-3 ${props.course.id === 0 ? 'd-none' : ''}`} controlId="areaPubblicaAllegatiValidation">
                                            <Form.Label as={Col} lg={2} md={4} sm={12}>
                                                <span>Allegati pubblici</span>
                                                {
                                                    props?.readonly ? (
                                                        <Fragment />
                                                    ) : (
                                                        <MdUpload size={28} className="mx-2 rounded-circle c-pointer border border-dark" onClick={handleOpenAttachmentModal} />
                                                    )
                                                }
                                            </Form.Label>
                                            <Col lg={6} md={8} sm={12}>
                                                {
                                                    props?.readonly ? (
                                                        <CourseDetailAttachmentList idCorso={values.id} />
                                                    ) : (
                                                        <CourseAttachmentList idCorso={values.id} />
                                                    )
                                                }
                                            </Col>
                                        </Form.Group>
                                        <hr />
                                        <h4 className="mb-3">Area riservata</h4>
                                        <Form.Group as={Row} className="mb-3" controlId="collegamentoEsternoValidation">
                                            <Form.Label as={Col} lg={2} md={4} sm={12}>Collegamento esterno</Form.Label>
                                            <Col lg={6} md={8} sm={12}>
                                                <Form.Control as="input" type="text" onChange={handleChange}
                                                    name="pubblicazione.areaRisCollegamentoEsterno" value={values.pubblicazione?.areaRisCollegamentoEsterno || ""}
                                                    isInvalid={!!touched.pubblicazione?.areaRisCollegamentoEsterno && !!errors.pubblicazione?.areaRisCollegamentoEsterno}
                                                    disabled={props.isLoading || isReplicateCourse || props.course.idStatoPubblicazione > 2}
                                                />
                                                <Form.Control.Feedback type="invalid">{errors.pubblicazione?.areaRisCollegamentoEsterno}</Form.Control.Feedback>
                                                <Form.Text>Parametri da inviare ad eventuale risorsa esterna (Moodle, ecc...)</Form.Text>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className="mb-3" controlId="areaRisDescrizioneValidation">
                                            <Form.Label as={Col} lg={2} md={4} sm={12}></Form.Label>
                                            <Col lg={6} md={8} sm={12}>
                                                <MyRichTextEditor name="pubblicazione.areaRisDescrizione" value={values.pubblicazione?.areaRisDescrizione || ""} onChange={handleChange} />
                                            </Col>
                                        </Form.Group>
                                        <Row className={`mb-3 ${props?.readonly ? "d-none" : ""}`}>
                                            <Col lg={{ span: 8 }} md={{ span: 6 }} sm={{ span: 12 }}>
                                                {
                                                    ((!!touched.idAziendaProponente && !!errors.idAziendaProponente) ||
                                                        (!!touched.dataFine && !!errors.dataFine) ||
                                                        (!!touched.titolo && !!errors.titolo) ||
                                                        (!!touched.idAreaFormativa && !!errors.idAreaFormativa) ||
                                                        (!!touched.idErogazione && !!errors.idErogazione) ||
                                                        (!!touched.idTipologia && !!errors.idTipologia) ||
                                                        (!!touched.cpfRichiesti && !!errors.cpfRichiesti) ||
                                                        (!!touched.cpfAssegnati && !!errors.cpfAssegnati) ||
                                                        (!!touched.valutazione?.idStato && !!errors.valutazione?.idStato) ||
                                                        (!!touched.valutazione?.dettagliStato && !!errors.valutazione?.dettagliStato)) ? (
                                                        <Alert variant="danger">
                                                            <h6>
                                                                <span>Validazione fallita! Ricontrollare: </span>
                                                                <b>{!!touched.idAziendaProponente && !!errors.idAziendaProponente ? <u>Soggetto proponente</u> : ""} </b>
                                                                <b>{!!touched.dataFine && !!errors.dataFine ? <u>data fine corso</u> : ""} </b>
                                                                <b>{!!touched.titolo && !!errors.titolo ? <u>Titolo</u> : ""} </b>
                                                                <b>{!!touched.idAreaFormativa && !!errors.idAreaFormativa ? <u>Categoria</u> : ""} </b>
                                                                <b>{!!touched.idErogazione && !!errors.idErogazione ? <u>Erogazione</u> : ""} </b>
                                                                <b>{!!touched.idTipologia && !!errors.idTipologia ? <u>Tipologia</u> : ""} </b>
                                                                <b>{!!touched.cpfRichiesti && !!errors.cpfRichiesti ? <u>CFP richiesti</u> : ""} </b>
                                                                <b>{!!touched.cpfAssegnati && !!errors.cpfAssegnati ? <u>CFP assegnati</u> : ""} </b>
                                                                <b>{!!touched.valutazione?.idStato && !!errors.valutazione?.idStato ? <u>Stato</u> : ""} </b>
                                                                <b>{!!touched.valutazione?.dettagliStato && !!errors.valutazione?.dettagliStato ? <u>Note autorizzazione / bonifico</u> : ""} </b>
                                                            </h6>
                                                        </Alert>
                                                    ) : (
                                                        <Fragment />
                                                    )
                                                }
                                            </Col>
                                            <Col lg={{ span: 2 }} md={{ span: 3 }} sm={{ span: 6 }}>
                                                {(user?.role === UserRole.Admin && isCoursePartner && props.course.idStatoPubblicazione === 2) ? (
                                                    <Button
                                                        onClick={handleShow}
                                                        name="submitPubblicazione"
                                                        className="w-100 mb-2"
                                                        disabled={props.isLoading || props.course.idStatoPubblicazione > 2}
                                                    >
                                                        {props.isLoading ? (
                                                            <Fragment>
                                                                <Spinner as="span" animation="border" size="sm" role="status" />
                                                                <span className="ms-1">Attendere</span>
                                                                <span>...</span>
                                                            </Fragment>
                                                        ) : (
                                                            <span>Approva</span>
                                                        )}
                                                    </Button>
                                                ) : (
                                                    <Button
                                                        type="submit"
                                                        name="submitPubblicazione"
                                                        className="w-100 mb-2"
                                                        disabled={props.isLoading || !dataInizioInserita 
                                                            || (user?.role !== UserRole.Admin && props.course.idStatoPubblicazione > 2)
                                                            || isCorsoLegacy}
                                                    >
                                                        {props.isLoading ? (
                                                            <Fragment>
                                                                <Spinner as="span" animation="border" size="sm" role="status" />
                                                                <span className="ms-1">Attendere</span>
                                                                <span>...</span>
                                                            </Fragment>
                                                        ) : (
                                                            <span>Salva tutto</span>
                                                        )}
                                                    </Button>
                                                )}
                                            </Col>
                                            <Col lg={{ span: 2 }} md={{ span: 3 }} sm={{ span: 6 }}>
                                                {!isDisabledTab && (
                                                    <Button type="button" disabled={!dataInizioInserita || props.course.idStatoPubblicazione > 2} className="w-100 mb-2" name="iscrizioni" variant="dark" onClick={buttonGoToTabHandler}>Successivo</Button>
                                                )}
                                            </Col>
                                        </Row>
                                    </Tab>

                                    {
                                        // ISCRIZIONI
                                    }

                                    <Tab eventKey="iscrizioni" title="Iscrizioni e presenze" className="bg-light p-3" disabled={isDisabledTab || props?.readonly}>
                                        <h4 className="mb-3">Iscrizioni</h4>
                                        <CourseSubscriptionList idCorso={values.id} showDialogCert={props.course.idTipologia == 11} />
                                    </Tab>
                                </Tabs>
                                <Modal centered show={showModalApprove} onHide={handleClose} animation={true}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Procedere?</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>Cliccando su "Salva e approva" verrà:
                                        <ul>
                                            <li>pubblicato il corso</li>
                                            <li>assegnato il codice progressivo</li>
                                            <li>assegnati i seguenti CFP: {values.cpfRichiesti}</li>
                                        </ul>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="secondary" onClick={handleClose}>
                                            Annulla
                                        </Button>
                                        <Button variant="primary" onClick={(e) => handleApprove(values)}>
                                            Salva a approva
                                        </Button>
                                    </Modal.Footer>
                                </Modal>
                            </Form>

                        </>
                    )
                    }}
                </Formik>
            )
            }
            
            <Modal centered show={showUpdateMessageModal} onHide={handleCloseUpdateMessageModal} >
                <Modal.Header closeButton>
                    <Modal.Title><h3>Attenzione modifica corso</h3></Modal.Title>
                </Modal.Header>
                <Modal.Body>modificando i valori dei crediti CFP, verranno modificati anche i crediti assegnati a eventuali partecipanti al corso.
                    <br />
                    <br />
                </Modal.Body>
            </Modal>

            <Modal centered show={showAttachmentModal} onHide={handleCloseAttachmentModal} >
                <Modal.Header closeButton>
                    <Modal.Title><h3>Inserisci allegato</h3></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <AttachmentForm idCorso={props.course.id} />
                </Modal.Body>
            </Modal>
        </Fragment >
    );
};

export default CourseForm;