import { FC, Fragment, useEffect, useState } from 'react';
import { Container, Toast, ToastContainer, } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import CourseForm from '../../components/admin/CourseForm';
import PageParagraph from '../../components/PageParagraph';
import PageSubtitle from '../../components/PageSubtitle';
import PageTitle from '../../components/PageTitle';
import { ICourseEntityRaw } from '../../entities/course.entity';
import { AppDispatch, RootState } from '../../redux/store';
import { IPostCourseFulfilledPayload, IPostCourseThunkPayload, postCourse } from '../../redux/slices/course.slice';
import { AuthenticatedRoutesAdmin } from '../../enums/routes.enum';
import { courseInit } from '../../utils/course-default';
import { IRejectResponse } from '../../api/api.interface';
import { FaInfoCircle } from 'react-icons/fa';

const AdminCourseReplica: FC = () => {
  const { id } = useParams<{ id: string }>();
  const { replica } = useParams<{ replica: string }>();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const error: IRejectResponse | null = useSelector((s: RootState) => s.course.error);

  const [delaySuccessToast, ] = useState(4000);
  const [delayDangerToast, ] = useState(10000);
  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const [showDangerToast, setShowDangerToast] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const hideSuccessToast = () => setShowSuccessToast(false);
  const hideDangerToast = () => setShowDangerToast(false);
  const course: ICourseEntityRaw = useSelector((s: RootState) => s.course.value != null ? s.course.value : courseInit);
  const [cfpCorsoPadre, setCfpCorsoPadre] = useState<number | null>(null);

  // nel caso "replica in triennio in corso" azzero i cfp richiesti e assegnati,  l'id area formativa e l'id tipologia
  // const [localCourseNew, ] = useState<ICourseEntityRaw>({ ...course, id: 0, dataInizio: "", dataFine: "", idCorsoRiferimento: Number(id), 
  //   cpfAssegnati: 0, cpfRichiesti: 0, idAreaFormativa: 0, idTipologia:0,
  //   codice: "", idStatoPubblicazione: 1, valutazione: { ...course.valutazione, idStato: 7, dettagliStato: "" } });

  const [localCourse, setLocalCourse] = useState<ICourseEntityRaw | null>(null);

  useEffect(() => {    
    console.log(course);
    if (course && course.id > 0) {
      setCfpCorsoPadre(course.cpfAssegnati);
      if (replica === 'legacy') {
        console.log("replica legacy");
        setLocalCourse({ ...course, id: 0, dataInizio: "", dataFine: "", idCorsoRiferimento: Number(id), 
          cpfAssegnati: 0, cpfRichiesti: 0, idAreaFormativa: 0, idTipologia:0, iscritti: [], 
          codice: "", idStatoPubblicazione: 1, valutazione: { ...course.valutazione, idStato: 7, dettagliStato: "" } });
      } else {
        console.log("replica regular");
        setLocalCourse({ ...course, id: 0, dataInizio: "", dataFine: "", idCorsoRiferimento: Number(id), iscritti: [], 
          codice: "", idStatoPubblicazione: 1, valutazione: { ...course.valutazione, idStato: 7, dettagliStato: "" } });
      }
    }
  }, [course]);

  useEffect(() => {
    if (localCourse != null) {
      console.log(localCourse);
    }
  }, [localCourse]);

  const handleClickGoToEditCourse = (idCorso: number) => {
    let route = null;

    route = AuthenticatedRoutesAdmin.Course.toString();
    route = route.replace(':id', idCorso.toString());

    navigate(route);
  };

  const handleSave = (value: ICourseEntityRaw) => {
    setLoading(true);
    console.log(value);
    dispatch(postCourse({ course: value } as IPostCourseThunkPayload))
      .unwrap()
      .then((fulfilledPayload) => {
        setShowSuccessToast(true);
        setSubmitted(true);
        const payload = fulfilledPayload as unknown as IPostCourseFulfilledPayload;
        if (payload) {
          setTimeout(() => handleClickGoToEditCourse(payload.id), 2000);
        }
      })
      .catch(() => {
        setShowDangerToast(true);
      })
      .finally(() => setLoading(false));
  }

  return (
    <Fragment>
      <PageTitle content="Corso">
        <a href="/assets/tutorial/O_Corso.pdf" target="_blank" rel="noreferrer">
          <FaInfoCircle className="text-dark" size={32} />
        </a>
      </PageTitle>
      <PageSubtitle content="Replica" />
      <PageParagraph content="" />
      <Container fluid as="section">
        {localCourse != null && <CourseForm course={localCourse} onSave={handleSave} isLoading={isLoading} submitted={submitted} />}
        {/* <CourseForm course={localCourse} onSave={handleSave} isLoading={isLoading} submitted={submitted} /> */}
      </Container>

      <ToastContainer className="p-3" position={'top-end'}>
        <Toast show={showSuccessToast} bg="success" onClose={hideSuccessToast} delay={delaySuccessToast} autohide id="toastSuccess">
          <Toast.Header>
            <span className="me-auto"><strong>Salvataggio completato!</strong></span>
          </Toast.Header>
          <Toast.Body className="bg-light"></Toast.Body>
        </Toast>
        <Toast show={showDangerToast} bg="danger" onClose={hideDangerToast} delay={delayDangerToast} autohide id="toastDanger">
          <Toast.Header>
            <span className="me-auto"><strong>Salvataggio non riuscito!</strong></span>
          </Toast.Header>
          <Toast.Body className="bg-light">{error?.status} {error?.error}</Toast.Body>
        </Toast>
      </ToastContainer>
    </Fragment>
  );
};

export default AdminCourseReplica;